import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports/reports.component';
import { RouterModule, Routes } from '@angular/router';

const reportsRouting: Routes = [
  {
    path: 'reports',
    component: ReportsComponent
  }
];

@NgModule({
  declarations: [ReportsComponent],
  exports: [
    RouterModule,
    ReportsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(reportsRouting)
  ]
})
export class ReportsModule { }
