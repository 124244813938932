import { GetDoctorsList } from './../../../billing-module/billingconstants';
import { DoctorService } from './../doctorService';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Editor, Validators } from 'ngx-editor';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.css']
})
export class DoctorListComponent implements OnInit {
  @ViewChild('templateCreationPopUp')
  private templateCreationPopUp: ElementRef;
  editor: Editor;
  html: '';
  form: FormGroup;
  submitted = false;
  showErrorMessage = false;
  existingRecord = false;
  ipSampleCollectioninfo: any = [];
  selectedName: any;
  editData: any;
  public userInfo: any = [];
  ipSampleCollection = new MatTableDataSource(this.ipSampleCollectioninfo);
  @ViewChild(MatSort, { static: false }) ipSort: MatSort;
  @ViewChild('ipPaginator', { read: MatPaginator }) ipPaginator: MatPaginator;

  advSearchForm: FormGroup;

  constructor(private dataService: DoctorService,
    private modalService: NgbModal,
    private tokenService: TokenStorageService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder) {
    this.userInfo = this.tokenService.getUser();
  }
  ipcolumnsToDisplay = ['RTM_ID', 'SM_ID', 'SERVICE_NAME', 'CREATE_DATETIME', 'LAST_SAVED_BY', 'STATUS', 'ACTION'];
  serviceTypeList = []
  serviceNamesList = []
  ngOnInit() {
    this.advSearchForm = this.formBuilder.group({
      serviceName: [''],
      templateName: ['']
    });

    this.editor = new Editor();
    this.form = new FormGroup({
      serviceType: new FormControl("", Validators.required()),
      serviceName: new FormControl(""),
      editorContent: new FormControl("", Validators.required()),
      templateName: new FormControl("")
    });
    this.getServiceTypeList();
    this.getData();
  }

  onSearch() {
    this.getData();
  }

  get doc(): AbstractControl {
    return this.form.get("editorContent").value;
  }
  get f() { return this.form.controls; }

  submitData(form) {
    this.submitted = true;
    if (form.invalid) {
      if (this.form.controls.editorContent.invalid) {
        this.showErrorMessage = true;
      }
      return;
    } else {
      if (this.existingRecord) {
        this.updatetemplateData(form);
      } else {
        this.insertTemplateData(form)
      }
    }
  }
  insertTemplateData(form) {
    let selectedServiceName = this.serviceNamesList.find((data) => { return data.SM_ID === form.value.serviceName})    
    const inputReq = {
      smId: form.value.serviceName,
      serviceName: selectedServiceName.SERVICE_NAME,
      serviceTypeId: form.value.serviceType,
      reportTemplate: form.value.editorContent,
      templateName: form.value.templateName,
      createdId: this.userInfo.username,
      status: "ACTIVE"
    }
    
    this.dataService.saveTemplatesData(inputReq)
      .subscribe(
        (response) => {
          this.toastr.success('Template Created successfully..!');
          this.modalService.dismissAll();
          this.getData();
        },
        (err) => {
          this.toastr.error(err.error.message);
          console.log('HTTP Error', err);
        }
    )
  }
  updatetemplateData(form) {
    let selectedServiceName = this.serviceNamesList.find((data) => { return data.SM_ID === form.value.serviceName})    
    const inputReq = {
      smId: form.value.serviceName,
      serviceName: selectedServiceName.SERVICE_NAME,
      templateName: form.value.templateName,
      serviceTypeId: form.value.serviceType,
      reportTemplate: form.value.editorContent,
      createdId: this.userInfo.username,
      status: "ACTIVE",
      templateId: this.editData.RTM_ID
    }
    this.dataService.updateTemplatesData(inputReq).subscribe(
      (response) => {
        this.toastr.success('Template updated successfully!');
        this.modalService.dismissAll();
        this.getData();
      },
        (err) => {
          this.toastr.error(err.error.message);
          console.log('HTTP Error', err);
        }
      )
  }
  editTemplate(data) {
    this.modalService.open(this.templateCreationPopUp, { size: 'lg' });
    const inputReq = {
      templateId: data.RTM_ID
    }
    this.dataService.getTemplateDataById(inputReq).subscribe((response) => {
      this.existingRecord = true;
      this.editData = response;
      this.form.patchValue({
        serviceType: response.SERVICE_TYPE_ID,
        serviceName: response.SM_ID,
        editorContent: response.REPORT_TEMPLATE
      })

      this.getServiceNames();

    },
      (err) => {
        this.toastr.error('Something went wrong!');
        console.log('HTTP Error', err);
      }
    )
  }
  getServiceTypeList() {
    this.dataService.getServiceTypeList()
      .subscribe((response) => {
        this.serviceTypeList = response;
      },
        (err) => {
          this.toastr.error('Something went wrong!');
          console.log('HTTP Error', err);
        }
      )
  }
  getServiceNames() {
    this.selectedName = [];
    const data = this.form.value.serviceType;
    this.selectedName = this.serviceTypeList.filter(
      type => type.SERVICE_TYPE_ID === data);
    this.getServiceNameList();
  }
  changeServiceName()
  {
    let value = this.form.value.serviceName
    let serviceName = this.serviceNamesList.find((data) => { return data.SM_ID === value})
    this.form.patchValue({
      templateName: serviceName.SERVICE_NAME
    })
  }
  getServiceNameList() {
    const inputRequest = {
      serviceTypeId: this.form.value.serviceType,
      hospitalId: this.userInfo.hospitalID
    }
    this.dataService.getServiceNameList(inputRequest).subscribe((response) => {
      this.serviceNamesList = response;
    },
      (err) => {
        this.toastr.error('There are no services for selected service type');
        console.log('HTTP Error', err);
      }
    )
  }
  getData() {
    this.ipSampleCollectioninfo = '';
    const inputRequest = {
      serviceName: this.advSearchForm.controls.serviceName.value,
      templateName: this.advSearchForm.controls.templateName.value,
      hospitalId: this.userInfo.hospitalID
    }
    this.dataService.gettemplatesList(inputRequest)
      .subscribe((response) => {
        console.log("templates list", response)
        this.ipSampleCollectioninfo = response.data;
        this.ipSampleCollection = new MatTableDataSource(this.ipSampleCollectioninfo);
        this.ipSampleCollection.sort = this.ipSort;
        this.ipSampleCollection.paginator = this.ipPaginator;
      });
  }
  public createTemplate(): void {
    this.existingRecord = false;
    this.form.patchValue({
      serviceType: '',
      serviceName: '',
      editorContent: '',
      templateName: ''
    })
    this.modalService.open(this.templateCreationPopUp, { size: 'lg' });
  }

}
