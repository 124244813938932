<div class="d-flex">
  <app-sidenav></app-sidenav>
  <div class="container-fluid total-page">
    <div class="row page-header">      
      <div class="col-md-11 col-11 col-lg-11 col-sm-11 mt-2 align-center">
        <div class="cont1 centercont">
          <button class="button-center bgg-clr col-6" 
                  [class.cont]="bodyDisplay === 'IP'"
                  (click)="bodyDisplay = 'IP';getRadiologyPatientsList();">In-patient</button>
          <button class="button-center bgg-clr col-6" 
                  [class.cont]="bodyDisplay === 'OP'"
                  (click)="bodyDisplay = 'OP';getRadiologyPatientsList();">Out-patient</button>
        </div>
      </div>
      <div class="col-1 col-md-1 col-lg-1 col-sm-1">
        <div class="ic-postion float-right mt-2">
            <span class="fa-stack fa-1x has-badge" data-count="5">
                <i class="fa fa-circle fa-stack-1x fa-inverse"></i>
                <i class="fa fa-bell fa-stack-1x ic red-cart"></i>
            </span>
            <span class="dash-main-icon">To-Do</span>
        </div>
      </div>
    </div>
        <!-- Advance search -->
    <div class="row justify-content-center m-0">
      <div class="">
        <div class="">
          <form [formGroup]="advSearchForm" (ngSubmit)="getRadiologyPatientsList()" class="form-inline" autocomplete="off">
            <div class="row advance-search mt-4">
              <div class="col-sm-4  mb-3 p-0">
                <div class="form-group">
                  <label class="col-sm-4 p-0 modname" >UMR NO:</label>
                  <div class="col-sm-8">
                    <input name="textinput" 
                           formControlName="URMNO" 
                           class="form-control input-textt1 w-100" 
                           type="text" />
                  </div>
                </div>
              </div>

                <div class="col-sm-4  mb-3 p-0" [ngClass]="{ 'opDisplay': bodyDisplay !== 'OP' }">
                    <div class="form-group">
                        <label class="col-sm-4 p-0 modname" >VISIT ID:</label>
                        <div class="col-sm-8">
                        <input  name="textinput" formControlName="VISITID" class="form-control input-textt1 w-100" type="text">
                        </div>
                    </div>
                </div>

                <div class="col-sm-4  mb-3 p-0" [ngClass]="{ 'opDisplay': bodyDisplay !== 'IP' }">
                    <div class="form-group">
                        <label class="col-sm-4 p-0 modname" >IP NUM:</label>
                        <div class="col-sm-8">
                        <input  name="textinput" formControlName="IPNUM" class="form-control input-textt1 " type="text">
                        </div>
                    </div>
                </div>

                <div class="col-sm-4 mb-2  p-0">
                    <div class="form-group">
                        <label class="col-sm-4 modname align-box " >Name:</label>
                        <div class="col-sm-8">
                        <input  name="textinput" formControlName="PATIENTNAME" class="form-control input-textt1 w-100" type="text">
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 p-3" style="text-align: right;">
                    <button type="submit" class="btn btn-primary advanced-search-btn ">Search</button>
                </div>
            </div>
          </form>
        </div>
      </div>
            
      <div class="col-9 d-flex pt-4 pl-0 pb-4">
        <div class="col-md-3 text-left">
            <button class="button-center bgg-clr" [class.searchCont]="searchType === 'All'" (click)="searchType = 'All';clearSearchFields();getData('All');">All</button>
            <br>
            <span class="sampleCountLeft labCount">({{totalCount}})</span>
        </div>
        <div class="col-md-3 text-center">
            <button class="button-center bgg-clr" [class.searchCont]="searchType === 'Pending'" (click)="searchType = 'Pending';clearSearchFields();getData('Pending');">Pending </button>
            <br>
            <span class="labCount">({{pendingCount}})</span>
        </div>
        <div class="col-md-3 text-right">
            <button class="button-center bgg-clr" 
                    [class.searchCont]="searchType === 'Completed'"  
                    (click)="searchType = 'Completed';clearSearchFields();getData('Completed');">Completed </button>
            <br>
            <span class="sampleCountRight labCount">({{completedCount}})</span>
        </div>
  
        <!-- <div class="col-md-3 text-right">
            <button class="button-center bgg-clr" 
                    [class.searchCont]="searchType === 'ResultEntry'"  
                    (click)="searchType='ResultEntry';clearSearchFields();getData('ResultEntry');">Result Entry </button>
            <br>
            <span class="sampleCountRight labCount">(----)</span>
        </div> -->

      </div>
    </div>
        
    <!-- IP Result Table -->
    <div class="row justify-content-center mb-2" [ngClass]="{ 'opDisplay': bodyDisplay == 'OP' }">`
      <div class="col-md-12" >
        <div class="matcontent">
            <mat-table #table [dataSource]="ipSampleCollection" matSort >

                <ng-container matColumnDef="UMRNUM">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>UMR NO</div>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                    <div>{{element.umrNo}}</div>
                </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="IPNUM">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                    <div>IP NUM</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element"  matTooltip="">
                    <div>{{element.ipNr}}</div>
                </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="NAME">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                    <div>NAME</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element"  matTooltip="">
                    <div>{{element.title}} {{element.patientName}}</div>
                </mat-cell>
                </ng-container>
        
                <ng-container matColumnDef="GENDERAGE">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                    <div>GENDER/<br/> AGE</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                    <div>{{element.gender}}/{{element.dob | ageCalculation}}</div>
                </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="REFERREDBY">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                    <div>REFERRED BY</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div>{{element.referredBy}}</div>
                </mat-cell>
                </ng-container>
                <ng-container matColumnDef="SERVICETYPE">
                    <mat-header-cell *matHeaderCellDef  mat-sort-header>
                        <div>Service Name</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div>{{element.serviceName}}</div>
                    </mat-cell>
                    </ng-container>
                <ng-container matColumnDef="REQUESTDATE">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                    <div>REQUEST DATE</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div>{{element.serviceRequestDate}}</div>
                </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="REQUESTTIME">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                    <div>REQUEST TIME</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element"  matTooltip="">
                    <div>{{element.serviceRequestTime}} </div>
                </mat-cell>
                </ng-container>            
                
                <ng-container matColumnDef="ACTION">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                    <div>ACTION</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                    <div *ngIf="!element.resultEntry">
                        <button (click)="getPatientLabServices(element,'IP')"><span>{{element.sampleCollection}}</span></button>
                    </div>
                    <div>
                        <button (click)="getResultEntryServices(element,'IP')">
                            <span *ngIf="searchType === 'Completed'">Completed</span>
                            <span *ngIf="searchType !== 'Completed'">Enter Results</span>    
                        </button>
                    </div>
                </mat-cell>
                </ng-container>
        
                <mat-header-row *matHeaderRowDef="ipcolumnsToDisplay; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let element; columns: ipcolumnsToDisplay"></mat-row>
            </mat-table>
    
            <div class="mat-norecords" *ngIf="ipSampleCollection.data.length === 0"> NO RECORD FOUND WITH THE SEARCH VALUES</div>
            <mat-paginator #ipPaginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons style="border-bottom-left-radius:6px; border-bottom-right-radius:6px" class="mat-paginator-sticky">
            </mat-paginator>
        </div>
      </div>
    </div>

    <!-- OP Result Table -->
    <div class="row justify-content-center mb-2" [ngClass]="{ 'opDisplay': bodyDisplay == 'IP' }">
      <div class="col-md-12" >
        <div class="matcontent">
            <mat-table #table [dataSource]="opSampleCollection" matSort >

                <ng-container matColumnDef="UMRNUM">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>UMR NO</div>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                    <div>{{element.umrNo}}</div>
                </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="NAME">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                    <div>NAME</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element"  matTooltip="">
                    <div>{{element.title}} {{element.patientName}}</div>
                </mat-cell>
                </ng-container>
        
                <ng-container matColumnDef="GENDERAGE">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                    <div>GENDER/<br/> AGE</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                    <div>{{element.gender}}/{{element.dob | ageCalculation}}</div>
                </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="REFERREDBY">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                    <div>REFERRED BY</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div>{{element.referredBy}}</div>
                </mat-cell>
                </ng-container>
                <ng-container matColumnDef="SERVICENAME">
                    <mat-header-cell *matHeaderCellDef  mat-sort-header>
                        <div>Service Name</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div>{{element.serviceName}}</div>
                    </mat-cell>
                    </ng-container>
                <ng-container matColumnDef="REQUESTDATE">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                    <div>REQUEST DATE</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div>{{element.serviceRequestDate}}</div>
                </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="REQUESTTIME">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                    <div>REQUEST TIME</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element"  matTooltip="">
                    <div>{{element.serviceRequestTime}} </div>
                </mat-cell>
                </ng-container>            
                
                <ng-container matColumnDef="ACTION">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                    <div>ACTION</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                    <!-- <div *ngIf="element.resultEntry">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action"><mat-icon>more_vert</mat-icon></button>
                        <mat-menu #menu="matMenu">
                            <button (click)="getPatientLabServices(element,'OP')" mat-menu-item><span>{{element.sampleCollection}}</span></button>
                            <button *ngIf="element.resultEntry" (click)="getResultEntryServices(element,'OP')" mat-menu-item ><span>Result Entry</span></button>
                        </mat-menu>
                    </div> -->
                    <div *ngIf="!element.resultEntry">
                        <button (click)="getPatientLabServices(element,'OP')"><span>{{element.sampleCollection}}</span></button>
                    </div>
                    <div>
                        <button (click)="getResultEntryServices(element,'OP')">
                            <span *ngIf="searchType === 'Completed'">Completed</span>
                            <span *ngIf="searchType !== 'Completed'">Enter Results</span>
                        </button>
                    </div>
                </mat-cell>
                </ng-container>
        
                <mat-header-row *matHeaderRowDef="opcolumnsToDisplay; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let element; columns: opcolumnsToDisplay"></mat-row>
            </mat-table>
    
            <div class="mat-norecords" *ngIf="opSampleCollection.data.length === 0"> NO RECORD FOUND WITH THE SEARCH VALUES</div>
            <mat-paginator #opPaginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons style="border-bottom-left-radius:6px; border-bottom-right-radius:6px" class="mat-paginator-sticky">
            </mat-paginator>
        </div>
      </div>
    </div>
  </div>

<!-- OP Report Infomation popup -->
<ng-template #OPPreAuthPopup let-modal>
    <div class="modal-content">

        <div class="row mt-3">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <h4 class="mode-title text-center mt-2 ">Report Information</h4>
            </div>
            <div class="col-md-4">
                <i class="fa fa-times text-danger float-right handCursor p-3" data-dismiss="modal"
                    aria-hidden="true" (click) ="onClosePopup()">&nbsp;<span class="text-dark" >close</span></i>
            </div>
        </div>

        <div class="modal-body">
           
        </div>
        <hr>
        <div class="col-md-12 tab d-flex p-0" *ngIf="servicesList !== undefined">
            <div class="col-md-2 p-0 m-0" *ngFor="let service of servicesList;let i = index">
                <button  class="tablinks" [ngClass]="{'selService':serviceIndex == i}" (click)="serviceIndex = i;sampleServices = servicesList[i]" > <span *ngIf="service[0].sequenceNo !== undefined">Sample Id: {{service[0].sequenceNo}}</span></button>
            </div>
        </div>
        <hr>
        <div>
        <!-- <div *ngFor="let service of servicesList;let i = index"> -->
            <div class="mode-box" >
                <div class="row" >
                    <div class="col-md-1 "></div>
                    <div class="col-md-4 modname text-white mt-1"> Billing ID : {{sampleServices[0].billingId}} </div>
                    <div class="col-md-2 "></div>
                    <div class="col-md-2  modname text-white mt-1"> {{sampleServices[0].serviceTypeName}}</div>
                    <div class="col-md-1 "></div>
                    <div class="col-md-2  modname  mt-1"> <img class="ic-hover" src="assets/labimages/icon.svg">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 ml-3">
                    <label class="modname">
                        <u> Test Name</u>
                    </label>
                </div>
                <div class="col-md-2"> 
                    <label class="modname">
                        <u>Accept</u>
                    </label>
               </div>
                <div class="col-md-3">
                    <label class="modname">
                        <u>Sample Id: {{sampleServices[0].sequenceNo}}</u>
                    </label>
                </div>
                <div class="col-md-3">
                    <label class="modname">
                        <u>Requested By: {{sampleServices[0].serviceReuestedBy}}</u>
                    </label>
                </div>
                <!-- <div class="col-md-2">
                    <label class="modname">
                        <u>Tube Color : </u><label class="text-danger "> &nbsp;Null</label>
                    </label>
                </div>
                <div class="col-md-2">
                    <label class="modname">
                        <u>Barcode:</u>
                    </label>
                </div> -->
            </div>
            <div class="row mb-2" *ngFor="let list of sampleServices;let k = index;">
                <div class="col-md-2 ml-3 dis-align">
                    <label class="modname">
                       {{k+1}}.{{list.serviceName}}
                    </label>
                </div>
                <div class="col-md-1 dis-align">
                    <input type="checkbox" name="acceptServie" aria-label="Checkbox for following text input" [disabled]="(list.serviceStatus)?'':null" class="max-1" [(ngModel)]="list.serviceRequest" #acceptServie = "ngModel" (change)="checkServiceIsActive(sampleServices)" value='true'>
                </div>
                <div class="col-md-2 dis-align">
                    <label class="modname">
                        <u>Remarks:</u>
                    </label>
                    <input type="text" class="max" [disabled]="(list.serviceStatus)?'':null" [(ngModel)]="list.serviceRemarks" #remarks = "ngModel" name="remarks"> 
                </div>
                <div class="col-md-3 dis-align m-3 d-flex">
                    <div class="col-md-11"></div>
                    <!-- <i class="fa fa-info-circle col-md-1" aria-hidden="true"></i> -->
                </div>
            </div>
            <div class="col-md-11 service-btn">
                <button type="button" class="cont12 float-right" (click)="saveServiceRequests(sampleServices)" [disabled]="!servicesSequence[sampleServices[0].sequenceNo]">Save</button>
            </div>
        </div>
       
        <hr>
       
        <div class="row mb-4">
            <div class="col-md-11">
                <!-- <button class="cont12 float-right m-2" data-dismiss="modal" aria-hidden="true"> Submit</button>
                <button class="cont12 float-right m-2"> Print</button> -->
            </div>
        </div>
    </div>
</ng-template>

<!-- IP Report Infomation popup -->
<ng-template #IPPreAuthPopup let-modal>
    <div class="modal-content">
        <div class="row mt-3">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <h4 class="mode-title text-center mt-2 ">Report Information</h4>
            </div>
            <div class="col-md-4">
                <i class="fa fa-times text-danger float-right handCursor p-3" data-dismiss="modal"
                    aria-hidden="true" (click) ="onClosePopup()">&nbsp;<span class="text-dark" >close</span></i>
            </div>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-12">

                    <div class="form-inline" autocomplete="off">
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Name :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">{{patientInfo?.TITLE}} {{patientInfo?.PATIENT_NAME}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">IP Number :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.IP_NR}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Ward(Bed No) :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.WM_WARD_NAME}} <span>({{patientInfo?.WM_BED_NO}})</span></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Gender/Age :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">{{patientInfo?.GENDER}}/{{patientInfo?.AGE}}</p>
                                </div>
                            </div>
                        </div>
                        
                        <br>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Admitting Doctor :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.DM_NAME}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname"> Payee Name :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.PIM_NAME}}</p>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname"> Referred By :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.REFERRED_BY}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <hr>
        <div class="col-md-12 tab d-flex p-0" *ngIf="servicesList !== undefined">
            <div class="col-md-2 m-0" *ngFor="let service of servicesList;let i = index">
                <button  class="tablinks" [ngClass]="{'selService':serviceIndex == i }" (click)="serviceIndex = i;sampleServices = servicesList[i]" > <span *ngIf="service[0].sequenceNo !== undefined">Sample Id: {{service[0].sequenceNo}}</span></button>
            </div>
        </div>
        <hr>
        <div>
            <div class="mode-box" >
                <div class="row" >
                    <!-- <div class="col-md-1 "></div> -->
                    <!-- <div class="col-md-4 modname text-white mt-1"> Billing ID : {{service[0].billingId}} </div> -->
                    <!-- <div class="col-md-2 "></div> -->
                    <div class="col-md-10  modname text-white mt-1 text-center"> {{sampleServices[0].serviceTypeName}}</div>
                    <!-- <div class="col-md-1 "></div> -->
                    <div class="col-md-2  modname  mt-1"> <img class="ic-hover" src="assets/labimages/icon.svg">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 ml-3">
                    <label class="modname">
                        <u> Test Name</u>
                    </label>
                </div>
                <div class="col-md-1"> 
                    <label class="modname">
                        <u>Accept</u>
                    </label>
               </div>
               <div class="col-md-1"> 
                    <label class="modname">
                        <u>Reject</u>
                    </label>
                </div>
                <div class="col-md-3">
                    <!-- <label class="modname">
                        <u>Sample Id: {{sampleServices[0].sequenceNo}}</u>
                    </label> -->
                </div>
                <div class="col-md-3">
                    <label class="modname">
                        <u>Requested By: {{sampleServices[0].serviceReuestedBy}}</u>
                    </label>
                </div>
            </div>
            <div class="row mb-2" *ngFor="let list of sampleServices;let k = index;">
                <div class="col-md-2 ml-3 dis-align">
                    <label class="modname">
                       {{k+1}}.{{list.serviceName}}
                    </label>
                </div>
                <div class="col-md-1 dis-align">
                    <input type="checkbox" name="acceptServie" aria-label="Checkbox for following text input"  class="max-1" [disabled]="(list.serviceStatus)?'':null" [(ngModel)]="list.serviceAccept" #acceptServie = "ngModel" (change)="list.serviceReject=false;checkServiceIsActive(sampleServices)" value='true'>
                </div>
                <div class="col-md-1 dis-align">
                    <input type="checkbox" name="acceptServie" aria-label="Checkbox for following text input" [disabled]="(list.serviceStatus)?'':null" class="max-1" [(ngModel)]="list.serviceReject" #acceptServie = "ngModel" (change)="list.serviceAccept = false;checkServiceIsActive(sampleServices);" value='true'>
                </div>
                <div class="col-md-2 dis-align servRemarks">
                    <label class="modname">
                        <u>Remarks:</u>
                    </label>
                    <input type="text" class="max" [disabled]="(list.serviceStatus)?'':null" [(ngModel)]="list.serviceRemarks" #remarks = "ngModel" name="remarks" (ngModelChange)="checkServiceIsActive(sampleServices);">
                    <div *ngIf="list.serviceReject && list.serviceRemarks == ''"  class="bg-warningtxt width100">
                        Remarks is required
                      </div> 
                </div>
            </div>
            <div class="col-md-11 service-btn">
                <button type="button" class="cont12 float-right" (click)="saveServiceRequests(sampleServices)" [disabled]="!servicesSequence[sampleServices[0].sequenceNo]">Save</button>
            </div>
        </div>
       
        <hr>
       
        <div class="row mb-4">
            <div class="col-md-11">
                <!-- <button class="cont12 float-right m-2" data-dismiss="modal" aria-hidden="true"> Submit</button>
                <button class="cont12 float-right m-2"> Print</button> -->
            </div>
        </div>
    </div>
</ng-template>

<!-- IP Result Entry Popup -->
<ng-template #IPResultEntryPreAuthPopup let-modal>
    <div class="modal-content">
        <div class="row mt-3">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <h4 class="mode-title text-center mt-2 ">REPORT INFORMATION</h4>
            </div>
            <div class="col-md-4">
                <i class="fa fa-times text-danger float-right handCursor p-3" data-dismiss="modal"
                    aria-hidden="true" (click) ="onClosePopup()">&nbsp;<span class="text-dark" >close</span></i>
            </div>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-12">

                    <div class="form-inline" autocomplete="off">
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Name :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">{{patientInfo?.TITLE}} {{patientInfo?.PATIENT_NAME}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">IP Number :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.IP_NR}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Ward(Bed No) :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.WM_WARD_NAME}} <span>({{patientInfo?.WM_BED_NO}})</span></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Gender/Age :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">{{patientInfo?.GENDER}}/{{patientInfo?.AGE}}</p>
                                </div>
                            </div>
                        </div>
                        
                        <br>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Admitting Doctor :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.DM_NAME}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname"> Payee Name :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.PIM_NAME}}</p>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname"> Referred By :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.REFERRED_BY}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="tab col-md-12 d-flex p-0" *ngIf="labResultSequenceNo !== undefined">
                <div class="col-md-2 p-0 m-0"  *ngFor="let sequenceNo of labResultSequenceNo;let i = index">
                    <button class="tablinks " [ngClass]="{'selService':resultIndex == i}" (click)="resultIndex = i;labResultServices = labResultServicesList[sequenceNo]" > <span *ngIf="sequenceNo !== undefined">Sample Id: {{sequenceNo}}</span></button>
                </div>
            </div>
            <hr>
            <div class="mode-box" >
                <div class="row m-0" >
                    <div class="col-md-2 modname text-white mt-1 text-center">PARAMETER</div>
                    <div class="col-md-2 modname text-white mt-1 text-center">RESULT</div>
                    <div class="col-md-1 modname text-white mt-1 text-center">UNITS</div>
                    <div class="col-md-2 modname text-white mt-1 text-center">REFERENCE RANGES</div>
                    <div class="col-md-3 modname text-white mt-1 text-center">REMARKS</div>
                    <div class="col-md-2 modname text-white mt-1 text-center">METHODOLOGY</div>
                </div>
            </div>

            <div class="row m-0">
                <div class="col-md-12 resultLabel" *ngFor="let service of labResultServices; let i= index">
                    <div class="mt-1 pl-4 resultMainLabel" *ngIf="service[0].subTestName != null && service[0].subTestName != ''">{{i+1}}. {{service[0].serviceName}}</div>
                    <div  class="row m-0 pl-3" *ngFor="let subTests of service; let k= index">
                        <div class="col-md-2 mt-1 pl-4 resultLabel" *ngIf="subTests.subTestName == null || subTests.subTestName == ''"> {{i+1}}. {{subTests.serviceName}}</div>
                        <div class="col-md-2 mt-1 pl-4 resultLabel" *ngIf="subTests.subTestName != null && subTests.subTestName != ''"> {{alphabetIndex[k]}}. {{subTests.subTestName}}</div>
                        <div class="col-md-2 text-center mt-1">
                            <input type="text" name="serviceResult" class="mode-input1" [class.resultTyp]="subTests.resultEnterStatus" [(ngModel)]="subTests.resultTextValue" [disabled]="subTests.resultTextEnterStatus" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(service)" value="subTests.resultTextValue">
                            <span class="resultEnterStatus" [class.checkresultStatus]="subTests.resultEnterStatus">{{subTests.resultAlert}}</span>
                        </div>
                    
                        <div class="col-md-1 text-center mt-1">
                            <input type="text" name="serviceUnits" [(ngModel)]="subTests.ResultUnits" #serviceUnits = "ngModel" class="mode-units" value="subTests.ResultUnits" disabled>
                        </div>
                        <div class="col-md-2 text-center mt-1">
                            <input type="text" name="serviceRefRange" [(ngModel)]="subTests.ResultRange" #serviceRefRange = "ngModel" class="mode-input1" value="subTests.ResultRange" disabled>
                        </div>
                        <div class="col-md-3 text-center mt-1">
                            <!-- <input type="text" class="mode-input1"> -->
                            <textarea name="serviceResultRemarks" [(ngModel)]="subTests.resultRemarks" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2">{{subTests.resultRemarks}}</textarea>
                        </div>
                        <div class="col-md-2 text-center mt-1">
                            <input type="text" name="methodology" [(ngModel)]="subTests.ResultMethod" #methodology = "ngModel" class="mode-input1" value="subTests.ResultMethod" disabled>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-4">
                <div class="col-md-12 text-right">
                    <button class="cont13 col-md-1 ml-2" [disabled]="labResultStausSequenceNo[labResultServices[0][0].sequenceNo]" (click)="addLabResultsData(labResultServices)">Save</button>
                    <button class="cont13 col-md-1 ml-2" [disabled]="!labResultServices[0][0].resultServiceStatus" (click)="printLabResults(labResultServices)">print</button>
                </div>
            </div> 
        </div> 
    </div>
</ng-template>

<!-- OP Result Entry Popup -->
<ng-template #OPResultEntryPreAuthPopup let-modal>
   <div class="model-content">
    <div class="row mt-3">
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <h4 class="mode-title text-center mt-2 ">Report Information</h4>
        </div>
        <div class="col-md-4">
            <i class="fa fa-times text-danger float-right handCursor p-3" data-dismiss="modal"
                aria-hidden="true" (click) ="onClosePopup()">&nbsp;<span class="text-dark" >close</span></i>
        </div>
    </div>
    <div class="modal-body p-0">
      <app-radiology-summary 
        (closePopup)='closeModalPopup($event)'></app-radiology-summary>
    </div>
   </div>
</ng-template>