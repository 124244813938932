import { Component, ElementRef, Input, OnInit, OnChanges, ViewChild } from '@angular/core';
import { AppointmentService } from '../../appointments/appointment.service';
import {
  AppointmentPatientAllCities,
  AppointmentPatientRegistration,
  AppointmentPatientSearch,
  GettingCityInfoById,
} from '../../appointments/appointmentconstants';
import { ViewAppointmentsModel } from '../../shared/appointmentsModels/viewAppointmentsModel';
import { PatientProfile } from '../../shared/appointmentsModels/patientProfile';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { SearchDepartments } from '../../shared/appointmentsModels/searchDepartments';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { BillingService } from '../../billing-module/billing.service';
import { SearchHospitals } from '../../shared/appointmentsModels/searchHospitals';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-patient-registration-form',
  templateUrl: './patient-registration-form.component.html',
  styleUrls: ['./patient-registration-form.component.css']
})
export class PatientRegistrationFormComponent implements OnInit {
  @Input() arrayIndex: number;
  @Input() patientID: number;
  @Input() editOption: boolean;
  @Input() editButton: boolean;
  dataLoaded = false;
  date = new Date().getTime();
  toDayDate = new Date();
  // selectedId: any = 'Aadhar';
  UMRvalue: number = null;
  nameValue: string = null;
  mobileValue: string = null;
  appointmentsData: ViewAppointmentsModel[];
  patientsData: PatientProfile[] = [];
  patientAge: string;
  patientCityModel: { cityName: string, cityId: number }[] = [];
  patientDobYear = 0;
  patientDobMonth = 0;
  patientDobDate = 0;
  bodyDisplay = 'registration';
  addVisitAvailable = false;
  idPlaceholder = 'Enter Id Number';
  formFillPatient: PatientProfile;
  doctorNdepAvailable = false;
  duplicateRecords: PatientProfile[];
  idPattern: string;
  existingPatientsAvailability = false;
  doctorData: { docName: string, docId: number, depId: number }[];
  indexSelected: number;
  visitindex: number;
  maxDate: string;
  minDate: string;
  minYear: number;
  minMonth = 0;
  minDay = 0;
  maxYear: number;
  maxMonth = 11;
  maxDay = 30;
  yearError = false;
  monthError = false;
  addVisitArray: boolean[] = [];
  dateError = false;
  availableUMR: number[];
  existingUmr = false;
  selectedVisitId: number;
  selectedVisitIdArray: {};
  existingPatients: PatientProfile[];
  hospitalData: SearchHospitals[];
  public show: any = false;
  public showin: any = false;
  public doctorList: any = [];
  public filterDoctorList: any = [];
  public departmentList: SearchDepartments[];
  public payeeList: any = [];
  public extRefList: any = [];
  public visitTypeList: any = [];
  public patientVisitStatus: any = '';
  public itemDoctor = false;
  public itemExref = false;
  extReferalDoctor: string;
  extReferalList: string[] = [];
  doctorNameFilterKeyword = 'doctorName';
  externalReferenceKeyword = 'doctorName';
  citiesKeyword = 'cityName';
  @ViewChild('dataFillModal') private fillModal: ElementRef;
  @ViewChild('duplicateRecordsMod') private duplicate: ElementRef;
  @ViewChild('StatusInfoModal') private statusInfo: ElementRef;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  extDoctor$ = new Subject<string>();
  extDoctorClick$ = new Subject<string>();
  public titleList: any = [
    'B/O.', 'Master.', 'Miss.', 'Mr.', 'Ms.', 'Mrs.'
  ];
  public userInfo: any = {};
  public itemCity = false;
  model: PatientProfile = {
    address: '',
    city: '',
    country: 'India',
    createDateTime: '',
    createId: '',
    dateOfBirth: '',
    emailId: '',
    familyGroupId: '',
    familyGroupRegistration: '',
    gender: '',
    identityIdNr: '',
    identityType: '',
    mobileNumber: '',
    nextOfKin1Mobilenr: null,
    nextOfKin1Name: '',
    nextOfKin1Relation: '',
    nextOfKinMobilenr: '',
    nextOfKinName: '',
    nextOfKinRelation: '',
    patientID: 0,
    patientName: '',
    firstName: '',
    lastName: '',
    permanentHomeAddress: '',
    pincode: '',
    regDate: '',
    stateCountry: '',
    status: 1,
    title: '',
  };
  citiesArray = [];
  // identityNumber: FormControl = new FormControl('', [Validators.required, Validators.pattern('^\\d{4}\\s\\d{4}\\s\\d{4}$')]);
  constructor(private appointmentService: AppointmentService, private toastr: ToastrService,
    private modalService: NgbModal, private tokenService: TokenStorageService,
    private billingService: BillingService,
    private service: CommonService) {
    this.userInfo = this.tokenService.getUser();
  }

  ngOnInit(): void {
    if (this.editButton) {
      this.gettingPatientData(this.patientID, this.arrayIndex);
    }
  }
  ngOnChanges() {
    if (!this.editButton) this.setValuestoEmpty();
    else this.gettingPatientData(this.patientID, this.arrayIndex);
  }
  assigningPatterns(event): any {
    if (event.target.value === 'Aadhar') {
      this.idPattern = '^\\d{4}\\d{4}\\d{4}$';
      // this.idPlaceholder = '123412341234';
    }
    else if (event.target.value === 'Driving license') {
      this.idPattern = '^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$';
      // this.idPlaceholder = 'AA1220101234567';
    }
    else if (event.target.value === 'Voter ID') {
      this.idPattern = '^([a-zA-Z]){3}([0-9]){7}?$';
      // this.idPlaceholder = 'ABC1234567';
    }
    else if (event.target.value === 'Pan Card') {
      this.idPattern = '^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$';
      // this.idPlaceholder = 'ABCDE1234A';

    }
    else if (event.target.value === 'Passport') {
      this.idPattern = '^(?!^0+$)[a-zA-Z0-9]{6,9}$';
      // this.idPlaceholder = 'Passport Number';
    }
  }
  keyUpEnterFunction(event, idRec): any {
    if (idRec === 'num') {
      this.UMRvalue = null;
      this.nameValue = '';
    }
    else if (idRec === 'id') {
      this.nameValue = '';
      this.mobileValue = '';
    }
    else if (idRec === 'name') {
      this.mobileValue = '';
      this.UMRvalue = null;
    }
    // if (event.keyCode === 13) {
    //   this.searchByNumber(event.target, idRec);
    // }
  }

  gettingPatientData(patientid, recIndex): any {
    // this.show = true;
    // this.existingPatientsAvailability = false;
    this.editButton = true;
    this.editOption = true;
    this.visitindex = undefined;
    // if (this.indexSelected === recIndex) {
    //   this.toggle();
    //   this.indexSelected = null;
    // }
    // else {
    //   this.indexSelected = recIndex;
    // }
    Object.keys(this.model).forEach(keys => {
      this.model[keys] = '';
      if (keys === 'status') {
        this.model[keys] = 1;
      }
    });
    if (this.indexSelected === recIndex || this.indexSelected === undefined || (this.indexSelected !== recIndex)) {
      // (this.toggleOnOff === 'registration') ? 'nothing' : 'registration';
      this.indexSelected = null;
    }
    this.indexSelected = recIndex;
    const requestData = {
      patientID: patientid
    };
    this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, requestData)
      .subscribe(patients => {
        if (patients !== null && patients.length > 0) {
          this.model = patients[0];
          console.log(this.model.city);
          this.settingMinnMaxDates();
          this.gettingAge(this.model.dateOfBirth);
        }
      });
    //   this.gettingAllCitiesData();
  }
  doctorChange(doctorId: any): any {
    const docObj = this.doctorList.filter(obj => obj.doctorId === doctorId);
    if (docObj.length !== 0) {
      //   this.saveVisitFormData.departmentId = docObj[0].departmentId;
    }
    // this.loadVisitType(doctorId);
  }
  selectAutocompleteEvent(item: any, field: string): void {
    if (field === 'doctorname') {
      //   this.saveVisitFormData.doctorId = item.doctorId;
      this.itemDoctor = true;
      this.doctorChange(item.doctorId)
    }
    else if (field === 'externalReferral') {
      //  this.saveVisitFormData.extReferralId = item.referralId;
      this.itemExref = true;
    }
    else if (field === "cities") {
      this.model.city = item.cityName;
      console.log(item.cityId)
      this.gettingCityInfo(item.cityId);
      this.itemCity = true;
    }
  }

  onRemoveSelected(item: any, field: string): void {
    if (field === 'doctorname') {
      //   this.saveVisitFormData.doctorId = null ;
      //  this.saveVisitFormData.departmentId = "";
      this.itemDoctor = false;
    }
    else if (field === 'externalReferral') {
      //  this.saveVisitFormData.extReferralId = '';
      this.itemExref = false;
    }
    else if (field === 'cities') {
      this.model.city = "";
      this.itemCity = false;
    }
  }
  onChangeSearch(val: string): void {
    //return this.doctorsList.filter(option => option.DM_NAME.toLowerCase().includes(val));
    if (val.length < 3) { }
    else {
      this.gettingAllCitiesData(val);
    }
  }

  onFocused(e): void {
    // do something when input is focused
  }
  gettingAllCitiesData(data): any {
    this.patientCityModel = [];
    const cityobj = {
      keyWord: data
    };
    this.appointmentService.gettingAllCitiesForPatientWithInput(AppointmentPatientAllCities, cityobj)
      .subscribe(citiesData => {
        if (citiesData.length > 0) {
          citiesData.forEach(cityItem => {
            this.patientCityModel.push({ cityName: cityItem.cscmCityAreaName, cityId: cityItem.cityID });
          });
          this.citiesArray = this.patientCityModel;
        }
      });
  }
  newPatientWithExisitng(): any {
    this.model = this.formFillPatient;
    this.editOption = true;
    this.editButton = true;
    this.modalService.dismissAll();
    this.formFillPatient = undefined;
  }
  setValuestoEmpty(): any {
    this.patientsData = [];
    this.editButton = false;
    this.editOption = false;
    this.patientDobDate = 0;
    this.patientDobMonth = 0;
    this.patientDobYear = 0;
    this.maxDate = new Date().toISOString().split('T')[0];
    Object.keys(this.model).forEach(keys => {
      this.model[keys] = '';
      if (keys === 'status') {
        this.model[keys] = 1;
      }
      if (keys === 'patientID') {
        this.model[keys] = null;
      }
      if (keys === 'nextOfKin1Mobilenr') {
        this.model[keys] = null;
      }
    });
    // this.gettingAllCitiesData();
  }
  assigningYears(data): any {
    this.patientDobYear = Number(data.target.value);
    if (this.patientDobYear === this.maxYear) {
      this.maxMonth = 0;
      this.maxDay = 0;
      this.minMonth = 0;
      this.minDay = 0;
    }
    else {
      this.minMonth = 0;
      this.minDay = 0;
      this.maxMonth = 11;
      this.maxDay = 30;
    }
    this.gettingDOBFromAge();
  }
  checkingByYear(event): boolean {
    this.settingMinnMaxDates();
    if (Number(event.target.value) >= this.maxYear) {
      this.yearError = Number(event.target.value) > this.maxYear;
      this.patientDobYear = this.maxYear;
      this.maxMonth = 11;
      this.maxDay = 30;
      this.minDay = 0;
      this.minMonth = 0;
      this.gettingDOBFromAge();
      return false;
    }
    else {
      this.maxMonth = 11;
      this.maxDay = 30;
      this.minDay = 0;
      this.minMonth = 0;
      this.gettingDOBFromAge();
      this.yearError = false;
    }
    if (Number(event.target.value) <= this.minYear) {
      // this.patientDobYear = this.minYear;
      this.yearError = Number(event.target.value) < this.minYear;
      this.minMonth = 0;
      this.minDay = 0;
      this.maxMonth = 11;
      this.maxDay = 30;
      this.gettingDOBFromAge();
      return false;
    }
    this.yearError = false;
    return true;
  }
  checkingByMonth(event): boolean {
    if (Number(event.target.value) >= this.maxMonth) {
      this.yearError = Number(event.target.value) > this.maxMonth;
      if (this.patientDobYear === this.maxYear) {
        this.maxDay = 29;
      }
      this.patientDobMonth = this.maxMonth;
      this.gettingDOBFromAge();
      return false;
    }
    if (Number(event.target.value) <= this.minMonth) {
      this.yearError = Number(event.target.value) < this.minMonth;
      this.patientDobMonth = this.minMonth;
      this.gettingDOBFromAge();
      return false;
    }
    this.yearError = false;
    return true;
  }
  checkingByDay(event): boolean {
    if (Number(event.target.value) >= this.maxDay) {
      this.yearError = Number(event.target.value) > this.maxDay;
      this.patientDobDate = this.maxDay;
      this.gettingDOBFromAge();
      return false;
    }
    if (Number(event.target.value) <= this.minDay) {
      this.yearError = Number(event.target.value) < this.minDay;
      this.patientDobDate = this.minDay;
      this.gettingDOBFromAge();
      return false;
    }
    this.yearError = false;
    return true;
  }
  assigningMonths(data): any {
    this.patientDobMonth = Number(data.target.value);
    this.gettingDOBFromAge();
  }
  assigningDate(data): any {
    this.patientDobDate = Number(data.target.value);
    this.gettingDOBFromAge();
  }
  gettingDOBFromAge(): any {
    const ageToSeconds = ((this.patientDobYear * 365.25) + (this.patientDobMonth * 30.41) + this.patientDobDate) * 86400000;
    const secondsDiff = new Date().getTime() - ageToSeconds;
    this.model.dateOfBirth = this.appointmentService.requiredDateFormat(new Date(secondsDiff));
    // this.appointmentService.apppatientAge = this.patientDobYear + 'Y' + this.patientDobMonth + 'M' + this.patientDobDate + 'D';
  }

  checkInputDate(event): boolean {
    this.settingMinnMaxDates();
    const recValue = event.target.value;
    const recyear = parseInt(recValue, 10).toString();
    if ((recValue.length >= 10) && (recValue > this.maxDate) && (recyear.length >= 4)) {
      this.model.dateOfBirth = this.maxDate;
      this.gettingAge(this.maxDate);
      this.yearError = true;
      return false;
    }
    if ((recValue.length >= 10) && (recValue < this.minDate) && (recyear.length >= 4)) {
      this.model.dateOfBirth = this.minDate;
      this.gettingAge(this.minDate);
      this.yearError = true;
      return false;
    }
    this.yearError = false;
    return true;
  }
  gettingCityInfo(cityId: any): any {
    const cityobj = {
      cityID: cityId
    };
    this.appointmentService.patientCityInfoById(GettingCityInfoById, cityobj)
      .subscribe(cityInfo => {
        this.model.country = cityInfo[0].cityCountryName;
        this.model.stateCountry = cityInfo[0].cityState;
        this.model.pincode = cityInfo[0].cityPinZip;
        if (this.editOption) this.model.city = cityInfo[0].cityName
      });
  }
  settingMinnMaxDates(): any {
    const presentYear = this.toDayDate.getFullYear();
    const myDate = new Date();
    if (this.model.title === 'B/O.' || this.model.title === 'Baby') {
      this.minDate = this.appointmentService.requiredDateFormat(new Date(myDate.setFullYear(presentYear - 2) + 86400000));
      this.maxDate = this.appointmentService.requiredDateFormat(this.toDayDate);
      this.maxYear = 1;
      this.minYear = 0;
    }
    else if (this.model.title === 'Master.' || this.model.title === 'Miss.') {
      this.maxDate = this.appointmentService.requiredDateFormat(new Date(myDate.setFullYear(presentYear - 2)));
      this.minDate = this.appointmentService.requiredDateFormat(new Date(myDate.setFullYear(presentYear - 13) + 86400000));
      this.maxYear = 12;
      this.minYear = 2;
    }
    else {
      this.maxDate = this.appointmentService.requiredDateFormat(new Date(myDate.setFullYear(presentYear - 13)));
      this.minDate = null;
      this.maxYear = 150;
      this.minYear = 13;
    }
  }
  gettingAge(dobValue): any {
    const fullDate = new Date(dobValue);
    var todayDate = new Date();
    this.patientDobYear = todayDate.getFullYear() - fullDate.getFullYear();
    this.patientDobMonth = todayDate.getMonth() - fullDate.getMonth();
    this.patientDobDate = todayDate.getDate() - fullDate.getDate();

    if (this.patientDobMonth <= 0) {
      this.patientDobYear--;
      this.patientDobMonth = (12 + this.patientDobMonth);
    }
    if (this.patientDobDate < 0) {
      this.patientDobMonth--;
      this.patientDobDate = 31 + this.patientDobDate;
      if (this.patientDobDate == 31) {
        this.patientDobMonth++;
        this.patientDobDate = 0;
      }
    }
    if (this.patientDobMonth == 12) {
      this.patientDobYear = this.patientDobYear + 1;
      this.patientDobMonth = 0;
    }
    this.patientAge = this.patientDobYear + 'Y ' + this.patientDobMonth + 'M ' + this.patientDobDate + 'D';
    return this.patientAge
  }
  getAge(birthdate): any {
    const timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());
    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    // this.saveVisitFormData.patientAge = this.gettingAge(this.saveVisitFormData.dateOfBirth);
  }
  checkingMobileNumber(event): boolean {
    if (event.keyCode === 8 || event.keyCode === 46) {
      return true;
    }
    if (event.target.value.toString().length >= 10 || event.keyCode === 38 || event.keyCode === 40) {
      return false;
    }
    return true;
  }
  changeRadioGender(event): any {
    if (this.model.title === 'B/O.') {
      this.model.gender = event.target.value;
    }
    if (this.model.title === 'Baby') {
      this.model.gender = event.target.value;
    }
  }
  changingGenderFields(): any {
    this.yearError = false;
    // this.dateError = false;
    // this.monthError = false;
    if (this.model.title === 'Master.' || this.model.title === 'Mr.') {
      this.model.gender = 'MALE';
    }
    else if (this.model.title === 'B/O.' || this.model.title === 'Baby') {
      this.model.gender = '';
    }
    else {
      this.model.gender = 'FEMALE';
    }
    this.model.dateOfBirth = '';
    this.patientDobYear = 0;
    this.patientDobMonth = 0;
    this.patientDobDate = 0;
    this.settingMinnMaxDates();
  }
  existingPatientsCheck(data, type, existingPatientsModal): any {
    let serInput = {};
    if (type === 'num') {
      serInput = {
        patientMobile: data.value
      };
    } else if (type === 'email' && data.value !== '') {
      serInput = {
        patientEmail: data.value
      };
    }
    if (serInput) {
      this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, serInput)
        .subscribe((patients: PatientProfile[]) => {
          this.existingPatients = patients;
          this.existingPatientsAvailability = patients.length > 0;
          if (patients.length > 0) {
            this.modalService.open(existingPatientsModal, { size: 'lg' });
          }
        });
    }
  }
  onSubmit(): any {
    if (this.model.patientID === null || this.model.patientID === 0) {
      this.model.createDateTime = this.appointmentService.requiredDateFormat(this.toDayDate) + ' ' +
        this.toDayDate.toTimeString().substr(0, 8);
      this.model.regDate = this.appointmentService.requiredDateFormat(this.toDayDate);
      this.model.patientID = 0;
    }
    this.model.status = 1;
    this.duplicateRecordsModal(false);
  }
  duplicateRecordsModal(regDup): any {
    const serInput = {
      patientMobile: this.model.mobileNumber
    };
    this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, serInput)
      .subscribe((patients: PatientProfile[]) => {
        this.duplicateRecords = patients.filter(eachPatient => {
          return (Number(eachPatient.mobileNumber) === Number(this.model.mobileNumber)) &&
            (eachPatient.dateOfBirth === this.model.dateOfBirth)
            && eachPatient.gender === this.model.gender;
        });
        if (this.duplicateRecords.length > 0 && this.model.patientID === 0) {
          this.modalService.open(this.duplicate, { size: 'xl' });
        } else {
          this.regUpdatePatient();
        }
      });
  }
  regUpdatePatient(): any {

    this.appointmentService.registeringPatientIfUnavailable(AppointmentPatientRegistration, this.model)
      .subscribe(reply => {
        if (reply.errorMessage === null) {
          if (this.model.patientID === 0) {
            this.model.patientID = reply.recordID;
          }
          if (this.indexSelected === null) {
            //     this.loadPatientDetails(this.model, null);
          }
          this.service.displayPatientDetails({ patientID: this.model.patientID });
          this.toastr.success('Details submitted successfully.');
          this.resetValues();
          // this.toggleOnOff = (this.indexSelected !== null) ? 'nothing' : 'visitForm' ;
        }
        else {
          this.toastr.error('Please Check all the fields or Check Internet Connection');
        }
      });
  }
  checkingExistingUMR(receivedData): any {
    const exUmr = {
      patientID: receivedData.value
    };
    this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, exUmr)
      .subscribe(patients => {
        if (patients.length > 0) {
          this.existingUmr = true;
        }
        else {
          this.existingUmr = false;
        }
      });
  }

  resetValues(): any {
    this.maxDate = null;
    this.minDate = null;
    this.minYear = null;
    this.minMonth = 0;
    this.minDay = 0;
    this.maxYear = null;
    this.maxMonth = 11;
    this.maxDay = 30;
  }
}
