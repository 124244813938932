import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Type,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from '../../shared/loginservices/token-storage.service';
import { InpatientService } from '../inpatient.service';
import { Inpatient_URL } from '../inpatientConstants';
import { Location } from '@angular/common';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxPrinterService } from 'ngx-printer';

import { MatDialog } from '@angular/material/dialog';
import { PreviewComponent } from '../../print-management/preview/preview.component';
import { NursestationsService } from '../../ward-management/nursestations.service';
import { ToastrService } from 'ngx-toastr';

import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-inpatient',
  templateUrl: './inpatient.component.html',
  styleUrls: ['./inpatient.component.css'],
})
export class InpatientComponent implements OnInit, AfterViewInit {
  @ViewChild('paymentReceiptPopup') paymentReceiptPopup;
  @ViewChild('preAuthPopup') preAuthPopup;
  @ViewChild('creditBillSubmissionPopup') creditBillSubmissionPopup;
  @ViewChild('coverLetterPopup') coverLetterPopup;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('ipAdmissionInformationPopup')
  private ipAdmissionInformationPopup: ElementRef;
  listTitle = 'In Patients List';
  columnsToDisplay = [
    'IPNUM',
    'NAMEGENDERAGE',
    'SPONSOR',
    'CONSULTDOCTORWARDBED',
    'BILLAMOUNTTOTALADVPAID',
    'PAYABLE',
    'ADMISSIONDATE',
    'LENGTHOFSTAY',
    'ACTION',
  ];
  serviceUrl: any;
  patientsList: any = [];
  totalPatientsList = '';
  selfPayList: number;
  insuranceList: number;
  corporateList: number;
  patientsData = new MatTableDataSource(this.patientsList);

  public userInfo: any = {};
  public rolesInfo: any = [];
  public consultDoctorsList: any = [];
  public sponsersList: any = [];
  public admissionFormPrintData;
  public dischargeFormPrintData;
  public inputPropertiesList: any;
  public printPrescription = false;

  /* advanced search */
  advSearchForm: FormGroup;
  Control: AbstractControl;
  advancedSearch: any = {};
  coverLetterInfo : any;
  seletedPatientURMNo = 0;
  selectedIPPatientNO = 0;
  loading = true;
  enableDischargeList = false;
  admissionDateOrder = '';
  doctorNameOrder = '';
  sponserNameOrder = '';
  ipnumOrder = 'DESC';
  public patientQuickInformation: any = '';
  public selectedPatient: any = {};
  public dischargeSummaryPatientInfo: any = '';
  public existingDischargeSummaryInfo: any = '';
  public icdPatientDetails: any = {};
  existingRecord: boolean = false
  sponserName: any;
  sponserId: any;
  namesList: any = [];
  patientslistbtn: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tokenService: TokenStorageService,
    private inpatientService: InpatientService,
    private printerService: NgxPrinterService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private location: Location,
    public dialog: MatDialog,
    private nursestationsService: NursestationsService,
    private toastrService: ToastrService,
    private commonService: CommonService
  ) {
    if (this.tokenService.getToken()) {
      this.rolesInfo = this.tokenService.getUser().rolesInfo;
    }
    this.inputPropertiesList = ['patientName', 'patientIPNumber', 'umrNumber'];

    this.userInfo = this.tokenService.getUser();

    this.route.queryParams.subscribe((params) => {
      if (params.mode === 'dischargelist') {
        this.enableDischargeList = true;
        this.listTitle = 'IP Discharge List';
      } else {
        this.enableDischargeList = false;
        this.listTitle = 'In Patients List';
      }
    });
  }

  ngOnInit(): void {
    this.advSearchForm = this.formBuilder.group({
      IPNO: [''],
      PATIENTNAME: [''],
      ADMISSIONDATE: [''],
      UMRID: [''],
      CONSULTDOCTOR: [''],
      SPONSER: [''],
    });

    this.getConsultDoctorsList();
    this.getSponsersList();
    const dateT = this.commonService.formatDateTime(new Date());
    console.log(dateT);
    this.patientslistbtn = false;
  }

  ngAfterViewInit(): void {
    this.getPatientsList();

    if (this.enableDischargeList) {
      this.columnsToDisplay = [
        'IPNUM',
        'NAMEGENDERAGE',  
        'SPONSOR',
        'BILLAMOUNT',
        'ADMISSIONDATE',
        'DISCHARGETYPE',
        'DISCHARGEDDATE',
        'ACTION',
      ];
    }
  }
  getConsultDoctorsList(): void {
    const inputRequest = {
      HospitalID: this.userInfo.hospitalID,
    };
    this.inpatientService
      .consultDoctorsList(inputRequest)
      .subscribe((response) => {
        this.loading = false;
        this.consultDoctorsList = response;
      });
  }
  getSponsersList(): void {
    const inputRequest = {
      HospitalID: this.userInfo.hospitalID,
    };
    this.inpatientService.sponsersList(inputRequest).subscribe((response) => {
      this.loading = false;
      this.sponsersList = response;
    });
  }
  onSearch(): void {
    this.getPatientsList();
    this.sponserId = this.advSearchForm.controls.SPONSER.value;
    this.namesList = this.sponsersList.filter(obj => obj.ptmId == this.sponserId);
    this.sponserName = this.namesList[0].ptmName;
    
  }
  sortData(data): void {
    console.log(data);
    if (data.active === 'CONSULTDOCTORWARDBED') {
      this.admissionDateOrder = '';
      this.ipnumOrder = '';
      this.sponserNameOrder = ''
      if (data.direction === 'asc') {
        this.doctorNameOrder = 'ASC';
      } else {
        this.doctorNameOrder = 'DESC';
      }
    } else if (data.active === 'ADMISSIONDATE') {
      this.doctorNameOrder = '';
      this.ipnumOrder = '';
      this.sponserNameOrder = ''
      if (data.direction === 'asc') {
        this.admissionDateOrder = 'ASC';
      } else {
        this.admissionDateOrder = 'DESC';
      }
    }
    else if (data.active === 'IPNUM') {
      this.doctorNameOrder = '';
      this.admissionDateOrder = '';
      this.sponserNameOrder = ''
      if (data.direction === 'asc') {
        this.ipnumOrder = 'ASC';
      } else {
        this.ipnumOrder = 'DESC';
      }
    }
    else if (data.active === 'SPONSOR') {
      this.doctorNameOrder = '';
      this.admissionDateOrder = '';
      this.ipnumOrder = '';
      if (data.direction === 'asc') {
        this.sponserNameOrder = 'ASC';
      } else {
        this.sponserNameOrder = 'DESC';
      }
    }
    this.getPatientsList();
  }
  /** get patients list from B-end */
  getPatientsList(): void {
    const inutReq = {
      start: 0,
      limit: 10,
      ser_hospitalid: this.userInfo.hospitalID,
      sear_umr_no: this.advSearchForm.controls.UMRID.value,
      sear_ip_no: this.advSearchForm.controls.IPNO.value,
      sear_patient: this.advSearchForm.controls.PATIENTNAME.value,
      sear_admission_date: this.advSearchForm.controls.ADMISSIONDATE.value,
      sear_consult_doctor: this.advSearchForm.controls.CONSULTDOCTOR.value,
      sear_ptmid: this.advSearchForm.controls.SPONSER.value,
      admission_date_order: this.admissionDateOrder,
      doctor_name_order: this.doctorNameOrder,
      ipno_order: this.ipnumOrder,
      sponser_order: this.sponserNameOrder
    };

    if (this.enableDischargeList) {
      this.serviceUrl = this.inpatientService.DischargepatientsList(inutReq);
    } else {
      this.serviceUrl = this.inpatientService.patientsList(inutReq);
    }

    this.serviceUrl.subscribe(
      (response) => {
        this.loading = false;

        if (this.enableDischargeList) {
          this.patientsList = response;
          this.totalPatientsList = '';
          this.selfPayList = 0;
          this.insuranceList = 0;
          this.corporateList = 0;
        } else {
          this.patientsList = response.data;
          // this.patientsList.forEach((ele) => {
          //   const billAmount = (ele.BILL_AMOUNT === '--') ? 0 : ele.BILL_AMOUNT;
          //   const advancedPaid = (ele.TOTAL_ADVANCE_PAID === '--') ? 0 : ele.TOTAL_ADVANCE_PAID;
          //   ele.PAYABLE = billAmount - advancedPaid;
          // });

          this.totalPatientsList = response.totalRecords;
          this.selfPayList = response.selfPayCount;
          this.insuranceList = response.insurenceCount;
          //  this.corporateList = response.corp;
        }

        this.patientsData = new MatTableDataSource(this.patientsList);
        this.patientsData.sort = this.sort;
        this.patientsData.paginator = this.paginator;
      },
      (err) => {
        this.patientsData = new MatTableDataSource([]);
      });
  }

  getNextData(currentSize, offset, limit22): void {
    const inutReq = {
      start: offset * limit22,
      limit: limit22,
      ser_hospitalid: this.userInfo.hospitalID,
      sear_umr_no: this.advSearchForm.controls.UMRID.value,
      sear_ip_no: this.advSearchForm.controls.IPNO.value,
      sear_patient: this.advSearchForm.controls.PATIENTNAME.value,
      sear_admission_date: this.advSearchForm.controls.ADMISSIONDATE.value,
    };

    this.inpatientService.patientsList(inutReq).subscribe((response) => {
      this.loading = false;

      this.patientsList.length = currentSize;
      this.patientsList.push(...response.data);

      this.patientsList.length = response.totalRecords;

      this.patientsData = new MatTableDataSource<any>(this.patientsList);
      // this.patientsData._updateChangeSubscription();

      this.patientsData.paginator = this.paginator;
    });
  }

  pageChanged(event): void {
    this.loading = true;

    const pageIndex = event.pageIndex;
    const pageSize = event.pageSize;

    const previousIndex = event.previousPageIndex;

    const previousSize = pageSize * pageIndex;

    this.getNextData(previousSize, pageIndex.toString(), pageSize.toString());
  }

  actionRouting(value: any, patient: any): void {
    const val = value;
    this.selectedIPPatientNO = patient.IP_NR;
    this.seletedPatientURMNo = patient?.P_ID;

    if (val === 'AddReceipts') {
      this.openAddReceiptPopup(this.paymentReceiptPopup);
    } else if (val === 'PreAuth') {
      this.openPreAuthPopup(this.preAuthPopup);
    } else if (val === 'creditBill') {
      this.openCreditBillPopup(this.creditBillSubmissionPopup);
    } else if (val === 'coverLetter') {
     // this.openCoverLetterPopup(this.coverLetterPopup);
    } else if (val === 'admissionFormPrint') {
      this.admissionFormPrintFunc();
    } else if (val === 'dischargeFormPrint') {
      this.dischargeFormPrintFunc();
    } else if (val === 'BillingInsurance') {
      if(this.enableDischargeList === true)
      {
        const queryParam = { mode: 'dischargelist' };
        this.router.navigate(['/' + val + '/' + patient.IP_NR], {queryParams: queryParam});
      }
      else 
      {
        this.router.navigate(['/' + val + '/' + patient.IP_NR]);
      }
      
    } else if (val === 'admissionInformation') {
      this.selectedPatient = patient;
      this.openAdmissionInfoPopup();
    } else {
      if (val !== '') {
        const returnToURL = this.enableDischargeList
          ? 'dischargelist'
          : 'iplist';
        const queryParam = { umrNumber: patient?.P_ID, mode: returnToURL };
        this.router.navigate(['/' + val + '/' + patient.IP_NR], {
          queryParams: queryParam,
        });
      }
    }
  }

  public openAdmissionInfoPopup(): void {
    this.modalService.open(this.ipAdmissionInformationPopup, { size: 'lg' });
  }

  public patientAdvancedSearch(data): any {
    console.log(data);
  }

  /** pre-auth form popup */
  openPreAuthPopup(preAuthPopup): void {
    this.modalService.open(preAuthPopup, { size: 'xl', backdrop: 'static' });
  }

  openCreditBillPopup(creditBillSubmissionPopup): void {
    this.modalService.open(creditBillSubmissionPopup, { size: 'xl' });
  }

  openAddReceiptPopup(paymentReceiptPopup): void {
    this.modalService.open(paymentReceiptPopup, {
      size: 'xl',
      backdrop: 'static',
    });
  }

  closeModalPopup(event): void {
    this.modalService.dismissAll();
    this.getPatientsList();
  }

  admissionFormPrintFunc(): void {
    const dialogRef = this.dialog.open(PreviewComponent, {
      width: '80%',
      height: '95%',
      data: {
        mode: 'admissionform',
        ipno: this.selectedIPPatientNO,
        umrno: this.seletedPatientURMNo,
      },
    });

    // const inputRequest = {
    //   sear_hospitalid: this.userInfo.hospitalID,
    //   sear_ip_no: this.selectedIPPatientNO,
    //   sear_umr_no : this.seletedPatientURMNo,
    //   admissionPrintStatus: 'Y',
    //   chckoutPrintStatus: 'N'
    // };
    // this.inpatientService.admissionFormPrint(inputRequest).subscribe((response) => {

    //   if (response !== null) {
    //     this.admissionFormPrintData = response;
    //   }

    //   this.printPrescription = true;

    //   setTimeout(() => {
    //     this.printerService.printDiv('print-prescription-section');
    //   }, 2000);

    //   setTimeout(() => {
    //     this.printPrescription = false;
    //   }, 3000);

    // });
  }

  dischargeFormPrintFunc(): void {
    const dialogRef = this.dialog.open(PreviewComponent, {
      width: '80%',
      height: '95%',
      data: {
        mode: 'dischargeform',
        ipno: this.selectedIPPatientNO,
        umrno: this.seletedPatientURMNo,
      },
    });

    // const inputRequest = {
    //   sear_hospitalid: this.userInfo.hospitalID,
    //   sear_ip_no: this.selectedIPPatientNO,
    //   sear_umr_no : this.seletedPatientURMNo,
    //   admissionPrintStatus: 'N',
    //   chckoutPrintStatus: 'Y'
    // };
    // this.inpatientService.admissionFormPrint(inputRequest).subscribe((response) => {

    //   if (response !== null) {
    //     this.dischargeFormPrintData = response;
    //   }

    //   this.printPrescription = true;

    //   setTimeout(() => {
    //     this.printerService.printDiv('print-prescription-section');
    //   }, 2000);

    //   setTimeout(() => {
    //     this.printPrescription = false;
    //   }, 3000);

    // });
  }

  backNav(): void {
    this.router.navigate(['/ipAdmission']);
  }

  patientQuickInfo(patientQuickInfoPopup, patient): void {
    this.patientQuickInformation = patient;
    this.modalService.open(patientQuickInfoPopup, { size: 'xl' });
  }

  admissionInfoPopClose(): void {
    this.modalService.dismissAll();
  }

  printDischargeSummary(dischargeSummaryPrintPopup, patientData): void {
    console.log("patient data", patientData)
    const data = {
      UMRNo: patientData.P_ID,
      IpNr: patientData.IP_NR,
      PatientName: patientData.PATIENT_NAME,
      GenderAge: patientData.AGE,
      ConsultDoctor: patientData.CONSULT_DOCTOR,
      address: patientData.address,
      refDoctorName: patientData.externalRefDoctor,
      admissionDate: patientData.IP_ADMISSION_DATE,
      dischargeDate: patientData.IP_DISCHARGE_DATE,
      headingTitle: patientData.HEADING_TITLE,
      dischargeType: patientData.DISCHARGE_TYPE,
      title: patientData.TITLE
    };
    this.dischargeSummaryPatientInfo = data;
    this.getDischargeSummary(patientData.IP_NR, dischargeSummaryPrintPopup);
  }

  getDischargeSummary(ipNr, dischargeSummaryPrintPopup): void {
    const inputRequest = {
      ipNr,
    };
    this.nursestationsService.getDischargeSummaryInfo(inputRequest).subscribe(
      (dischargeSummaryInfo: any) => {
        // console.log("dischargeSummaryInfo", dischargeSummaryInfo);
        this.dischargeSummaryPatientInfo.dischargeType = dischargeSummaryInfo.DISCHARGE_TYPE
        this.dischargeSummaryPatientInfo.headingTitle = dischargeSummaryInfo.HEADING_TITLE
        this.dischargeSummaryPatientInfo.address = dischargeSummaryInfo.ADDRESS
        this.dischargeSummaryPatientInfo.refDoctorName = dischargeSummaryInfo.DOCTOR_NAME
        this.dischargeSummaryPatientInfo.ConsultDoctor = dischargeSummaryInfo.CONSULTANT_DOC_NAME
        this.existingDischargeSummaryInfo = dischargeSummaryInfo
        this.modalService.open(dischargeSummaryPrintPopup, { size: 'lg' })
        this.existingRecord = true
      },
      (err) => {
        console.log('HTTP Error', err.error);
        // this.toastrService.error(err.error.message);
        this.existingRecord = false
        this.modalService.open(dischargeSummaryPrintPopup, { size: 'lg' })
      }
    );
  }

  actionIcdPop(icdPopup, patientData)
  {
    this.icdPatientDetails = patientData
    this.modalService.open(icdPopup, { size: 'xl' })
  }

  popupClose()
  {
    this.modalService.dismissAll()
  }
  
  selfpayPatientsList(){
    //this.getPatientsList();
    this.patientslistbtn = true;
    this.printPatientsList();
  }

  printPatientsList(): void {
   
    const sheet = document.createElement('style');
    sheet.innerHTML = '@page {size: landscape;}';
    document.getElementById('patients-list').appendChild(sheet);
    
    const htmlele = document.getElementById('patients-list');
    htmlele.style.display = 'block';
    this.printerService.printDiv('patients-list');
   
    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000);
  } 
}
