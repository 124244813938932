
   
          <fieldset class="legend-block mt-3">
         
            <legend class="legend-title mb-0" *ngIf="!editButton">New Patient</legend>
            <legend class="legend-title mb-0" *ngIf="editButton">Patient Information</legend>
          
            <div class="text-center mt-1 clearfix"> 
              <i class="fas fa-edit close handCursor" (click)="editOption = false" *ngIf="editButton" style="color: #173267"
                title="click here to edit form"></i>
            </div>
  
            <form class="form-horizontal" (ngSubmit)=" check.form.valid && onSubmit()" #check="ngForm" autocomplete="off">
              <div class="row">
                <div class="col-md-5 row form-group">
                  <label for="title" class="control-label col-sm-4">Title
                    <span class="requiredSymbol">*</span></label>
                  <div class="col-sm-8">
                    <select class="form-control-sm form-control border border-primary"
                      (change)="changingGenderFields()" name="title" [(ngModel)]="model.title" #title="ngModel"
                      [ngClass]="{ 'is-invalid-error': check.submitted && title.invalid }" id="title"
                      [attr.disabled]="(editOption) ? '' : null" required>
                      <option [ngValue]="''">-- Select Title --</option>
                      <option value="Baby" selected title="0 to 1 year boy and girl">Baby</option>
                      <option value="B/O." selected title="0 to 1 year boy and girl">B/O.</option>
                      <option value="Master." title="1 to 12 years Boys">Master. </option>
                      <option value="Miss." title="1 to 12 years Girls">Miss.</option>
                      <option value="Mr." title="Above 13 years Boys">Mr.</option>
                      <option value="Ms." title="Above 13 years Girls and Unmarried">Ms.</option>
                      <option value="Mrs." title="For married women">Mrs.</option>
                    </select>
                    <span *ngIf="check.submitted && title.invalid" class="bg-warningtxt">Title is required</span>
                  </div>
                </div>
                <div class="form-group col-md-7 row">
                  <label for="patname" class="col-sm-4">Name
                    <span class="requiredSymbol">*</span></label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control-sm form-control border border-primary" name="ptname"
                      [(ngModel)]="model.patientName" #ptname="ngModel" [readOnly]="editOption"
                      [ngClass]="{ 'is-invalid-error': check.submitted && ptname.invalid }" pattern="^[a-zA-Z][a-zA-Z\. ]*$"
                      id="patname" required>
                    <span *ngIf="check.submitted && model.patientName === ''" class="bg-warningtxt">Name is required</span>
                    <span *ngIf="ptname.errors?.pattern" class="bg-warningtxt">Invalid format</span>
                  </div>
                </div>
              </div>
  
  
              <div class="row">
                <div class="col-md-5 form-group row mb-0">
                  <label class="col-sm-4">Gender
                    <span class="requiredSymbol">*</span></label>
                  <div class="form-group col-sm-8">
                    <label class="radio-inline" for="radio122">
                      <input type="radio" class="mx-1" id="radio122" value="MALE" name="gender"
                        (change)="changeRadioGender($event)" #gender="ngModel" required="required"
                        [ngModel]="model.gender">Male
                    </label>
                    <label class="radio-inline" for="radio222">
                      <input type="radio" class="mx-1" id="radio222" value="FEMALE" name="gender"
                        (change)="changeRadioGender($event)" #gender="ngModel" required="required"
                        [disabled]="model.gender !== '' && model.title !== 'B/O.' && model.title !== 'Baby'" [ngModel]="model.gender">Female
                    </label>
                    <div *ngIf="check.submitted && gender.invalid" class="bg-warningtxt width100">
                      Gender is required
                    </div>
                  </div>
                </div>
  
                <div class="col-md-7 form-group row">
                  <label for="patientmobNumberf" class="col-sm-4">Mobile No
                    <span class="requiredSymbol">*</span></label>
                  <div class="col-sm-8">
                    <input type="number" class="form-control-sm form-control  border border-primary" #patMobile
                      name="mobile" [(ngModel)]="model.mobileNumber" #mobile="ngModel"
                      [readOnly]="editOption && model.mobileNumber"
                      [ngClass]="{ 'is-invalid-error': check.submitted && mobile.invalid }"
                      pattern="^((\\+91-?)|0)?[0-9]{10}$"
                      (focusout)="existingPatientsCheck(patMobile, 'num', existingPatientsModal)" id="patientmobNumberf"
                      (keydown)="checkingMobileNumber($event)" required>
                    <span *ngIf="check.submitted && model.mobileNumber === ''" class="bg-warningtxt">Mobile Number is
                      required.</span>
                    <span *ngIf="mobile.errors?.pattern" class="bg-warningtxt">Invalid Mobile number</span>
                  </div>
                </div>
              </div>
  
  
              <div class="row my-0 py-0">
                <div class="col-md-5 form-group row py-0">
                  <label for="dob1" class="col-sm-4 py-0 my-0">D.O.B
                    <span class="requiredSymbol">*</span></label>
                  <div class="col-sm-8 my-0 py-0">
                    <input type="date" class="form-control-sm form-control border border-primary"
                      placeholder="dd/mm/yyyy" (click)="checkInputDate($event)" (keyup)="checkInputDate($event)"
                      [max]="maxDate" [min]="minDate" name="dateofbirth" #dob #dateofbirth="ngModel"
                      [(ngModel)]="model.dateOfBirth " (input)="gettingAge(model.dateOfBirth)"
                      [ngClass]="{'is-invalid--error':check.submitted && dateofbirth.invalid}" id="dob1"
                      [readOnly]="editOption" required>
                    <span *ngIf="check.submitted && dateofbirth.invalid" class="bg-warningtxt">D.O.B is required</span>
                    <span *ngIf="yearError || monthError || dateError" class="bg-warningtxt">Select correct Title or Date/
                      Age</span>
                  </div>
                </div>
  
                <div class="col-md-7 form-group row py-0">
                  <label for="patientageff" class="col-sm-4">Age
                    <span class="requiredSymbol">*</span></label>
                  <div id="patientageff" class="row ml-1 col-sm-8">
                    <div class="form-group col-4 px-1">
                      <div class="input-group-sm input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Y</span>
                        </div>
                        <input type="number" class="form-control-sm form-control mx-1 borderleftnone"
                          [readOnly]="editOption" (keyup)="checkingByYear($event)" (input)="assigningYears($event)"
                          placeholder="Years" [min]="minYear" [max]="maxYear" width="2" size="4" id="forYears"
                          [value]="patientDobYear">
  
                      </div>
  
                    </div>
                    <div class="form-group col-4 px-1">
                      <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                          <span class="input-group-text">M</span>
                        </div>
                        <input type="number" class="form-control-sm form-control mx-1 borderleftnone"
                          [readOnly]="editOption" (keyup)="checkingByMonth($event)" (input)="assigningMonths($event)"
                          placeholder="Months" [min]="minMonth" [max]="maxMonth" width="2" size="4" id="forMonths"
                          [value]="patientDobMonth">
                      </div>
                    </div>
                    <div class="form-group col-4 px-1">
                      <div class="input-group input-group-sm">
                        <div class="input-group-append ">
                          <span class="input-group-text borderrightnone">D</span>
                        </div>
                        <input type="number" class="form-control-sm form-control mx-1 borderleftnone"
                          [readOnly]="editOption" (keyup)="checkingByDay($event)" (input)="assigningDate($event)"
                          placeholder="Days" [min]="minDay" [max]="maxDay" width="2" size="4" id="forDays"
                          [value]="patientDobDate">
                      </div>
                    </div>
                    <span *ngIf="check.submitted &&dateofbirth.invalid" class="bg-warningtxt">Age is required</span>
                  </div>
  
                </div>
              </div>
  
              <div class="row mt-0">
                <div class="form-group col-md-5 row">
                  <label for="patientmobEmailrff" class="text-dark col-sm-4">Email
                    <!--                <span class="requiredSymbol">*</span>-->
                  </label>
                  <div class="col-sm-8">
                    <input type="email" class="form-control-sm form-control  border border-primary" #patemail
                      name="email" [(ngModel)]="model.emailId" #email="ngModel"
                      (focusout)="existingPatientsCheck(patemail, 'email', existingPatientsModal)"
                      [ngClass]="{ 'is-invalid-error': check.submitted && email.invalid }" [readOnly]="editOption"
                      pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" id="patientmobEmailrff">
                    <!--                <span *ngIf="check.submitted && model.emailId === ''" class="bg-warningtxt">Email is required.</span>-->
                    <span *ngIf="email.errors?.pattern" class="bg-warningtxt">Invalid Email format</span>
                  </div>
                </div>
                <div class="col-md-7 form-group row">
                  <label for="add" class="col-sm-4">Address
                    <span class="requiredSymbol">*</span></label>
                  <div class="col-sm-8">
                    <textarea type="text" class="form-control-sm form-control border border-primary" rows="1"
                      cols="55" name="address" [(ngModel)]="model.address" #address="ngModel" [readOnly]="editOption"
                      [ngClass]="{ 'is-invalid-error': check.submitted && address.invalid }" id="add" required>
                    </textarea>
                    <span *ngIf="check.submitted &&address.invalid" class="bg-warningtxt">Address is required</span>
                  </div>
                </div>
              </div>
  
  
              <div class="row">
                <div class="form-group col-md-5 row">
                  <label for="city1" class="col-sm-4">City
                    <span class="requiredSymbol">*</span></label>
                    <div class="col-sm-8">
                      <div *ngIf="editOption">
                        <ng-autocomplete
                          [data]="citiesArray"
                          [searchKeyword]="citiesKeyword"
                          placeholder="Enter the city's Name"
                          (selected)='selectAutocompleteEvent($event, "cities")'
                          (inputCleared)='onRemoveSelected($event,"cities")'
                          (inputChanged)='onChangeSearch($event)'
                          (inputFocused)='onFocused($event)'
                          [itemTemplate]="itemTemplate"
                          [notFoundTemplate]="notFoundTemplate"
                          class="autocomplete-dropdown sm"
                          [(ngModel)]="model.city" #city="ngModel"
                          name="city"
                      [ngClass]="{ 'is-invalid-error': check.submitted && city.invalid }"
                         >                           
                        </ng-autocomplete>
                        <ng-template #itemTemplate let-item> 
                          <a [innerHTML]="item.cityName"></a>
                        </ng-template>
                        <ng-template #notFoundTemplate let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                      </div>
                    
                      <div *ngIf="!editOption"> 
                        <ng-autocomplete
                          [data]="citiesArray"
                          [searchKeyword]="citiesKeyword"
                          placeholder="Enter the city's Name"
                          (selected)='selectAutocompleteEvent($event, "cities")'
                          (inputCleared)='onRemoveSelected($event,"cities")'
                          (inputChanged)='onChangeSearch($event)'
                          (inputFocused)='onFocused($event)'
                          [itemTemplate]="itemTemplate"
                          [notFoundTemplate]="notFoundTemplate"
                          class="autocomplete-dropdown sm"
                         >                           
                        </ng-autocomplete>
                        <ng-template #itemTemplate let-item> 
                          <a [innerHTML]="item.cityName"></a>
                        </ng-template>
                        <ng-template #notFoundTemplate let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                      </div>
                      <span *ngIf="check.submitted && !itemCity" class="bg-warningtxt">City is required</span>
                    </div>
                </div>
                <div class="col-md-7 form-group row">
                  <label for="ste" class="col-sm-4">State
                    <span class="requiredSymbol">*</span>
                  </label>
                  <div class="col-sm-8 ">
                    <input type="text" class="form-control-sm form-control border border-primary" name="state123"
                      [(ngModel)]="model.stateCountry" #state123="ngModel" [readOnly]="editOption"
                      [ngClass]="{ 'is-invalid-error': check.submitted && state123.invalid }" id="ste" required>
                    <span *ngIf="check.submitted &&state123.invalid" class="bg-warningtxt">State is required</span>
                  </div>
                </div>
              </div>
  
  
              <div class="row">
                <div class="form-group row col-md-5">
                  <label for="cont" class="col-sm-4">Country
                    <span class="requiredSymbol">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control-sm form-control border border-primary" name="countryyy"
                      [(ngModel)]="model.country" #countryyy="ngModel" [readOnly]="editOption"
                      [ngClass]="{ 'is-invalid-error': check.submitted && countryyy.invalid }" id="cont" required>
                    <span *ngIf="check.submitted &&countryyy.invalid" class="bg-warningtxt">Country is required</span>
                  </div>
                </div>
                <div class="col-md-7 form-group row">
                  <label for="pin" class="col-sm-4">Pincode
                    <span class="requiredSymbol">*</span>
                  </label>
                  <div class=" col-sm-8">
                    <input type="text" class="form-control-sm form-control border border-primary" name="pincode"
                      [(ngModel)]="model.pincode" #pincode="ngModel" [readOnly]="editOption"
                      [ngClass]="{ 'is-invalid-error': check.submitted && pincode.invalid }" pattern="^(\d{4}|^\d{6})$"
                      id="pin" required>
                    <span *ngIf="check.submitted && model.pincode === ''" class="bg-warningtxt">Pincode is required</span>
                    <span *ngIf="pincode.errors?.pattern" class="bg-warningtxt">Invalid pin code</span>
                  </div>
                </div>
              </div>
  
              <div class="row">
  
                <div class="col-md-5 form-group row">
                  <label for="IDTYPE" class="col-sm-4">Identity Type
                    <!--                <span class="requiredSymbol">*</span>-->
                  </label>
                  <div class="col-sm-8">
                    <select class="form-control-sm form-control border border-primary" name="IDTYPE"
                      [(ngModel)]="model.identityType" #IDTYPE="ngModel" (change)="assigningPatterns($event)"
                      [attr.disabled]="(editOption) ? '' : null"
                      [ngClass]="{ 'is-invalid-error': check.submitted && IDTYPE.invalid }" id="IDTYPE">
                      <option value="">Select Identity Type</option>
                      <option value="Aadhar">Aadhar</option>
                      <option value="Driving license">Driving license</option>
                      <option value="Voter ID">Voter ID</option>
                      <option value="Pan Card">Pan card</option>
                      <option value="Passport">Passport</option>
                      <option value="Employee Id">Employee ID </option>
                    </select>
                    <span *ngIf="check.submitted && IDTYPE.invalid" class="bg-warningtxt">Identity Type is required</span>
                  </div>
                </div>
                <div class="col-md-7 form-group row">
                  <label class="col-sm-4">Identity No
                    <!--                <span class="requiredSymbol">*</span>-->
                  </label>
                  <div class="col-sm-8">
                    <input [ngClass]="{ 'is-invalid-error':(check.submitted && idNUmber.invalid) && check.submitted}"
                      type="text" name="idNUmber" class="form-control-sm form-control border border-primary"
                      [pattern]="idPattern" #idNUmber="ngModel" [(ngModel)]="model.identityIdNr" [readOnly]="editOption"
                      [required]="model.identityType !== ''">
                    <span class="bg-warningtxt"
                      *ngIf="model.identityType !== '' && check.submitted && model.identityIdNr === ''">Identity Number is
                      required</span>
                    <span class="bg-warningtxt" *ngIf="model.identityType ==='Aadhar' && idNUmber.errors?.pattern">Invalid
                      Aadhar Number</span>
                    <span class="bg-warningtxt"
                      *ngIf="model.identityType ==='Driving license' && idNUmber.errors?.pattern">Invalid Driving
                      license</span>
                    <span class="bg-warningtxt" *ngIf="model.identityType ==='Voter ID' && idNUmber.errors?.pattern">Invalid
                      Voter ID</span>
                    <span class="bg-warningtxt" *ngIf="model.identityType ==='Pan Card' && idNUmber.errors?.pattern">Invalid
                      Pan Card</span>
                    <span class="bg-warningtxt" *ngIf="model.identityType ==='Passport' && idNUmber.errors?.pattern">Invalid
                      Passport</span>
                    <!--                                    <span *ngIf="check.submitted && model.identityIdNr === ''" class="bg-warningtxt">Identity Number is required.</span>-->
                    <!--                    <span *ngIf="mobile.errors?.pattern" class="bg-warningtxt">Identity Number number</span>-->
                  </div>
                </div>
              </div>
              <hr class="d-none d-md-block">
  
              <div class="d-sm-flex px-2 justify-content-between">
                <div class="mx-2 form-group d-sm-flex">
                  <label for="kin1" class="mr-sm-2">Next of kin1</label>
                  <div>
                    <div class="ml-sm-2">
                      <input type="text" class="form-control-sm form-control border border-primary"
                        name="Nextofkin1" [(ngModel)]="model.nextOfKinName" #Nextofkin1="ngModel" [readOnly]="editOption"
                        [ngClass]="{ 'is-invalid-error': check.submitted && Nextofkin1.invalid }"
                        pattern="^[a-zA-Z][a-zA-Z\. ]*$" id="kin1" [required]="model.nextOfKinRelation !== ''">
                      <span *ngIf="check.submitted && model.nextOfKinName === '' && model.nextOfKinRelation !== ''"
                        class="bg-warningtxt">Next of kin1 is required</span>
                      <span *ngIf="Nextofkin1.errors?.pattern" class="bg-warningtxt">Invalid format</span>
                    </div>
                  </div>
                </div>
                <div class=" mx-2 form-group d-sm-flex">
                  <label for="Rkin1" class="mr-sm-2">Relation</label>
                  <div class="ml-sm-2">
                    <select class="form-control-sm form-control  border border-primary " name="Nextofkin1relation"
                      [(ngModel)]="model.nextOfKinRelation" #Nextofkin1relation="ngModel"
                      [attr.disabled]="(editOption) ? '' : null"
                      [ngClass]="{ 'is-invalid-error': check.submitted && Nextofkin1relation.invalid }" id="Rkin1"
                      [required]="model.nextOfKinName !== ''">
                      <option [ngValue]="''">Select</option>
                      <option>Father</option>
                      <option>Mother</option>
                      <option>Brother</option>
                      <option>Sister</option>
                      <option>Wife</option>
                      <option>Husband</option>
                      <option>Son</option>
                      <option>Daughter </option>
                      <option>Son-in-law</option>
                      <option>Daughter-in-law</option>
                      <option>In-laws</option>
                      <option>Guardian </option>
                      <option>Friends </option>
                    </select>
                    <span *ngIf="check.submitted && Nextofkin1relation.invalid" class="bg-warningtxt">Relation is
                      required</span>
                  </div>
                </div>
                <div class="mx-2 form-group d-sm-flex">
                  <label for="kin1mobNumber" class="mr-sm-2">Mobile No</label>
                  <div class="ml-sm-2">
                    <input type="number" class="form-control-sm form-control border border-primary"
                      name="kinmobNumber122" [(ngModel)]="model.nextOfKinMobilenr" #kinmobNumber122="ngModel"
                      [readOnly]="editOption" [ngClass]="{ 'is-invalid-error': check.submitted && kinmobNumber122.invalid }"
                      pattern="^((\\+91-?)|0)?[0-9]{10}$" id="kin1mobNumber" (keydown)="checkingMobileNumber($event)"
                      [required]="model.nextOfKinName !== '' || model.nextOfKinRelation !== ''">
                    <span *ngIf="check.submitted && kinmobNumber122.errors?.required" class="bg-warningtxt">Mobile Number is
                      required.</span>
                    <span *ngIf="kinmobNumber122.errors?.pattern" class="bg-warningtxt">Invalid Mobile Number</span>
                  </div>
                </div>
              </div>
  
  
              <div class="d-sm-flex px-2 justify-content-between">
                <div class="mx-2 form-group d-sm-flex">
                  <label for="kin2" class="mr-sm-2">Next of kin2</label>
                  <div class="ml-sm-2">
                    <input type="text" class="form-control-sm form-control border border-primary"
                      name="Nextofkin111" [ngClass]="{ 'is-invalid-error': check.submitted && Nextofkin111.invalid }"
                      pattern="^[a-zA-Z][a-zA-Z\. ]*$" id="kin2" [(ngModel)]="model.nextOfKin1Name" [readOnly]="editOption"
                      #Nextofkin111="ngModel"
                      [required]="model.nextOfKin1Mobilenr !== null || model.nextOfKin1Relation !== ''">
                    <span
                      *ngIf="check.submitted && (model.nextOfKin1Mobilenr !== null || model.nextOfKin1Relation !== '') && model.nextOfKin1Name === ''"
                      class="bg-warningtxt">Next of kin2 is required</span>
                    <span *ngIf="Nextofkin111.errors?.pattern" class="bg-warningtxt">Invalid format</span>
                  </div>
                </div>
                <div class="mx-2 form-group d-sm-flex">
                  <label for="Rkin2" class="mr-sm-2">Relation</label>
                  <div class="ml-sm-2">
                    <select class="form-control-sm form-control border border-primary"
                      name="Nextofkin2relation1123" [attr.disabled]="(editOption) ? '' : null"
                      [(ngModel)]="model.nextOfKin1Relation" #Nextofkin2relation1123="ngModel"
                      [attr.disabled]="(editOption) ? '' : null" id="Rkin2"
                      [ngClass]="{ 'is-invalid-error': check.submitted && Nextofkin2relation1123.invalid }"
                      [required]=" model.nextOfKin1Mobilenr !== null || model.nextOfKin1Name !== ''">
                      <option value="">Select</option>
                      <option>Father</option>
                      <option>Mother</option>
                      <option>Brother</option>
                      <option>Sister</option>
                      <option>Wife</option>
                      <option>Husband</option>
                      <option>Son</option>
                      <option>Daughter </option>
                      <option>Son-in-law</option>
                      <option>Daughter-in-law</option>
                      <option>In-laws</option>
                      <option>Guardian </option>
                      <option>Friends </option>
                    </select>
                    <span *ngIf="check.submitted && Nextofkin2relation1123.invalid" class="bg-warningtxt">Relation is
                      required</span>
                  </div>
                </div>
                <div class="mx-2 form-group d-sm-flex">
                  <label for="kin2mobNumber" class="mr-sm-2">Mobile No</label>
                  <div class="ml-sm-2">
                    <input type="number" class="form-control-sm form-control  border border-primary"
                      [(ngModel)]="model.nextOfKin1Mobilenr" name="kinmobNumberr" id="kin2mobNumber"
                      (keydown)="checkingMobileNumber($event)" [readOnly]="editOption" #kinmobNumberr="ngModel"
                      pattern="^((\\+91-?)|0)?[0-9]{10}$"
                      [required]="model.nextOfKin1Name !== '' || model.nextOfKin1Relation !== ''"
                      [ngClass]="{ 'is-invalid-error': check.submitted && kinmobNumberr.invalid }"
                      (keyup)="keyUpEnterFunction($event, 1)">
                    <span
                      *ngIf="(model.nextOfKin1Name !== '' || model.nextOfKin1Relation !== '') && model.nextOfKin1Mobilenr === null"
                      class="bg-warningtxt">Mobile Number is required.</span>
                    <span *ngIf="kinmobNumberr.errors?.pattern" class="bg-warningtxt">Invalid mobile number</span>
                  </div>
                </div>
              </div>
  
  
              <div class="row">
                <div class="col-md-5 form-group row">
                  <label for="existing" class="col-sm-4">Existing UMR No
                  </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control-sm form-control border border-primary"
                      name="existingnumber" [(ngModel)]="model.patientID" #existingnumber="ngModel" #umrCheck id="existing"
                      [readOnly]="editOption" (keyup)="checkingExistingUMR(umrCheck)">
                    <span *ngIf="existingUmr" class="bg-warningtxt">UMR Number already exists </span>
                  </div>
                </div>
                <div class="col-md-7 mt-md-2"> 
                  <div class="form-group twoInputssameLine">
                    <div class="float-md-right text-center">
                      <button *ngIf="!editButton" (click)="setValuestoEmpty()" type="button"
                        class="btn mx-3 resetSub mb-2 text-white"
                        style="background-color: #173267; width: 120px">Reset</button>
                      <button type="submit" class="btn mx-md-3 resetSub mb-2  text-white"
                        style="background-color: #173267; width: 120px">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <br><br>
          </fieldset>
      
      <ng-template #existingPatientsModal let-modal>
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content p-0">
            <div class="modal-header d-flex justify-content-center m-0">
              <h4 class="modal-title font-weight-bold text-info">Existing Patient Records by Mail/ Mobile Number</h4>
            </div>
            <div class="modal-body py-0 px-1 text-center">
              <table class="table my-0 table-hover table-striped">
                <thead>
                  <tr class="text-center">
                    <th>Name</th>
                    <th>Gender</th>
                    <th>D.O.B</th>
                    <th>UMR No</th>
                    <th>Mobile No</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="handCursor text-center" *ngFor="let patient of existingPatients, let  i = index"
                    (click)="formFillPatientFun(dataFillModal, patient, i)">
                    <td>{{patient.title}} {{patient.patientName}}</td>
                    <td>{{patient.gender}}</td>
                    <td>{{patient.dateOfBirth.split('-').reverse().join('-')}}</td>
                    <td>{{patient.patientID}}</td>
                    <td>{{patient.mobileNumber}}</td>
                    <td>{{patient.emailId}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button class="btn btn-danger btn-sm" (click)="modal.dismiss('click')">Close</button>
            </div>
          </div>
        </div>
      </ng-template>