import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  public bodyDisplay = 'reports';
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  billingReportsPopup(billingReportsPopupBlock, billType: string): void {
    this.modalService.open(billingReportsPopupBlock);
  }
  
}
