<!-- <div class="land">
  <div class="container-fluid ">
    <div class="row justify-content-md-center">
      <div class="m-3">
        <button type="button" routerLink="/OutPatient" class="btn btn-sm btnn btn-light">View Outpatients</button>
      </div>
      <div class="m-3">
        <button type="button" routerLink="/Inpatient" class="btn btn-sm btnn btn-light">View Inpatients</button>
      </div>
      <div class="m-3">
        <button type="button" class="btn btn-sm btnn btn-light">Search/View OP Bills</button>
      </div>
      <div class="m-3">
        <button type="button" routerLink="/reports" class="btn btn-sm btnn btn-light">Reports</button>
      </div>
      <div class="m-3">
        <button type="button" routerLink="/Enquiry" class="btn btn-sm btnn btn-light">Enquiry</button>
      </div>
      <div class="m-3">
        <button type="button" routerLink="/registration" class="btn btn-sm btnn btn-light" >Registration</button>
      </div>
    </div>
  </div>
</div> -->
<div *ngIf="bodyDisplay === 'reports'">


<div class="row mt-4 mx-4">
  <div class="col-2">
    <a href="javascript:void(0)" (click)="billingReportsPopup(billingReportsPopupBlock, 'opbilling')">View OP billing details</a>
  </div>
  <div class="col-2">
    <a href="javascript:void(0)">View IP billing details</a>
  </div>
</div>

<div class="m-5">
  <h5>IP COLLECTION SUMMARY REPORT</h5>
  <table class="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col">USER NAME</th>
        <th scope="col">PARTICULARS</th>
        <th scope="col">CASH AMOUNT</th>
        <th scope="col">CARD AMOUNT</th>
        <th scope="col">WALLETS AMOUNT</th>
        <th scope="col">CREDIT VOUCHER AMOUNT</th>
        <th>TOTAL AMOUNT</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">1</th>
        <td>Mark</td>
        <td>Otto</td>
        <td>@mdo</td>
        <td>Mark</td>
        <td>Otto</td>
        <td>@mdo</td>
      </tr>
    </tbody>
  </table>
</div>
</div>
<!-- billing reports popup -->
<ng-template #billingReportsPopupBlock let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Input Controls</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="startDate">Start Date</label>
        <input type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label for="endDate">End Date</label>
        <input type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label for="startTime">Start Time</label>
        <input type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label for="endTime">End Time</label>
        <input type="text" class="form-control" />
      </div>
      <div class="text-right">
        <button type="button" class="btn btn-primary">Submit</button>
      </div>      
    </form>
  </div>
</ng-template>