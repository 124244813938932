<app-ward-header></app-ward-header>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-3 p-0">
      <div class="sidebox" style="overflow-y: auto; overflow-x: hidden;">
        <div class="row justify-content-center">
          <div class="btn-group">
            <button class="btn drop-style dropdown-toggle handCursor" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Options
            </button>
            <div class="dropdown-menu p-2 handCursor" aria-labelledby="dropdownMenuButton">
              <label class=" mb-0 handCursor" (click)="toggle7('selectform1')">search by ip no / umr no</label>
              <a class=" handCursor" (click)="toggle7('selectform2')">accept / acknowledge patient transfer</a>
              <a class="handCursor">Nursing case sheet handover</a>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="floorsList.length !== 0">
          <div class="col-md-12 hfloors" *ngFor="let floor of floorsList">
            <button class="ward-btn" (click)="showWardsList(floor?.FloorId)" [ngClass]="status ? 'success' : 'danger'">
              {{floor?.FloorName}}
            </button>
            <div class="row floorwards" *ngIf="floorType == floor?.FloorId && wardsList.length !==0">
              <div class="col-md-6" *ngFor="let ward of wardsList">
                <div class="ward-card">
                  <img class="beds-icon handCursor" (click)="showWardRoomsList(ward?.FloorId,ward?.WardId)"
                    data-toggle="tooltip" data-placement="bottom" title="{{ward?.WardName}}"
                    src="assets/wardicons/semiprivateward.svg">
                  <h6 class="modename text-center">{{ward?.WardName}}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-3 col-9" *ngIf="!wardType && !patientData" [hidden]="labreportsbtn == true">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="w-25">Ward Name</th>
            <th class="w-25">Available Beds</th>
            <th class="w-25">Occupied Beds</th>
            <th class="w-25">Total Beds</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let beds of bedCountInfo, let i = index">
            <td colspan="4" class="p-0">
              <div class="p-2 font-weight-bold">{{beds.FloorName}}</div>
              <table class="w-100">
                <tr *ngFor="let count of beds.wards">
                  <td class="w-25 border-left-0">{{count.WardName}}</td>
                  <td class="w-25">{{count.AvailableBeds}}</td>
                  <td class="w-25">{{count.occupiedBeds}}</td>
                  <td class="w-25 border-right-0">{{count.TotalBeds}}</td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--3rd floor-->
    <ng-container *ngIf="wardType && roomBedsList.length !== 0" >
      <div class="col-md-9 col-sm-9 col-lg-9" [hidden]="labreportsbtn == true">
        <div class="mx-auto row">
          <div class="col mt-3 clearfix">
            <div class="float-right">
              <div>
                <div class="d-inline-block mr-1 p-2 bed-label-available"></div>
                <label>Available</label>
              </div>
  
              <div>
                <div class="d-inline-block mr-1 p-2 bed-label-notavailable"></div>
                <label>Not Available</label>
              </div>
            </div>
          </div>
          <div class="col-12 " 
               *ngFor="let room of roomBedsList; let i = index"
               [ngClass]="(i !== 0)? 'mt-4':'' ">
            <div class="room-card" *ngIf="room.length > 0">
              <label class="modename col-md-12 text-center">
                <h4 class="mt-2">Room No {{room[0].RoomNo}}</h4>
              </label>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-4 mt-3" *ngFor="let bed of room">
                    <div (click)="showPatientInfo(bed)" 
                          data-toggle="tooltip" 
                          data-placement="bottom"
                          title="{{bed?.PatientName}}"
                          class="pointer"
                          [ngClass]=" (bed?.BedStatus == 'DISABLED') ? '' : 'bed_enable'">
                      <div class="float-left" [ngClass]=" (bed?.BedStatus == 'DISABLED') ? 'bed_disable' : 'bed_enable'">
                        <div class="mr-2 bed-icon"><i class="fa fa-bed fa-3x"></i></div>
                        <h6 class="modename ml-2">{{bed?.BedName}}</h6>
                      </div>
  
                      <div *ngIf="bed.BedStatus === 'DISABLED'">
                        <span>{{bed.PatientName}}</span> <br />
                        <span><b>IP no:</b> {{bed.IpNr}}</span> <br />
                        <span>{{bed.GenderAge}}</span><br/>
                        <span><a href="javascript:void(0)" title="Click To View Lab Results" (click)="getLabReports($event)">view Lab Reports</a></span>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="labreportsbtn == true">
      <div class="row col-9">
        
        <div class="col-12">
          <div class="row mt-1 mb-2">
            <div class="col-md-10">
              <h4 class="text-center blue-title font-weight-bold">View Lab Reports of {{patientName}}</h4>
            </div>
            <div class="col-md-2">
              <div class="text-right">
                <button type="button" 
                        class="btn btn-dark-moon"
                        (click)="toggle1('semiprivate1')">
                        <i class="fas fa-angle-double-left handCursor"></i> Back</button>          
              </div>
            </div>
          </div>
          <!-- <hr class="m-0"/> -->
          
          <div class="col-12">
            <app-view-lab-reports [patientIPNo]="patientIPNo" [patientUMRNo]="patientUMRNo"></app-view-lab-reports>
          </div>
        </div>        
      </div>
    </ng-container>

    <!--3rd floor semiprivate-->
    <ng-container *ngIf="patientData" >
      <div class="col-md-9 col-sm-9 col-lg-9 p-3" [hidden]="labreportsbtn == true">
        <div class="float-right">
          <button type="button" 
                  class="btn btn-dark-moon"
                  (click)="toggle1('semiprivate1')">
                  <i class="fas fa-angle-double-left handCursor"></i> Back</button>
          
        </div>
        <div class="row summary-info">
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-5"><label>Patient Name</label></div>
              <div class="col-sm-7">
                <span>{{ selectedBed?.PatientName }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5"><label>Ward</label></div>
              <div class="col-sm-7">
                <span>{{ selectedBed?.WardName }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5"><label>Consultant Name</label></div>
              <div class="col-sm-7">
                <span>{{ selectedBed?.ConsultDoctor }}</span>
              </div>
            </div>
          </div>

          <div class="col-md-5">
            <div class="row">
              <div class="col-md-5"><label>IP No</label></div>
              <div class="col-sm-7">
                <span>{{ selectedBed?.IpNr }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5"><label>Gender/Age</label></div>
              <div class="col-sm-7">
                <span>{{ selectedBed?.GenderAge }}</span>
              </div>
            </div>

            <div class="row" *ngIf="selectedBed?.BedConfirmBy != ''">
              <div class="col-md-5"><label>Bed Confirmed By</label></div>
              <div class="col-sm-7">
                <span>{{ selectedBed?.BedConfirmBy }}</span>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="row">
              <div class="col-md-5"><label>UMR No</label></div>
              <div class="col-sm-7">
                <span>{{ selectedBed?.UMRNo }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5"><label>Bed No</label></div>
              <div class="col-sm-7">
                <span>{{ selectedBed?.BedName }}</span>
              </div>
            </div>
          </div>
        </div>
        
        <div class="row justify-content-left summary-actions py-4 summary-info">
          <button type="button" 
              (click)="actionRouting(selectedBed.IpNr, selectedBed.UMRNo, 'DateWiseServiceCharged')"
              class="btn border-btn mr-3 px-4">Goto Service Charged</button>
          <button type="button" 
              (click)="actionRouting(selectedBed.IpNr, selectedBed.UMRNo, 'AddInpatientServices')"
              class="btn border-btn mr-3 px-4" 
              *ngIf="selectedBed?.AddPostServiceBtn == 'Y'">Add/Post Service</button>
          <button type="button" class="btn border-btn mr-3 px-4" 
              *ngIf="selectedBed?.BedConfirmationBtn == 'Y'"
              (click)="bedConfirmationPopUp(bedConfirmPopup)">Bed Confirmation</button>
          <button type="button" class="btn border-btn mr-3 px-4" 
              *ngIf="selectedBed?.BedTransferBtn == 'Y'"
              (click)="bedTransferPopUp(bedTransferPopup)">Bed Transfer</button>
          <button type="button"
                  class="btn border-btn mr-3 px-4" 
                  *ngIf="selectedBed.DischargeSummary"
                  (click)="navigation(selectedBed)">Discharge summary</button>
                    
        </div>
        <button type="button" 
                  class="btn border-btn mr-3 px-4"                   
                  (click)="crossConsultantPopUp(crossConsultantPopup,selectedBed)"
                  >Add treating Doctor / Cross Consultant</button>
      </div>
    </ng-container>
  </div>

<!-- Cross Consultant -->
<ng-template #crossConsultantPopup let-modal class="consult-form">
  <div class="modal-header">
    <h4 class="modal-title">Add Treating Doctor / Cross Consultant</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
   <form  #consultantForm="ngForm">
    <div class="row">
      <div class="col-md-6">
        <div class="row form-group">
          <div class="col-md-4">
            <label for="docname" class="font-weight-bold">Doctor Name: <span
              class="requiredSymbol">*</span></label>
          </div>
          <div class="col-md-8">
            <ng-autocomplete #doctorauto
                [data]="filterDoctorList"
                [searchKeyword]="doctorNameFilterKeyword"
                placeholder="Enter the Doctor's Name"
                (selected)='selectAutocompleteEvent($event, "doctorname")'
                (inputChanged)='onChangeSearch($event)'
                (inputFocused)='onFocused($event)'
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
                class="autocomplete-dropdown bb"
              >
              </ng-autocomplete>          
              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.doctorName"></a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
              <span *ngIf="consultantForm.submitted && consultantFormData.doctorId == '' " class="bg-warningtxt">Doctor Name is
                required.</span>
          </div>
        </div>        
      </div>
    
      <div class="col-md-6">
        <div class="row form-group">
          <div class="col-md-4">
            <label class="font-weight-bold">Department: <span class="requiredSymbol">*</span></label>
          </div>
          <div class="col-md-8">
            <select class="form-control ropdoun bb" 
            name="dptName" 
            [(ngModel)]="consultantFormData.departmentId"
            #dpartName="ngModel" 
            [ngClass]="{ 'is-invalid-error': consultantForm.submitted && dpartName.invalid }"
            (change)="departmentChange(consultantFormData.departmentId)" 
            id="dptName"                            
            required>
            <option value="">-- Select Department --</option>
            <option *ngFor="let deprtment of departmentList; let i = index" [ngValue]="deprtment.deptId">
            {{deprtment.deptName}}
            </option>
            </select>
            <span *ngIf="consultantForm.submitted && dpartName.invalid" class="bg-warningtxt">Department is
            required</span>
          </div>
        </div>
      </div>
    </div><br>
    <div class="text-center">
      <button class="btn btn-primary mr-2 " (click)="submitConsultantFormDetails()"
    >Submit</button>
    <div class="float-left mr-2" *ngIf="consultFormErrorMessage"></div>
    </div>  
    <!-- <div class="row">
      <div class="col-md-5">Consult Doctor Name : </div>
      <div class="col-md-7">{{selectedBed.ConsultDoctor}}</div>
    </div><br>
    <div class="row">
      <div class="col-md-5">Cross Doctor Name : </div>
      <div class="col-md-7">
      <select class="form-control ropdoun bb" 
        name="dptName" 
        [(ngModel)]="consultantFormData.doctorId"
        #dctrName="ngModel" 
        [ngClass]="{ 'is-invalid-error': consultantForm.submitted && dctrName.invalid }"
        (change)="doctorChange(consultantFormData.doctorId)" 
        id="docName"                            
        required>
        <option value="">-- Select Department --</option>
        <option *ngFor="let doc of doctorIdslist; let i = index" [ngValue]="doc.doctorId">
        {{doc.doctorName}}
        </option>
        </select>
      </div>
    </div> -->    
   </form>       
  </div>
</ng-template>
<!-- end Cross Consultant -->
  <!-- bed confirmation popup -->
  <ng-template #bedConfirmPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Are you sure you want to confirm this bed</h4>
    </div>
    <div class="modal-body">
      <button class="btn btn-success mr-2" (click)="bedConfirmation(selectedBed)">Yes</button>
      <button class="btn btn-danger" type="button" aria-label="Close" (click)="modal.dismiss('Cross click')">No</button>
    </div>
  </ng-template>
  <!-- ./END bed confirmation popup -->

  <!-- bed transfer popup -->
  <ng-template #bedTransferPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Bed Transfer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form (ngSubmit)="transferForm.form.valid && submitTransferFormDetails()" #transferForm="ngForm">
        <div class="form-group">
          <label class="font-weight-bold">Select Ward: <span class="requiredSymbol">*</span></label>
          <select class="form-control ropdoun bb" 
                  name="wardList" 
                  [(ngModel)]="transferFormData.wardListId"
                  #wardList="ngModel" 
                  id="wardList" 
                  (change)="changeWard(transferFormData.wardListId)" 
                  required>
            <option value="">-- Select Ward --</option>
            <option *ngFor="let ward of completeWardsList; let i = index" [ngValue]="ward?.WM_WARD_M_ID">
              {{ward?.WM_WARD_NAME}}
            </option>
          </select>
          <span *ngIf="transferForm.submitted && wardList.invalid" class="bg-warningtxt">
            Ward is required
          </span>
        </div>

        <div class="form-group">
          <label class="font-weight-bold">Select Bed: <span class="requiredSymbol">*</span></label>
          <select class="form-control ropdoun bb" name="patientBed" [(ngModel)]="transferFormData.patientBedId"
            #patientBed="ngModel" id="patientBed" required>
            <option value="">-- Select Bed --</option>
            <option *ngFor="let bed of bedsList; let i = index" [ngValue]="bed?.WM_BED_M_ID">
              {{bed?.WM_BED_NO}}
            </option>
          </select>
          <span *ngIf="transferForm.submitted && patientBed.invalid" class="bg-warningtxt">
            Bed is required
          </span>
        </div>
        <span class="text-danger">{{transferBedError}}</span>
        <div class="float-left mr-2" *ngIf="bedTransferSubmitBtn">
          <button class="btn btn-primary">Submit</button>
        </div>
        <div *ngIf="!bedTransferSubmitBtn">
          <div>Are you sure really transfer the bed?</div>
          <button class="btn btn-success mr-2">Transfer Bed</button>
          <button class="btn btn-danger mr-2" type="button" (click)="closeBedTransferPopup()">Cancel</button>
        </div>
      </form>
    </div>
  </ng-template>
  <!-- ./END bed transfer popup -->
  
</div>