<div class="d-flex">
    <app-sidenav></app-sidenav>
    <div class="container-fluid total-page">
        <div class="row page-header">
            <!-- <div class="col-1">
                <div class="info-count-box">
                  <div class="text-center info-box-number info-box-primary-text mb-0">
                    <i class="fa fa-calendar-check"></i>
                  </div>
                  <div class="text-center info-box-text">Calendar</div>
                </div>
            </div> -->

            <div class="col-md-11 text-center">
                <h4 class="text-uppercase bold font-weight-bold orange-color">
                   Cover Letter Information
                </h4>
            </div>
            <div class="col-md-1 text-center" style="line-height: 80px;">
                <button type="button" (click)="backNav()" class="btn border-btn mr-3 px-4">Back</button>
            </div>
            <!-- <div class="col-1">
                <div class="info-count-box">
                  <div class="text-center info-box-number info-box-primary-text mb-0">
                    <span class="fa-stack fa-stack-adjust" data-notification-count="9">
                      <i class="fa fa-bell"></i>
                    </span>
                  </div>
                  <div class="text-center info-box-text">Todo</div>
                </div>
            </div> -->

        </div>
       
        <div class="row justify-content-center mb-2">
                <div class="col-md-6">
                  <div class="text-right mt-2">
                    <i class="fas fa-edit close handCursor" (click)="enableEditOption()" *ngIf="editButton" style="color: #173267"
                    title="click here to edit form"></i>
                   </div>
                <form class="row" [formGroup]="billingInsuranceForm" (ngSubmit)="onSubmit()">
                    <div class="col-12  pt-3">
                         <div class="col">
                            <label class="font-weight-bold"> Reference Number </label>
                            <input type="text" id="insuranceid" class="form-control form-control-sm"
                            name="insuranceid" formControlName="insuranceID"
                            [ngClass]="{ 'is-invalid': submitted &&  f.insuranceID.errors }" />
                           
                            <div *ngIf="submitted && f.insuranceID.errors" class="invalid-feedback">
                                <div class="invalid-feedback" *ngIf="f.insuranceID.errors.maxlength">Reference Number cannot be greater than 25 characters</div>
                                <div *ngIf="f.insuranceID.errors.required">Reference number is required</div>
                             
                            </div>
                        </div>
                    </div>
                    <div class="col-12  pt-3">                     
                        <div class="col">
                            <label class="font-weight-bold"> Claim Reference Number </label>
                          <input type="text" id="cinno" class="form-control form-control-sm" name="cinno"
                            formControlName="cinNO"  [ngClass]="{ 'is-invalid': submitted &&  f.cinNO.errors }" />
                            <div *ngIf=" submitted && f.cinNO.errors" class="invalid-feedback">
                                <div *ngIf="f.cinNO.errors.required">Claim Reference Number is required</div>
                                <div *ngIf="f.cinNO.errors.maxlength">Claim Reference Number cannot be greater than 25 characters</div>
                            </div>
                    </div>
                    </div>
                    <!-- <div class="col-12  pt-3">
                        <div class="col">
                            <label class="font-weight-bold">IP NR </label>
                          <input type="text" id="membershipid" class="form-control form-control-sm"
                            name="membershipid" formControlName="membershipID" [ngClass]="{ 'is-invalid': submitted &&  f.membershipID.errors }" />
                            <div *ngIf=" submitted && f.membershipID.errors" class="invalid-feedback">
                                <div *ngIf="f.membershipID.errors.required">Membership Id is required</div>
                                <div *ngIf="f.membershipID.errors.maxlength">Membership Id cannot be greater than 25 characters</div>
                            </div>
                        </div>
                  
            </div>
                    <div class="col-12  pt-3">
                                          <div class="col">
                            <label class="font-weight-bold">Insured Sum</label>
                            <input type="text" id="insurancesum" class="form-control form-control-sm"
                            name="insurancesum" formControlName="insuredSum" [ngClass]="{ 'is-invalid': submitted &&  f.insuredSum.errors }" />
                            <div *ngIf=" submitted && f.insuredSum.errors" class="invalid-feedback">
                                <div *ngIf="f.insuredSum.errors.required">Insured Sum is required</div>
                                <div *ngIf="f.insuredSum.errors.maxlength">Insured Sum cannot be greater than 25 characters</div>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-12  pt-3">
                        <div class="col">
                            <label class="font-weight-bold">Final Bill(Credit Voucher)</label>
                            <input type="text" id="availblesum" class="form-control form-control-sm"
                            name="availblesum" formControlName="availableSum" [ngClass]="{ 'is-invalid': submitted &&  f.availableSum.errors }" />
                            <div *ngIf=" submitted && f.availableSum.errors" class="invalid-feedback">
                                <div *ngIf="f.availableSum.errors.required">Final bill is required</div>
                                <div *ngIf="f.availableSum.errors.maxlength">Final bill cannot be greater than 25 characters</div>
                            </div>
                        </div>
                      </div>
                    <div class="col-12  pt-3">
                                        <div class="col">
                            <label class="font-weight-bold">Primary Insurance</label>
            
                        <input type="text" id="insurance" class="form-control form-control-sm"
                        name="insurance" formControlName="primaryInsurance" [ngClass]="{ 'is-invalid': submitted &&  f.primaryInsurance.errors }" />
                        <div *ngIf=" submitted && f.primaryInsurance.errors" class="invalid-feedback">
                            <div *ngIf="f.primaryInsurance.errors.required">Primary Insurance is required</div>
                            <div *ngIf="f.primaryInsurance.errors.maxlength">Primary Insurance cannot be greater than 25 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-3 text-right">
                      <button type="submit" class="btn btn-primary mr-3">{{existingRecord ? 'Save' : 'Submit'}}</button>
                      <button *ngIf="existingRecord" (click)="openCoverLetter()" type="button" class="btn btn-primary mr-3">View & Print</button>
                                         </div>
            
                  </form>
            </div>
        </div>
    </div>
</div>
<ng-template #coverLetterPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Cover Letter</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">X</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-right">
      <button *ngIf="existingRecord" (click)="printBill()" type="button" class="btn btn-primary mr-3">Print</button>
    </div>
  <div id="print-discharge-summary-section">
    <div class="row font-16 margin-top">
      <div class="col-md-6">Ref No:{{coverLetterInfo?.referenceID}}</div>
      <div class="col-md-3"></div>
      <div class="col-md-3 text-left">
        <div>{{coverLetterInfo?.hospitalName}}</div>
        <div >{{coverLetterInfo?.hospitalAddress}}</div>
        <div class="pt-2">DATE :{{coverLetterInfo?.updatedDateTime | date:"dd/MM/yyyy"}}</div>
      </div>
         </div>
         <div class="row" >
          <div class="col">
            <div class="font-16">To,</div>
            <div class="font-16">The Claims Manager,</div>
            <div class="font-16">{{coverLetterInfo?.primaryInsuranceName}},
             
         </div>
         <div class="py-4 font-16">Dear Sir,</div>
         <div class="pb-4 font-16"> Please find enclosed medicals bills of your employee who has received treatment  
          at {{coverLetterInfo?.hospitalName | titlecase}} on credit basis. Details are as under:-
       </div>
       <table class="font-weight-bold" style="width:100%;">
        <tr>
          <th>Bill No</th>
          <th>Patient's Name</th> 
          <th>IP No</th>
          <th>Claim Ref.No</th>
          <th>Net.Amt(Rs)</th>
        </tr>
        <tr>
          <td>{{ coverLetterInfo?.billNo}}</td>
          <td>{{ coverLetterInfo?.patientName}}</td>
          <td>{{ selectedIPPatientNO}}</td>
          <td>{{ coverLetterInfo?.claimNr}}</td>
          <td>{{ coverLetterInfo?.cashAmount}}</td>
        </tr>
        
      </table>
      <div class="py-4 font-16">
        Primary Insurance: {{coverLetterInfo?.primaryInsuranceType}}</div>
        <div class="font-16"> Kindly release the payment at favour of {{coverLetterInfo?.hospitalName | titlecase}} </div>
        <div class="pb-4 font-16">  At the earliest <span style="font-weight:bold">PAN NO: AAFCP7756G </span>ROHINI ID : 8900080163003</div>
        <div class="pb-4 font-16">Thanking You,</div>
        <div class="font-16">Your's Sincerely, </div>
        <div class="font-16">Sathish Kumar Maroju,</div>
        <div class="font-16"> Manager Corporate Relations,</div>
        <div class="font-16">For {{coverLetterInfo?.hospitalName | titlecase}}</div>
        <div class="font-16">Contact No: 9553411594, 040-40108117.</div>
        <div class="font-16">Email :paramithachildrenshospital@gmail.com</div>
          </div>
         </div>
  </div>
  </div>
</ng-template>

