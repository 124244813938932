export const OPSampleCollection = '/labmodule/getOPSampleCollection';
export const IPSampleCollection = '/labmodule/getIPSampleCollection';
export const OPPatientServices = '/labmodule/getOPPatientServices';
export const IPPatientServices = '/labmodule/getIPPatientServices';
export const OPLabServiceRequest = '/labmodule/addOPLabServiceRequest';
export const IPLabServiceRequest = '/labmodule/addIPLabServiceRequest';
export const LabServiceResultEntry = '/labmodule/getLabResultEntryServices';
export const AddLabServiceResult = '/labmodule/addLabResultEntryServices';
export const SampleIdsService = '/labmodule/getLabResultSampleIds';
export const SampleIdResultService = '/labmodule/getSampleIdResults';
export const ReportingDoctors = '/labmodule/getReportingDoctors';






