import { environment } from 'src/environments/environment';

export const serverUrl = environment.apiUrl;

export const SearchPatientOrByIpNr = '/inpatient/searchPatientOrByIpNr';
export const ActiveInPatientList = '/inpatient/activeInPatientList';

// export const GET_IP_PATIENTS_LIST = 'Api/ip_patients_list';
export const GET_IP_PATIENTS_LIST = 'Api/ip_patients_list1';

export const GET_PATIENT_INFO = 'Api/ip_pateint_details';
export const GET_PATIENT_SERVICE_LIST = 'Api/ip_pateint_services_charged_list';
export const PATIENT_SERVICE_CHARGED_EDIT = 'Api/ip_pateint_service_edit';
export const GET_DOCTORS_INFO = 'Api/prescribing_doctor_list';
export const GET_SERVICE_DETAILS = 'Api/ip_add_service';
export const ADD_SERVICE_CHECK = 'Api/added_service_check';
export const GET_ALL_SERVICES = 'Api/ip_patient_services_list';
export const ADD_POST_SERVICE = 'Api/add_postservice';

export const BILLING_SUMMARY_INFO = 'IpBillingSummary/patient_billing_summary_amt_info';
export const GET_REMARKS_LIST = 'IpBillingSummary/get_billing_summary_remarks';
export const SUBMIT_REMARK = 'IpBillingSummary/add_billing_summary_remarks';
export const SUBMIT_PATIENT_REMARK = 'IpBillingSummary/addIpPatientRemarks'
export const GET_HOLD_SERVICE_INFO = 'IpBillingSummary/get_holdservice_status';
export const GET_PATIENT_SERVICES_TYPE_LIST = 'IpBillingSummary/get_patient_grpby_services_list';

export const GET_DISCOUNT_AUTHORIZATION_NAMES = 'IpBillingSummary/discount_authorization_names';
export const DISCOUNT_STATUS_BY_IPNO = 'IpBillingSummary/get_discount_status_byip';
export const ASSIGN_DISCOUNT_TO_AUTHORIZATION = 'IpBillingSummary/assign_discount_to_authorization';


export const MODIFY_HOLD_SERVIOCE = 'IpBillingSummary/ip_hold_service_operation';
export const GET_PATIENT_SERVICES_GROUP_LIST = 'IpBillingSummary/pateint_services_list';
export const APPLY_DISCOUNT_TO_SERVICES = 'IpBillingSummary/apply_discount_to_services';

export const PATIENT_FINAL_BILL_CLOSURE = 'IpBillingSummary/patient_final_bill_closure';
export const HOLD_SERVICE_FINAL_BILLING_STATUS = 'IpBillingSummary/hold_service_final_billing_status';
export const VIEW_RECEIPTS_LIST = 'IpBillingSummary/view_receipts_list';
export const ADD_RECEIPT_DATA = 'IpBillingSummary/add_receipt';
export const PATIENTINFO_ATFINALBILL = 'IpBillingSummary/get_hospital_patientinfo_atfinalbill';
export const FINAL_BILL_SERVICES_INFO = 'IpBillingSummary/final_bill_services_info';
export const FINAL_BILL_RECEIPTS_LIST = 'IpBillingSummary/final_bill_receipts_list';
export const FINAL_BILL_FOOTER_ACKNOWLEDGEMENT = 'IpBillingSummary/final_bill_footer_acknowledgement';
export const DISCHARGEBTNSTATUS = 'IpBillingSummary/DischargeBtnStatus';
export const PATIENTDISCHARGE = 'IpBillingSummary/PatientDischarge';
export const ADDPREAUTHSTATUS = 'IpBillingSummary/AddPreAuthStatus';
export const PATIENTPREAUTHFORM = 'IpBillingSummary/PatientPreAuthForm';
export const GETPREAUTHLIST = 'IpBillingSummary/getPreAuthList';
export const IPRECEIPT = 'IpBillingSummary/IpReceipt';

export const PENDING_STATUS = 'IpBillingSummary/get_patient_refund_later_pending_status';
export const PAYMENT_DONE = 'IpBillingSummary/patient_final_bill_refund_later_upd';

export const IPDISCHARGEDPATIENTSLIST = 'DischargedPatients/IpDischargedPatientsList';

export const ADMISSIONCHECKOUTPRINT = 'Api/IpPatientAdmissionCheckoutPrint';

export const CREDITBILLPATIENTINFO = 'DischargedPatients/CreditBillPatientInfo';
export const CREDITBILLREMARKSLIST = 'DischargedPatients/getCreditBillRemarks';
export const ADDCREDITBILLREMARKS = 'DischargedPatients/addCreditBillRemarks';
export const CREDITBILLSUBMISSION = 'DischargedPatients/CreditBillSubmission';
export const DATEWISESERVICECHARGED = 'IpBillingSummary/dateWiseServiceCharged'

export const GETTARIFFS = 'Api/getPatientTariefNames';
export const GETSERVICESBYIPNO = 'Api/servicesListByIPNO';

export const PATIENTSERVICECHARGEDROWUPDATE = 'Api/patientServiceChargedrowUpdate';
export const SUBMITINSURANCEINFO = 'v1/Insurance/submitInsuranceInfo';

export const UPDATEFULLBREAKPRINTLABELSTATUS = 'IpBillingSummary/updateFullBreakPrintLabelStatus';
export const UPDATERECEIPTPRINTLABELSTATUS = 'IpBillingSummary/updateReceiptPrintLabelStatus';
export const RECEIPTSOPERATION = 'Api/receiptsOperation';
export const RECEIPTREFUNDORCREDITACTION = 'Api/receiptRefundOrCreditAction';
export const GET_CONSULT_DOCTORS_LIST = 'Api/getConsultDoctorsList';
export const GET_SPONSERS_LIST = 'Api/getPayeeTypeList';
export const GET_IP_PATIENT_DETAILS = 'Api/ip_pateint_details';
export const GETCOVERLETTERDATA = 'v1/CoverLetters/getCoverLetterInfo';
export const GETINSURANCEDETAILS = 'v1/Insurance/getInsuranceInfo';
export const UPDATEINSURANCEINFO = 'v1/Insurance/updateInsuranceInfo';
export const PATIENTINFO_FINALBILL = '/IpBillingSummary/get_hospital_patientinfo_atfinalbill';
export const GET_PATIENT_INSURANCEINFO = '/v1/Billing/getPatientInsuranceInformation';

export const Inpatient_URL = {
    SearchPatientOrByIpNrURL : serverUrl + SearchPatientOrByIpNr,
    GET_IP_PATIENTS_LIST: serverUrl + GET_IP_PATIENTS_LIST,
    GET_PATIENT_INFO: serverUrl + GET_PATIENT_INFO,
    GET_PATIENT_SERVICE_LIST: serverUrl + GET_PATIENT_SERVICE_LIST,
    PATIENT_SERVICE_CHARGED_EDIT: serverUrl + PATIENT_SERVICE_CHARGED_EDIT,
    GET_DOCTORS_INFO: serverUrl + GET_DOCTORS_INFO,
    GET_SERVICE_DETAILS: serverUrl + GET_SERVICE_DETAILS,
    ADD_SERVICE_CHECK: serverUrl + ADD_SERVICE_CHECK,
    GET_ALL_SERVICES: serverUrl + GET_ALL_SERVICES,
    ADD_POST_SERVICE: serverUrl + ADD_POST_SERVICE,
    BILLING_SUMMARY_INFO: serverUrl + BILLING_SUMMARY_INFO,
    GET_REMARKS_LIST: serverUrl + GET_REMARKS_LIST,
    SUBMIT_REMARK: serverUrl + SUBMIT_REMARK,
    SUBMIT_PATIENT_REMARK: serverUrl + SUBMIT_PATIENT_REMARK,
    GET_HOLD_SERVICE_INFO: serverUrl + GET_HOLD_SERVICE_INFO,
    GET_PATIENT_SERVICES_TYPE_LIST: serverUrl + GET_PATIENT_SERVICES_TYPE_LIST,
    GET_DISCOUNT_AUTHORIZATION_NAMES: serverUrl + GET_DISCOUNT_AUTHORIZATION_NAMES,
    DISCOUNT_STATUS_BY_IPNO: serverUrl + DISCOUNT_STATUS_BY_IPNO,
    ASSIGN_DISCOUNT_TO_AUTHORIZATION: serverUrl + ASSIGN_DISCOUNT_TO_AUTHORIZATION,
    GET_PATIENT_SERVICES_GROUP_LIST: serverUrl + GET_PATIENT_SERVICES_GROUP_LIST,
    APPLY_DISCOUNT_TO_SERVICES: serverUrl + APPLY_DISCOUNT_TO_SERVICES,
    MODIFY_HOLD_SERVIOCE: serverUrl + MODIFY_HOLD_SERVIOCE,
    PATIENT_FINAL_BILL_CLOSURE: serverUrl + PATIENT_FINAL_BILL_CLOSURE,
    HOLD_SERVICE_FINAL_BILLING_STATUS: serverUrl + HOLD_SERVICE_FINAL_BILLING_STATUS,
    VIEW_RECEIPTS_LIST: serverUrl + VIEW_RECEIPTS_LIST,
    ADD_RECEIPT_DATA: serverUrl + ADD_RECEIPT_DATA,
    PATIENTINFO_ATFINALBILL: serverUrl + PATIENTINFO_ATFINALBILL,
    FINAL_BILL_SERVICES_INFO: serverUrl + FINAL_BILL_SERVICES_INFO,
    FINAL_BILL_RECEIPTS_LIST: serverUrl + FINAL_BILL_RECEIPTS_LIST,
    FINAL_BILL_FOOTER_ACKNOWLEDGEMENT: serverUrl + FINAL_BILL_FOOTER_ACKNOWLEDGEMENT,
    DISCHARGEBTNSTATUS: serverUrl + DISCHARGEBTNSTATUS,
    PATIENTDISCHARGE: serverUrl + PATIENTDISCHARGE,
    ADDPREAUTHSTATUS: serverUrl + ADDPREAUTHSTATUS,
    PATIENTPREAUTHFORM: serverUrl + PATIENTPREAUTHFORM,
    GETPREAUTHLIST: serverUrl + GETPREAUTHLIST,
    IPRECEIPT: serverUrl + IPRECEIPT,

    PAYMENT_DONE: serverUrl + PAYMENT_DONE,
    PENDING_STATUS: serverUrl + PENDING_STATUS,
    IPDISCHARGEDPATIENTSLIST: serverUrl + IPDISCHARGEDPATIENTSLIST,
    ADMISSIONCHECKOUTPRINT: serverUrl + ADMISSIONCHECKOUTPRINT,
    CREDITBILLPATIENTINFO: serverUrl + CREDITBILLPATIENTINFO,
    CREDITBILLREMARKSLIST: serverUrl + CREDITBILLREMARKSLIST,
    ADDCREDITBILLREMARKS: serverUrl + ADDCREDITBILLREMARKS,
    CREDITBILLSUBMISSION: serverUrl + CREDITBILLSUBMISSION,
    DATEWISESERVICECHARGED: serverUrl + DATEWISESERVICECHARGED,
    GETTARIFFS: serverUrl + GETTARIFFS,
    GETSERVICESBYIPNO: serverUrl + GETSERVICESBYIPNO,
    PATIENTSERVICECHARGEDROWUPDATE: serverUrl + PATIENTSERVICECHARGEDROWUPDATE,
    SUBMITINSURANCEINFO: serverUrl + SUBMITINSURANCEINFO,

    UPDATEFULLBREAKPRINTLABELSTATUS: serverUrl + UPDATEFULLBREAKPRINTLABELSTATUS,
    UPDATERECEIPTPRINTLABELSTATUS: serverUrl + UPDATERECEIPTPRINTLABELSTATUS,
    RECEIPTSOPERATION: serverUrl + RECEIPTSOPERATION,
    RECEIPTREFUNDORCREDITACTION: serverUrl + RECEIPTREFUNDORCREDITACTION,
    GET_CONSULT_DOCTORS_LIST: serverUrl + GET_CONSULT_DOCTORS_LIST,
    GET_SPONSERS_LIST : serverUrl + GET_SPONSERS_LIST,
    GET_IP_PATIENT_DETAILS: serverUrl + GET_IP_PATIENT_DETAILS,
    GETCOVERLETTERDATA: serverUrl + GETCOVERLETTERDATA,
    GETINSURANCEDETAILS: serverUrl + GETINSURANCEDETAILS,
    UPDATEINSURANCEINFO: serverUrl + UPDATEINSURANCEINFO,
    PATIENTINFO_FINALBILL: serverUrl + PATIENTINFO_FINALBILL,
    GET_PATIENT_INSURANCEINFO: serverUrl + GET_PATIENT_INSURANCEINFO
};

export const PatientInfo = '/patient/searchPatient';
