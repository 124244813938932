import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { InpatientService } from '../inpatient.service';
import {AppointmentService} from '../../appointments/appointment.service';
import { BillingService } from '../../billing-module/billing.service';
import { CommonService } from '../../shared/services/common.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-inpatient-service.component.html',
  styleUrls: ['./add-inpatient-service.component.css']
})

export class AddInpatientServiceComponent implements OnInit {

  date = new Date();
  public userInfo: any = {};
  public patientInfo: any = {};
  public ipPatientNO = 0;
  public searchServiceName: any = '';
  public allServices: any = [];
  public selectedAllServices: any = [];
  public searchServiceNameVal = '';
  public searchServiceType = '';
  public doctorsList: any = [];
  public doctorServicesList: any = [];
  public selectedServices: any = [];
  public grandTotal: any;
  public discountAmount: any;
  public discountAmountPercenttage: any;
  public netAmount: any;
  public addServiceCheckInfo: any = {};
  public addedSelectService: any = '';

  patientURMNo: number;

  minDate = '';
  maxDate = '';

  keyword = 'DM_NAME';
  initialValue: any;

  saveLoader = false;

  holdServiceStatus = 'N';
  finalBillStatus = 'N';
  bedConfirmationStatus = 'Y';
  returnMode = '';

  constructor(private tokenService: TokenStorageService,
              private toastrService: ToastrService,
              private billingService: BillingService,
              private inpatientService: InpatientService,
              private router: Router,
              private route: ActivatedRoute,
              private appointmentService: AppointmentService,
              private commonService: CommonService,
              private modalService: NgbModal
              ) {

    this.userInfo = this.tokenService.getUser();
    const id = this.route.snapshot.paramMap.get('id');
    // tslint:disable-next-line:radix
    this.ipPatientNO = parseInt(id);

    this.route.queryParams.subscribe(params => {

      const urmNo = params.umrNumber;
      // tslint:disable-next-line:radix
      this.patientURMNo = parseInt(urmNo);
      this.returnMode = params.mode;

    });

  }

  ngOnInit(): void {
    this.getPatientInfo();
    // this.currentDate = this.appointmentService.requiredDateFormat(this.date)
    this.holdServiceBillingStatus();
  }

  public getPatientInfo(): any {

    const search = {
      sear_umr_no: this.patientURMNo,
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID
    };

    this.inpatientService.patientInfo(search).subscribe(response => {
      this.patientInfo = response;
      this.patientInfo.prescribeDM_NAME = '';
      this.patientInfo.prescribeDM_ID = '';
      this.minDate = this.appointmentService.requiredDateFormat(new Date(this.patientInfo.IP_ADMISSION_DATE));
      this.maxDate = this.appointmentService.requiredDateFormat(this.date);
      this.searchServiceData();
      this.getDoctorsList(); // this.ipPatientNO
    });

  }

  holdServiceBillingStatus(): void {

    const search = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID
    };

    this.inpatientService.holdServiceBillingStatus(search).subscribe((response) => {
      this.holdServiceStatus = response.HoldServiceStatus;
      this.finalBillStatus = response.FinalBillStatus;
      this.bedConfirmationStatus = response.BedConfirmationStatus;
    });

  }

  public getDoctorsList(): any {
    this.doctorsList = [];
    const search = {
      sear_ip_no: this.patientInfo.IP_NR,
      sear_hospitalid: this.userInfo.hospitalID
    };

    this.inpatientService.getDoctorsInfo(search).subscribe(serResponse => {
      this.doctorsList = serResponse;

      if (this.doctorsList.length === 1) {
        this.initialValue = this.doctorsList[0];
      }
      // if (this.doctorsList.length !== 0) {
      //   this.getDoctorServiceInfo(this.doctorsList[0]);
      // }
    });

  }

  public getDoctorServiceInfo(doctorobj: any): any {

    // const serviceSearchObj = {
    //   sear_gender: this.patientInfo.GENDER,
    //   sear_hospitalid: '1'
    // };

    // this.doctorServicesList = [];
    // this.inpatientService.getDoctorsServiceList(serviceSearchObj).subscribe(response => {
    //     this.doctorServicesList = response;
    // });

  }
  public addServiceCheck(service, addServiceConfirmPopup?) {
    const serviceJson = {
      sear_hospitalid: this.userInfo.hospitalID,
      sear_ip_no: this.patientInfo.IP_NR,
      sear_sm_id: service.SM_ID,
      sear_bill_date: this.commonService.getCurrentDate()
    };
    // const serviceJson = {
    //   hospitalID: this.userInfo.hospitalID,
    //   ipNr: "201002",
    //   serviceMID: "4801",
    //   serviceBillDate: "2021-03-16"
    // };
    this.inpatientService.addServiceCheck(serviceJson)
      .subscribe(
        response => {
          if(response.message == "notExist") {
            this.addService(service);
          }
          else {
            this.addServiceCheckInfo = response.data;
            this.addedSelectService = service;
            this.modalService.open(addServiceConfirmPopup);
          }
        },
        err => {
          console.log("error", err)
        });
  }

  public addService(service: any): any {
    this.modalService.dismissAll();
    const serviceJson = {
      sear_hospitalid: this.userInfo.hospitalID,
      sear_ip_no: this.patientInfo.IP_NR,
      sear_sm_id: service.SM_ID
    };
    const hasService = this.doctorServicesList.filter(obj => obj.SM_ID === service.SM_ID);
    if (hasService.length === 0) {
      this.inpatientService.getServiceDetail(serviceJson).subscribe(response => {
        if (response !== null) {
          const obj = response[0];
          if (obj !== undefined) {
            obj.ismain = true;
            obj.isSelected = true;
            obj.SERVICE_TYPE_NAME = service.SERVICE_TYPE_NAME;
            obj.SERVICE_UNIT_QTY = 1; // static as of now
            obj.SERVICE_BILL_DATE = this.commonService.formatDate('');
            this.doctorServicesList.push(obj);
            this.onSelectService(obj);

            service.isSelected = true;
          }
        }
      });
    }
  }

  public onSelectService(serviceObj: any): any {

    if (serviceObj.isSelected) {
      if (serviceObj.SERVICE_UNIT_QTY === 0) {
        serviceObj.SERVICE_UNIT_QTY = 1;
      }
      serviceObj.TOTAL_PRICE = serviceObj.SERVICE_UNIT_QTY * serviceObj.AMOUNT;
      serviceObj.NET_AMOUNT = serviceObj.SERVICE_UNIT_QTY * serviceObj.AMOUNT;
      serviceObj.PRIORITY = '';
      serviceObj.REMARKS = '';
      this.selectedServices.push(serviceObj);
    } else {
      const hasObj = this.selectedServices.filter(obj => obj.SERVICE_NAME === serviceObj.SERVICE_NAME);
      if (hasObj.length !== 0) {
        const index = this.selectedServices.indexOf(hasObj[0]);
        this.selectedServices.splice(index, 1);
      }
      if (serviceObj.ismain) {
          const index = this.doctorServicesList.indexOf(serviceObj);
          this.doctorServicesList.splice(index, 1);
      }
      let objIndex = this.selectedAllServices.findIndex((obj => obj.SM_ID == serviceObj.SM_ID) );
      this.selectedAllServices[objIndex].isSelected = false;
    }
    
  }

  onSubmit(): void {

    const servicesList = [];
    const prioritySelectError = 0;

    if (this.selectedServices.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.selectedServices.length; i++) {

        const data = {
          sear_h_id: this.userInfo.hospitalID,
          sear_ip_no: this.patientInfo.IP_NR,
          service_bill_date: this.selectedServices[i].SERVICE_BILL_DATE,
          sear_sm_id: this.selectedServices[i].SM_ID,
          service_amount: this.selectedServices[i].TOTAL_PRICE,
          sear_dm_id: this.patientInfo.prescribeDM_ID,
          sbi_remarks: this.selectedServices[i].REMARKS,
          sear_priority: this.selectedServices[i].PRIORITY,
          created_name: this.userInfo.username,
          unit_quantity: this.selectedServices[i].SERVICE_UNIT_QTY
        };
        servicesList.push(data);

        // Not validating as per requirment
        // if (this.selectedServices[i].PRIORITY === '') {
        //   prioritySelectError++;
        // }

      }
    }

    if (this.patientInfo.prescribeDM_ID === '') {
      this.toastrService.error('Please choose prescribing Doctor name');
      return;
    }

    if (prioritySelectError > 0) {
      this.toastrService.error('Please select Priority for selected services');
      return;
    }

    const serviceJson = {
      list: servicesList
    };

    this.saveLoader = true;

    this.inpatientService.addPostService(serviceJson).subscribe(response => {
        if (response.status === 'Success') {
          this.toastrService.success(response.message);
          // this.router.navigate(['ServiceCharged/' + this.ipPatientNO, {umrNumber: this.patientURMNo}]);
          const queryParam = { umrNumber: this.patientURMNo, mode: 'iplist'};
          this.router.navigate(['ServiceCharged/' + this.ipPatientNO], { queryParams: queryParam });

        } else if (response.status === 'Fail') {
          this.saveLoader = false;
          this.toastrService.error(response.message);

          const queryParam = { umrNumber: this.patientURMNo, mode: 'iplist', holdstatus: 'reload' };
          this.router.navigate(['AddInpatientServices/' + this.ipPatientNO], { queryParams: queryParam });
        } else {
          this.saveLoader = false;
          this.toastrService.error(response.message);

          const queryParam = { umrNumber: this.patientURMNo, mode: 'iplist', holdstatus: 'reload' };
          this.router.navigate(['AddInpatientServices/' + this.ipPatientNO], { queryParams: queryParam });
        }
      },
      error => {
        this.saveLoader = false;
        this.toastrService.error('Something went wrong..!');
      }
    );

  }

  public calculateTotalAmount(): any {

    this.grandTotal = 0;
    this.discountAmount = 0;
    this.discountAmountPercenttage = 0;
    this.netAmount = 0;

    this.selectedServices.forEach(element => {

      element.totalPrice = element.serviceUnitQty * element.servicePrice;
      this.grandTotal = this.grandTotal + element.totalPrice;
      this.grandTotal =  Number(this.grandTotal.toFixed(2));
      if (element.discount !== undefined && element.discount !== '') {
        const disAmt = ((element.totalPrice / 100) * element.discount);
        this.discountAmount = Number(this.discountAmount + disAmt);
        element.serviceNetAmt = (element.serviceUnitQty * element.servicePrice) - disAmt;
      } else if (element.discountRuppes !== undefined && element.discountRuppes !== '') {
        this.discountAmount = Number(this.discountAmount + Number(element.discountRuppes));
        element.serviceNetAmt = (element.serviceUnitQty * element.servicePrice) - Number(element.discountRuppes);
      } else{
        element.serviceNetAmt = (element.serviceUnitQty * element.servicePrice);
      }

    });

    this.discountAmount = Number(this.discountAmount.toFixed(2));
    this.netAmount = this.grandTotal - this.discountAmount;
    this.netAmount = Number(this.netAmount.toFixed(2));
    this.discountAmountPercenttage = ((this.grandTotal - this.netAmount) / this.grandTotal) * 100;
    this.discountAmountPercenttage = Number(this.discountAmountPercenttage.toFixed(2));

  }

  public searchServiceData(): any {

    this.allServices = [];
    this.selectedAllServices = [];

    const searchJson: any = {
      sear_gender: this.patientInfo.GENDER,
      sear_hospitalid: this.userInfo.hospitalID
    };

    this.inpatientService.getAllServices(searchJson).subscribe(response => {
      if (response !== null) {
        this.allServices = response;
        this.selectedAllServices = response;

        this.selectedAllServices.forEach(element => {
          element.isSelected = false;
        });
      }
    });

  }

  public servicenameSearch(): any {

    if (this.searchServiceType === '' && this.searchServiceNameVal === '') {
      this.selectedAllServices = this.allServices;
    } else if (this.searchServiceNameVal !== '' && this.searchServiceType !== ''){
      this.selectedAllServices = this.selectedAllServices.filter(obj => {
        return (obj.SERVICE_NAME.toLowerCase().indexOf(this.searchServiceNameVal.toLowerCase()) !== -1);
      });
    } else {
      this.selectedAllServices = this.allServices.filter(obj => {
        return (obj.SERVICE_NAME.toLowerCase().indexOf(this.searchServiceNameVal.toLowerCase()) !== -1);
      });
    }

  }

  public serviceTypeSearch(): any {

    if (this.searchServiceType === '' && this.searchServiceNameVal === '') {
      this.selectedAllServices = this.allServices;
    } else if (this.searchServiceNameVal !== '' && this.searchServiceType !== ''){
      this.selectedAllServices = this.selectedAllServices.filter(obj => {
        return (obj.SERVICE_TYPE_NAME.toLowerCase().indexOf(this.searchServiceType.toLowerCase()) !== -1);
      });
    } else {
      this.selectedAllServices = this.allServices.filter(obj => {
        return (obj.SERVICE_TYPE_NAME.toLowerCase().indexOf(this.searchServiceType.toLowerCase()) !== -1);
      });
    }

  }

  selectEvent(item: any): void {
    // do something with selected item
    this.patientInfo.prescribeDM_NAME = item.DM_NAME;
    this.patientInfo.prescribeDM_ID = item.DM_ID;
  }

  onChangeSearch(val: string): void {

    return this.doctorsList.filter(option => option.DM_NAME.toLowerCase().includes(val));

  }

  onFocused(e): void {
    // do something when input is focused
  }

  // changeUnitQTY(e, index): void {

  //   const value = e.target.value;
  //   if (value > 99) {
  //     this.selectedServices[index].SERVICE_UNIT_QTY = this.selectedServices[index].SERVICE_UNIT_QTY.slice(0, -1);
  //   } else if (value <= 99) {
  //     this.selectedServices[index].SERVICE_UNIT_QTY = value;
  //   }

  // }

  public changeUnitQTY(event, index): void {
    // console.log(event.target.value);

    const min = 1;
    const max = 99;
    const unitQty = event.target.value;

    if (unitQty !== '') {

      if (unitQty < min) {
        this.toastrService.error('Unit Qty should be between 1 to 99');
        event.target.value = min;
      } else if (unitQty > max ) {
        this.toastrService.error('Unit Qty should be between 1 to 99');
        event.target.value = max;
      } else {
        event.target.value = unitQty;
      }

      this.selectedServices[index].SERVICE_UNIT_QTY = event.target.value
    }

  }
  navigate() {
    if (this. returnMode  === 'iplist') {
      this.router.navigate(['/Inpatient']);
    }
    else{
      this.router.navigate(['/nursestation']);
    }
  }

}
