
<div class="land">
  <div class="container-fluid ">
    <div class="row btn_align justify-content-md-center">
      <div class="m-3" *ngIf="rolesInfo?.subFunctionalityLevel?.billing_view_outpatient">
        <button type="button" (click)="bodyDisplay = 'viewoutPatient';"  [class.active]="bodyDisplay === 'viewoutPatient'" class="btn btn-sm btnn btn-light" >View Outpatients</button>
      </div>
      <div class="m-3" *ngIf="rolesInfo?.subFunctionalityLevel?.billing_view_inpatient">
        <button type="button" routerLink="/Inpatient" class="btn btn-sm btnn btn-light" >View Inpatients</button>
      </div>
      <div class="m-3" *ngIf="rolesInfo?.subFunctionalityLevel?.billing_search_view_op_billing">
        <button type="button" (click)="bodyDisplay = 'viewoutPatientBill';" class="btn btn-sm btnn btn-light"  [class.active]="bodyDisplay === 'viewoutPatientBill'">Search/View OP Bills</button>
      </div>
      <div class="m-3" *ngIf="rolesInfo?.subFunctionalityLevel?.billing_reports">
        <button type="button"  routerLink="/reports" class="btn btn-sm btnn btn-light">Reports</button>
      </div>
      <div class="m-3">
        <!-- routerLink="/Enquiry" -->
        <button type="button"  (click)="bodyDisplay = 'serviceEnquiry';" [class.active]="bodyDisplay === 'serviceEnquiry'"  class="btn btn-sm btnn btn-light" >Enquiry</button>
      </div>
      <div class="m-3" *ngIf="userInfo?.hospitalShortName == 'DPMTA' || userInfo?.hospitalShortName == 'KPMTA' || 
      userInfo?.hospitalShortName == 'CPMTA' || userInfo?.hospitalShortName == 'CBPMTA' || userInfo?.hospitalShortName == 'JPMTA'">
      <!-- routerLink="/Enquiry"> -->
        <button type="button" 
        (click)="bodyDisplay = 'vaccinationBill';" [class.active]="bodyDisplay === 'vaccinationBill'"  class="btn btn-sm btnn btn-light">VaccinationBilling</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="bodyDisplay === 'viewoutPatient'" class="container-fluid viewOutpatient p-0"
  style="height:calc(100vh - 139px);">
  <app-viewoutpatient [registerVisitId]="visitID"></app-viewoutpatient>
</div>

<div *ngIf="bodyDisplay === 'viewoutPatientBill'" class="container-fluid viewoutPatientBill p-0"
    style="height:calc(100vh - 139px);">
  <app-viewoutpatientbill [registerVisitId]="visitID"></app-viewoutpatientbill>
</div>

<div *ngIf="bodyDisplay === 'serviceEnquiry'" class="container-fluid serviceEnquiry p-0"
    style="height:calc(100vh - 139px);">
  <app-enquiry></app-enquiry>
</div>
<div *ngIf="bodyDisplay === 'vaccinationBill'" class="justify-content-center vaccinationBill"
    style="height:calc(100vh - 139px);">
  <app-vaccination></app-vaccination>
</div>
