import {Component, OnInit, ViewChild} from '@angular/core';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import {merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  AppointmentAppointmentsByDate, AppointmentAppointmentsByNameNumberUmr,
  AppointmentDataBYDoctor,
  AppointmentSearchDoctor,
  AppointmentViewAppointments
} from '../../appointments/appointmentconstants';
import {ViewAppointmentsModel} from '../../shared/appointmentsModels/viewAppointmentsModel';
import {AppointmentService} from '../../appointments/appointment.service';
import {SearchDoctors} from '../../shared/appointmentsModels/searchDoctors';
import { NgxPrinterService } from 'ngx-printer';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import { MatTableDataSource } from '@angular/material/table';
import { WardService } from 'src/app/ward-management/ward.service';
import { ToastrService } from 'ngx-toastr';
import { NursestationsService } from 'src/app/ward-management/nursestations.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-front-office',
  templateUrl: './front-office.component.html',
  styleUrls: ['./front-office.component.css']
})

export class FrontOfficeComponent implements OnInit {
  
  userInfo: any;
  public bodyDisplay = 'IP';
  columnsToDisplay = [
    'IPNUM',
    'NAMEGENDERAGE',
    'REFERRALDOCTOR',
    'CONSULTDOCTORWARDBED',
    'ADMISSIONDATEANDTIME',
    'LENGTHOFSTAY',
    'ACTION',
  ];
  advSearchForm: FormGroup;
  patientsList: any = [];
  patientsData = new MatTableDataSource(this.patientsList);
  patients: any = {};
  icdPatientDetails: any;
  patientIPNo: any;
  patientUMRNo: any;
  dischargeSummaryPatientInfo: any;  
  existingDischargeSummaryInfo: any = '';
  patientQuickInformation: any;
  existingRecord: boolean = false;
  enableDischargeList:boolean = false;

  constructor(
    private tokenService: TokenStorageService,
    private modalService: NgbModal,
    private nursestationsService: NursestationsService,
    private wardService: WardService,
    private toastr: ToastrService,
    private router: Router
   ) {
    this.userInfo = this.tokenService.getUser();
  }

  ngOnInit(): void {    
    this.advSearchForm = new FormGroup({      
      PATIENTNAME : new FormControl('')      
    });

    this.loadPatientsList();
  }

  /* Patient search with name */
  onSearch(){
  this.loadPatientsList();    
  }

  get f(): any { return this.advSearchForm.controls; }

  /* to load the patients list */
  loadPatientsList(){
    const input = {
      "start":0,
      "limit":10,
      "ser_hospitalid":this.userInfo.hospitalID,
      "sear_umr_no":"",
      "sear_ip_no":"",
      "sear_patient":this.advSearchForm.controls.PATIENTNAME.value,
      "sear_admission_date":"",
      "sear_consult_doctor":"",
      "sear_ptmid":"",
      "admission_date_order":"",
      "doctor_name_order":""
      }
      this.wardService.getPatientsList(input).subscribe((response)=>{
        this.patients = response;
        this.patientsList = this.patients.data;
        //console.log(this.patientsList);
        this.patientsData = new MatTableDataSource(this.patientsList);
      });
  }

  getDischargeList(){
    this.router.navigate(['/dischargelist']);
  } 
  patientQuickInfo(patientQuickInfoPopup, patient): void {
    this.patientQuickInformation = patient;
    this.modalService.open(patientQuickInfoPopup, { size: 'xl' });
  }
  viewLabReportsPopup(labReportsPopup, patientData)
  {
    console.log(patientData);
    this.patientIPNo = patientData.IP_NR;
    this.patientUMRNo = patientData.P_ID;
    this.modalService.open(labReportsPopup, { size: 'xl'});
  }

  /* printDischargeSummary(dischargeSummaryPrintPopup, patientData)
  {
    this.dischargeSummaryPatientInfo = patientData;
    this.modalService.open(dischargeSummaryPrintPopup, { size: 'xl'});
  } */

  actionIcdPop(icdPopup, patientDta){
    this.icdPatientDetails = patientDta;
    this.modalService.open(icdPopup, {size: 'xl'});
  }

  printDischargeSummary(dischargeSummaryPrintPopup, patientData): void {
    //console.log("patient data", patientData)
    const data = {
      UMRNo: patientData.P_ID,
      IpNr: patientData.IP_NR,
      PatientName: patientData.PATIENT_NAME,
      GenderAge: patientData.AGE,
      ConsultDoctor: patientData.CONSULT_DOCTOR,
      address: patientData.address,
      refDoctorName: patientData.externalRefDoctor,
      admissionDate: patientData.IP_ADMISSION_DATE,
      dischargeDate: patientData.IP_DISCHARGE_DATE,
      headingTitle: patientData.HEADING_TITLE,
      dischargeType: patientData.DISCHARGE_TYPE,
      title: patientData.TITLE
    };
    this.dischargeSummaryPatientInfo = data;
    this.getDischargeSummary(patientData.IP_NR, dischargeSummaryPrintPopup);
  }

  getDischargeSummary(ipNr, dischargeSummaryPrintPopup): void {
    const inputRequest = {
      ipNr,
    };
    this.nursestationsService.getDischargeSummaryInfo(inputRequest).subscribe(
      (dischargeSummaryInfo: any) => {
        // console.log("dischargeSummaryInfo", dischargeSummaryInfo);
        this.dischargeSummaryPatientInfo.dischargeType = dischargeSummaryInfo.DISCHARGE_TYPE
        this.dischargeSummaryPatientInfo.headingTitle = dischargeSummaryInfo.HEADING_TITLE
        this.dischargeSummaryPatientInfo.address = dischargeSummaryInfo.ADDRESS
        this.dischargeSummaryPatientInfo.refDoctorName = dischargeSummaryInfo.DOCTOR_NAME
        this.dischargeSummaryPatientInfo.ConsultDoctor = dischargeSummaryInfo.CONSULTANT_DOC_NAME
        this.existingDischargeSummaryInfo = dischargeSummaryInfo
        this.modalService.open(dischargeSummaryPrintPopup, { size: 'lg' })
        this.existingRecord = true
      },
      (err) => {
        console.log('HTTP Error', err.error);
        // this.toastrService.error(err.error.message);
        this.existingRecord = false
        this.modalService.open(dischargeSummaryPrintPopup, { size: 'lg' })
      }
    );
  }
  popupClose(){
    this.modalService.dismissAll();
  }
}