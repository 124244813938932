import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LandingpageModule } from './landingpage/landingpage.module';
import { AppointmentsModule } from './appointments/appointments.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {MasterManagementModule} from './master-management/master-management.module'
import { BillingModuleModule } from './billing-module/billing-module.module';
import { ViewinpatientModule } from './viewinpatient-module/viewinpatient.module';
import { RegnOpdmanagementModule } from './regn-opdmanagement/regn-opdmanagement.module';
import { DoctorsWorkstationModule } from './doctors-workstation/doctors-workstation.module';
import { NgxAgoraModule } from 'ngx-agora';
import { environment } from 'src/environments/environment';
import { VideoConsultationModule } from './video-consultation/video-consultation.module';
import { LoginComponent } from './login/login.component';
import { authInterceptorProviders } from './shared/loginhelpers/auth.interceptor';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';
import { HeaderComponent } from './landingpage/header/header.component';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { WardManagementModule } from './ward-management/ward-management.module';
import { ToastrModule } from 'ngx-toastr';
import { LabModuleModule } from './lab-module/lab-module.module';
import {RadiologyManagementModule} from './radiology-management/radiology-management.module'
import { NgxPrinterModule } from 'ngx-printer';
import { MyAccountModule } from './my-account/my-account.module';
/* Loader Component */
import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderService } from './shared/services/Loader/loader.service';
import { LoaderInterceptorService } from './shared/services/Loader/loader-interceptor.service';

import { ConfirmDialogModule } from './shared/confirm-dialog/confirm-dialog.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './shared/material/material.module';
import { ReportsModule } from './reports/reports.module';

import { PreviewComponent } from './print-management/preview/preview.component';
import { ReceiptPrintComponent } from './print-management/receipt-print/receipt-print.component';
import { SummaryBillPrintComponent } from './print-management/summary-bill-print/summary-bill-print.component';
import { AdmissionformPrintComponent } from './print-management/admissionform-print/admissionform-print.component';
import { DischargeformPrintComponent } from './print-management/dischargeform-print/dischargeform-print.component';

import { NgxEditorModule } from 'ngx-editor';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { MultipleFileuploadComponent } from './shared/multiple-fileupload/multiple-fileupload.component';
import {MrdModule} from './mrd/mrd.module';
import {InventoryManagementModule} from './inventory-management/inventory-management.module';
import { CKEditorModule } from 'ckeditor4-angular';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProfileComponent,
    RegisterComponent,
    HeaderComponent,
    LoaderComponent,
    PreviewComponent,
    ReceiptPrintComponent,
    SummaryBillPrintComponent,
    AdmissionformPrintComponent,
    DischargeformPrintComponent,
    FileuploadComponent,
    MultipleFileuploadComponent
  ],
  imports: [
    InventoryManagementModule,
    MasterManagementModule,
    MrdModule,
    BrowserModule,
    RadiologyManagementModule,
    LandingpageModule,
    AppointmentsModule,
    NgbModule,
    ConfirmDialogModule,
    WardManagementModule,
    HttpClientModule,
    BillingModuleModule,
    ViewinpatientModule,
    ReportsModule,
    MyAccountModule,
    CKEditorModule,
    NgxPrinterModule.forRoot({ printOpenWindow: true }),
    RegnOpdmanagementModule,
    DoctorsWorkstationModule,
    VideoConsultationModule,
    NgxAgoraModule.forRoot({ AppID: environment.agora.appId }),
    NgxEditorModule,
    FormsModule,
    AppRoutingModule,
    LabModuleModule,
    MrdModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right'
    }),
    BrowserAnimationsModule,
    MaterialModule
  ],
  exports: [
    PreviewComponent,
    ReceiptPrintComponent,
    SummaryBillPrintComponent,
    AdmissionformPrintComponent,
    DischargeformPrintComponent,
    MultipleFileuploadComponent
  ],
  entryComponents: [
    PreviewComponent,
    ReceiptPrintComponent,
    SummaryBillPrintComponent,
    AdmissionformPrintComponent,
    DischargeformPrintComponent
  ],
  providers: [
    authInterceptorProviders,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
