import { Injectable } from '@angular/core';
import { FacadeService } from '../shared/facade.service';

@Injectable({
  providedIn: 'root'
})
export class LabService {

    constructor(private facadeService: FacadeService) { }
  
    public getSampleCollection(parameters: string, data: any): any {
      return this.facadeService.postService(data, parameters);
    }

    public getLabPatientServiceByID(parameters: string, data: any): any {
      return this.facadeService.postService(data, parameters);
    }

    public saveLabServicesRequest(parameters: string, data: any): any {
      return this.facadeService.postService(data, parameters);
    }

    public getLabServiceResultEntry(parameters: string, data: any): any {
      return this.facadeService.postService(data, parameters);
    }

    public saveLabResultEntryServices(parameters: string, data: any): any {
      return this.facadeService.postService(data, parameters);
    }
  
    public getReportingDoctorsInfo(parameters: string, data: any): any {
      return this.facadeService.postService(data, parameters);
    }
}
