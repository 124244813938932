<div class="row">
  <div class="col-12">
    <fieldset class="legend-block">
      <legend class="legend-title mb-0 font-16">Patient Info</legend>
      <div class="row">
        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Name </div>
            <div class="col"> {{patientInfo?.TITLE}} {{patientInfo?.PATIENT_NAME}} </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> IP Number </div>
            <div class="col"> {{patientInfo?.IP_NR}} </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Ward(Bed No) </div>
            <div class="col"> {{patientInfo?.WM_WARD_NAME}} <span>({{patientInfo?.WM_BED_NO}})</span></div>
          </div>
        </div>

        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Gender/Age </div>
            <div class="col"> {{patientInfo?.GENDER}}/{{patientInfo?.AGE}} </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Admitting Doctor </div>
            <div class="col"> {{patientInfo?.DM_NAME}} </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Payee Name </div>
            <div class="col"> {{patientInfo?.PIM_NAME}} </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Referred By </div>
            <div class="col">{{patientInfo?.REFERRED_BY}} </div>
          </div>
        </div>
      </div>
    </fieldset>

  </div>
  <div class="col-12 col-lg-7 mt-3">
    <fieldset class="legend-block">
      <legend class="legend-title mb-0 font-16">Payment Selection</legend>
      <p>Please select the mode of payment to collect advance/Deposit for the patient:</p>
      <div class="mt-2" *ngFor="let paymode of paymentModes">
        <input (change)="selectedradioButton(paymode)" type="radio" [value]="paymode.id" name="payment" />
        <label class="paymentType pl-2" [for]="paymode.id">{{paymode.name}}</label>
        <input type="text" #searchInput [disabled]="!paymode.isSelected" [(ngModel)]="paymode.value" (input)="selectedInputValue(paymode)"
          class="ml-2 form-control d-inline-block w-25 form-control-sm" appOnlyNumberdecimal numericType="decimal2digits" />
      </div>
    </fieldset>
  </div>

  <div class="col-12 col-lg-5 mt-3">
    <fieldset class="legend-block">
      <legend class="legend-title mb-0 font-16">Transaction details</legend>
      <div class="form-group twoInputssameLine mx-2 mb-2">
        <label for="transactionId" class="text-dark mb-1">
          Transaction ID
          <span *ngIf="showErrorMessage" class="requiredSymbol">*</span>
        </label>
        <input type="text" #transactionID name="umrorUhidNo" [(ngModel)]="umrorUhidNo"
          class="form-control form-control-sm" id="transactionId" />
      </div>
      <div class="form-group twoInputssameLine mx-2 mb-2">
        <label for="bankName" class="text-dark mb-1">
          Bank Name
          <span *ngIf="showErrorMessage" class="requiredSymbol">*</span>
        </label>
        <input type="text" #bank_Name name="bankName" [(ngModel)]="bankName" class="form-control form-control-sm"
          id="bankName" />
      </div>
      <div class="form-group twoInputssameLine mx-2 mb-2">
        <label for="mobileNum" class="text-dark mb-1">
          Mobile
          <span *ngIf="showErrorMessage" class="requiredSymbol">*</span>
        </label>
        <input type="text" #mobileNum name="mobileNumber" [(ngModel)]="mobileNumber"
          class="form-control form-control-sm" maxlength="10" minlength="10" id="mobileNum" appOnlyNumberdecimal numericType="number" />
      </div>
    </fieldset>
  </div>
  <div class="col-12 mt-2 text-right">
    <button type="button" class="btn btn-primary mr-2" [disabled]="paymentModeType =='' || isSubmitted " (click)="submit()">
      Submit
    </button>
    <button type="button" class="btn btn-primary cancelBtn" (click)="closeModalPopup()">
      Cancel
    </button>
    <div *ngIf="showErrorMessage">
      <p style="color: red;text-align:center">Please fill required fields(Transaction Id, Bank Name and Mobile Number)
      </p>
    </div>
  </div>

</div>

<div id="print-prescription-section" class="print-form mt-5 border border-dark"
  *ngIf="printPrescription && ipReceiptDetailsList !== undefined">
  <div class="row no-gutters heading-block border-bottom border-dark">
    <div class="col-md-6 border-right border-dark">
      <div class="row">
        <div class="col-md-2 logo">
          <img src="./assets/hospital-logo.jpg" class="img-thumbnail" />
        </div>
        <div class="col-md-10">
          <h2 class="main-title mb-0 font-weight-bold" [innerHtml]="ipReceiptDetailsList?.HospitalName">
          </h2>
          <p>{{ipReceiptDetailsList?.HospitalAddress}}</p>
          <h3 class="sub-title1 mb-1 font-weight-bold">
            <u>{{ipReceiptDetailsList?.ReceiptName}}</u>
          </h3>
        </div>
      </div>
    </div>
    <div class="col-md-6 p-3">
      <div class="row">
        <div class="col-md-3">
          P. Name
        </div>
        <div class="col-md-9">
          <b>:</b> {{ipReceiptDetailsList?.PatientName}}
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          UMR No
        </div>
        <div class="col-md-3">
          <b>:</b> {{ipReceiptDetailsList?.UMRNo}}
        </div>
        <div class="col-md-3">
          Admn No
        </div>
        <div class="col-md-3">
          <b>:</b> {{ipReceiptDetailsList?.AdmissionNo}}
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          Recpt No
        </div>
        <div class="col-md-3">
          <b>:</b> {{ipReceiptDetailsList?.ReceiptNo}}
        </div>
        <div class="col-md-3">
          Recpt Dt
        </div>
        <div class="col-md-3">
          <b>:</b> {{ipReceiptDetailsList?.ReceiptDate}}
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          Age/Sex
        </div>
        <div class="col-md-3">
          <b>:</b> {{ipReceiptDetailsList?.AgeSex}}
        </div>
        <div class="col-md-3">
          Phone
        </div>
        <div class="col-md-3">
          <b>:</b> {{ipReceiptDetailsList?.Phone}}
        </div>
      </div>
    </div>
  </div>
  <div class="row-md-8 p-3">
    <div class="row">
      <div class="col-md-2">
        <span> S/O.D/O.W/O</span>
      </div>
      <div class="col-md-4">
        <b>:</b> {{ipReceiptDetailsList?.SWDBO}}
      </div>
      <div class="col-md-2">
        <span> Ward/Bed</span>
      </div>
      <div class="col-md-4">
        <b>:</b> {{ipReceiptDetailsList?.WardBed}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <span> Consultant</span>
      </div>
      <div class="col-md-4">
        <b>:</b> {{ipReceiptDetailsList?.Consultant}}
      </div>
      <div class="col-md-2">
        <span> Dept Name</span>
      </div>
      <div class="col-md-4">
        <b>:</b> {{ipReceiptDetailsList?.DeptName}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <span>Bill NO</span>
      </div>
      <div class="col-md-4">
        <b>:</b> {{ipReceiptDetailsList?.BillNo}}
      </div>
      <div class="col-md-2">
        <span>Receipt Amt</span>
      </div>
      <div class="col-md-4">
        <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <span>Advance NO</span>
      </div>
      <div class="col-md-4">
        <b>:</b> {{ipReceiptDetailsList?.AdvanceNo}}
      </div>
      <div class="col-md-2">
        <span *ngIf="!ipReceiptDetailsList?.WalletMode"> Cash Amt</span>
        <span *ngIf="ipReceiptDetailsList?.WalletMode"> Wallet Amt</span>
      </div>
      <div class="col-md-4">
        <b>:</b>{{ipReceiptDetailsList?.ReceiptAmt}}
      </div>
    </div>
    <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode">
      <div class="col-md-2">
        <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Dt</span>
        <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card Dt</span> Might be required in future-->
      </div>
      <div class="col-md-4">
        <b>:</b><span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span>
        <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span>
      </div>
      <div class="col-md-2">
        <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque NO</span>
        <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card NO</span> Might be required in future -->
      </div>
      <div class="col-md-4">
        <b>:</b>
        <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.TransactionId}}</span>
        <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ReceiptAmt}}</span>

      </div>
    </div>
    <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode || ipReceiptDetailsList?.CardMode">
      <div class="col-md-2">
        <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Bank</span>
        <span *ngIf="ipReceiptDetailsList?.CardMode">Card Bank</span>

      </div>
      <div class="col-md-4">
        <b>:</b> {{ipReceiptDetailsList?.BankName}}
      </div>
      <div class="col-md-2">
        <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Amt</span>
        <span *ngIf="ipReceiptDetailsList?.CardMode">Card Amt</span>


      </div>
      <div class="col-md-4">
        <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
      </div>
    </div>
    <div>
      <p class="pt-4">{{ipReceiptDetailsList?.ReceivedWithThanksFrom}}</p>
      <p>In Words &nbsp;&nbsp;&nbsp;{{ipReceiptAmountInWords}}</p>
    </div>
  </div>
  <footer class="p-3">
    <div class="row">
      <div class="col-md-2">
        Created By
      </div>
      <div class="col-md-4">
        <b>:</b> {{ipReceiptDetailsList?.CreateBy}}
      </div>
      <div class="col-md-2">
        Create Dt
      </div>
      <div class="col-md-4">
        <b>:</b> {{ipReceiptDetailsList?.CreateDt}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        Print By
      </div>
      <div class="col-md-4">
        <b>:</b> {{userInfo.username}}
      </div>
      <div class="col-md-2">
        Print Dt
      </div>
      <div class="col-md-4">
        <b>:</b> {{ipReceiptDetailsList?.PrintDt}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 text-align">***Cheques are subject to realisation</div>
      <div class="col-md-4 text-center">Email: health@lsch.in, www.lsch.in</div>
      <div class="col-md-4 text-right">(Authorised Signatory)
      </div>
    </div>
  </footer>

</div>