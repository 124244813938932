<div class="d-flex">
  <app-sidenav></app-sidenav>
  <div class="container-fluid total-page">

    <div class="row page-header">      
      <!-- <div class="col-1">
        <div class="info-count-box">
          <div class="text-center info-box-number info-box-primary-text mb-0">
            <i class="fa fa-calendar-check"></i>
          </div>
          <div class="text-center info-box-text">Calendar</div>
        </div>
      </div> -->
      
      <div class="col-md-9 text-center">
        <h4 class="text-uppercase bold font-weight-bold orange-color">List of Services Charged</h4>
      </div>
      <div class="col-md-3 text-center" style="line-height: 80px;">
        <button type="button" (click)="gotoNav()" class="btn border-btn mr-3 px-4">Bill Summary</button>
        <button type="button" (click)="navigate()" class="btn border-btn mr-3 px-4">Back to list</button>
      </div>

      <!-- <div class="col-1">
        <div class="info-count-box">
          <div class="text-center info-box-number info-box-primary-text mb-0">
            <span class="fa-stack fa-stack-adjust" data-notification-count="9">
              <i class="fa fa-bell"></i>
            </span>
          </div>
          <div class="text-center info-box-text">Todo</div>
        </div>
      </div> -->

    </div>

    <!-- Advanced search block -->
    <div class="row">

      <!-- Advance search -->
      <div class="col-md-12">
        <div class=" p-3 mx-3">
          <form [formGroup]="advSearchForm" (ngSubmit)="onSearch()" class="form-inline" autocomplete="off">
            <div class="row advance-search">
              <div class="col-sm-4 mb-3">
                <div class="form-group row no-gutters">
                  <label class="col-md-4 modname">Service Name</label>
                  <div class="col-md-8 px-0">
                    
                    <div>
                      <ng-autocomplete #serviceauto
                        [data]="servicesMaster"
                        [searchKeyword]="serviceNameFilterKeyword"
                        placeholder="Search service name"
                        (selected)='selectAutocompleteEvent($event)'
                        (inputChanged)='onChangeSearch($event)'
                        (inputFocused)='onFocused($event)'
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        formControlName="SID"
                        class="autocomplete-dropdown sm"
                      >
                      </ng-autocomplete>
                      <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.ServiceName"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>

                  </div>
                </div>
              </div>

              <div class="col-sm-4 mb-3">
                <div class="form-group row">
                  <label class="col-md-4 modname">Date</label>
                  <div class="col-md-8 px-0">
                    <input formControlName="SDATE" class="form-control input-textt1" placeholder="mm-dd-yyyy" type="date" style="padding: 0;" />
                  </div>
                </div>
              </div>

              <div class="col-sm-3 mb-3" style="text-align: center;">
                <button type="submit" class="btn btn-primary advanced-search-btn">Search</button>
                &nbsp;
                <button type="button" (click)="resetSearch()" class="btn btn-primary advanced-search-btn">Reset</button>
              </div>

            </div>
          </form>
        </div>
      </div>
      <!-- ./END Advance search -->

    </div>
    <!-- ./Advanced search block -->

    <div class="row mt-4" *ngIf="finalBillStatus == 'Y'">
      <div class="col-12 m-auto">
        <span class="holdservicealert">Final bill closed for this Patient</span>
      </div>
    </div>
    <!-- Patient Info -->
    <div class="row mt-1">
      <div class="col-10 m-auto">
        <app-patient-info [ipPatientNO]="ipPatientNO" [patientURMNo]="patientURMNo">                    
        </app-patient-info>
      </div>
    </div>
    <!-- ./END Patient Info -->

    <div class="row justify-content-center mb-2">
      <div class="col-12">

        <div class="matcontent">
          <div class="row">
              <div class="col-md-10"></div>
              <div class="col-md-2">
                <label style="line-height: 30px; color: #343887;">
                  <span class="colorlegend orange-bg-color"></span>
                  Canceled                  
                </label>
              </div>
          </div>
          <mat-table #table [dataSource]="patientServicesData" matSort class="example-table mat-elevation-z8 inner-scroll">
    
            <ng-container matColumnDef="SNO">
              <mat-header-cell class="centre" *matHeaderCellDef mat-sort-header >
                <div>SNO</div>
              </mat-header-cell>
              <mat-cell class="centre" mat-cell *matCellDef="let element; let i = index;" >
                <div>{{ i+1 }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="SERVICE_BILLED_DATE">
              <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                <div>Service Charge Date</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" >
                <div>{{ element.SERVICE_BILLED_DATE }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CREATED_BY">
              <mat-header-cell *matHeaderCellDef  mat-sort-header>
                <div>Created By</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" >
                <div>{{ element.CREATED_BY }}</div>
              </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="SERVICE_NAME">
              <mat-header-cell *matHeaderCellDef  mat-sort-header>
                <div>Service Name</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" >
                <div>{{ element.SERVICE_NAME }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="SERVICE_TYPE_NAME">
              <mat-header-cell *matHeaderCellDef  mat-sort-header>
                <div>Service Type</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" >
                <div>{{ element.SERVICE_TYPE_NAME }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="TARIFF">
              <mat-header-cell *matHeaderCellDef  mat-sort-header>
                <div>Tariff</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" >
                <div style="padding: 8px 8px;">
                  <select class="form-control input-sm" [(ngModel)]="element.TTM_ID" (change)="onChangeTariff(element, $event)">
                    <option value="0">Select</option>
                    <!-- <ng-container *ngFor="let tariff of tariffList;">
                      <option value="{{tariff.TTM_ID}}">{{ tariff.TARIFF_TYPE_NAME }}</option>
                    </ng-container> -->
                    <option *ngFor="let tariff of tariffList" value="{{ tariff.TTM_ID }}">
                      {{tariff.TARIFF_TYPE_NAME}}
                    </option>                 
                  </select>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="SERVICE_UNIT_QTY">
              <mat-header-cell *matHeaderCellDef class="centre" mat-sort-header>
                <span>Unit</span>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="centre">
                
                <div *ngIf="element.VARIABLE_UNIT_QTY == '0'">{{element?.SERVICE_UNIT_QTY}}</div>
                
                <div *ngIf="element.VARIABLE_UNIT_QTY == '1'">
                  
                  <div *ngIf="element.BILL_PROCESSED == 'Y'">{{element?.SERVICE_UNIT_QTY}}</div>
                  
                  <div *ngIf="element.BILL_PROCESSED == 'N'" class="editable">
                    <input type="text" class="form-control form-control-sm" [value]="element?.SERVICE_UNIT_QTY" (keyup)="changeUnitQTY($event, element)" (blur)="blurUnitQTY($event, element)" appOnlyNumberdecimal numericType="number" />
                  </div>                  

                </div>
                <!-- <input type="text" class="form-control form-control-sm" [value]="element?.SERVICE_UNIT_QTY" (keyup)="changeUnitQTY($event, element)" (blur)="blurUnitQTY($event, element)" appOnlyNumberdecimal numericType="number" /> -->

              </mat-cell>
            </ng-container>            

            <ng-container matColumnDef="SERVICE_AMOUNT">
              <mat-header-cell *matHeaderCellDef class="centre" mat-sort-header>
                <div>Cost per Unit</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="centre">
                <div *ngIf="element.BILL_PROCESSED == 'N' && element.SERVICE_PRICE_VARIABLE == '0'">{{element?.SERVICE_AMOUNT}}</div>
                <div *ngIf="element.BILL_PROCESSED == 'Y' && (element.SERVICE_PRICE_VARIABLE == '0' || element.SERVICE_PRICE_VARIABLE == '1') ">{{element?.SERVICE_AMOUNT}}</div>
                
                <div *ngIf="element.BILL_PROCESSED == 'N' && element.SERVICE_PRICE_VARIABLE == '1'" class="editable" >
                  <input type="text" class="form-control form-control-sm" [value]="element.SERVICE_AMOUNT" (keyup)="changeCostPerUnit($event, element)" (blur)="blurCostPerUnit($event, element)" appOnlyNumberdecimal numericType="decimal2digits" />
                </div>
                <!-- <input type="text" class="form-control form-control-sm" [value]="element.SERVICE_AMOUNT" (keyup)="changeCostPerUnit($event, element)" (blur)="blurCostPerUnit($event, element)" appOnlyNumberdecimal numericType="decimal2digits" /> -->
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="SERVICE_DISCOUNT_AMOUNT">
              <mat-header-cell *matHeaderCellDef class="centre" mat-sort-header>
                <div>Discount<br/>(%)</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="centre">

                <div class="discount" *ngIf="!element.DISCOUNT_STATUS">
                  <div *ngIf="element.BILL_PROCESSED == 'Y'">{{element?.SERVICE_DISCOUNT_AMOUNT}}</div>
                  <div *ngIf="element.BILL_PROCESSED == 'N'" class="editable">
                    <input type="text" class="form-control form-control-sm" [value]="element.SERVICE_DISCOUNT_AMOUNT" (keyup)="changeDiscountAmount($event, element)" (blur)="blurDiscountAmount($event, element)" appOnlyNumberdecimal numericType="decimal2digits" />
                  </div>
                </div>

                <div class="discountper" *ngIf="!element.DISCOUNT_STATUS">
                  <div *ngIf="element.BILL_PROCESSED == 'Y'">({{element.SERVICE_DISCOUNT_PERCENTAGE}}) &nbsp;</div> 
                  <div *ngIf="element.BILL_PROCESSED == 'N'" class="editable" >
                    <input type="text" class="form-control form-control-sm" [value]="element.SERVICE_DISCOUNT_PERCENTAGE" (keyup)="changeDiscount($event, element)" (blur)="blurDiscount($event, element)" appOnlyNumberdecimal numericType="number" />
                  </div>
                </div>

                <div class="discount" *ngIf="element.DISCOUNT_STATUS">
                  <div>{{element?.SERVICE_DISCOUNT_AMOUNT}}</div>
                </div>
                <div class="discountper" *ngIf="element.DISCOUNT_STATUS">
                  <div>({{element.SERVICE_DISCOUNT_PERCENTAGE}}) &nbsp;</div> 
                </div>
                
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="SERVICE_NET_AMOUNT">
              <mat-header-cell *matHeaderCellDef  mat-sort-header>
                <div>Net Amount</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="centre">
                <span>{{ element.SERVICE_NET_AMOUNT }}</span>
              </mat-cell>
            </ng-container>                       
            
            <ng-container matColumnDef="ADDACTION">
              <mat-header-cell *matHeaderCellDef>
                <div>Add</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="centre">
                <span>
                  <button [disabled]="finalBillStatus == 'Y'" (click)="confirmDialog(element, 'addInline')" 
                          *ngIf="element.BILL_PROCESSED == 'N' && rolesInfo?.attributeLevel?.ward_bed_management_service_charged_add_service" 
                          type="button" 
                          title="Add" 
                          class="btn btn-primary btn-sm mx-1 font-12"><i class="fa fa-plus"></i></button>
                  <button [disabled]="finalBillStatus == 'Y'" (click)="confirmDialog(element, 'undoAddInline')" 
                          *ngIf="element.BILL_PROCESSED == 'Y'" 
                          type="button" 
                          title="Undo Add" 
                          class="btn btn-primary btn-sm mx-1 font-12"><i class="fa fa-undo"></i></button>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CANCELACTION">
              <mat-header-cell *matHeaderCellDef>
                <div>Cancel</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="centre">
                <span>
                  <button [disabled]="finalBillStatus == 'Y'" (click)="cancelPopup(closePopupBlock, element, 'Cancel')" 
                          *ngIf="element.RESULT_PROCESSED == 'N' && element.SBI_STATUS == 'N'  && rolesInfo?.attributeLevel?.ward_bed_management_service_charged_cancel_service" 
                          type="button" 
                          title="Cancel" 
                          class="btn btn-primary btn-sm mx-1 font-12"><i class="fa fa-times"></i></button>
                  <button [disabled]="finalBillStatus == 'Y'" (click)="confirmDialog(element, 'undoCancelService')" 
                          *ngIf="element.RESULT_PROCESSED == 'N' && element.SBI_STATUS == 'CANCELLED'" 
                          type="button" 
                          title="Undo Cancel - {{ element.SBI_REMARKS }} " 
                          class="btn btn-primary btn-sm"><i class="fa fa-undo"></i></button>
                </span>
              </mat-cell>
            </ng-container>
    
            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let element; columns: columnsToDisplay" [ngClass]="{'orange-bg-color': (element.SBI_STATUS == 'CANCELLED') }"></mat-row>
          </mat-table>

          <div class="mat-norecords" *ngIf="patientServicesData.data.length === 0">No records found</div>
    
          <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons style="border-bottom-left-radius:6px; border-bottom-right-radius:6px" class="mat-paginator-sticky">
          </mat-paginator>
        </div>

        <!-- <div class="table-responsive">
          <table class="table blue-table">
            <thead>
              <tr class="trhover">
                <th>S.no</th>
                <th>Service Charge Date</th>
                <th>Service Name</th>
                <th>Unit</th>
                <th>Service Type</th>
                <th>Cost per Unit</th>
                <th>Discount</th>
                <th style="width: 55px;">&nbsp;&nbsp;&nbsp;&nbsp; (%) &nbsp;&nbsp;&nbsp;&nbsp;</th>
                <th>Net Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="11" *ngIf="patientServiceList.length === 0">
                  <div>No Recordes</div>
                </td>
              </tr>
              <tr class="trhover" *ngFor="let patient of patientServiceList; let i = index">
                <td>
                  <div>{{i+1}}</div>
                </td>
                <td>
                  <div>{{patient?.SERVICE_BILLED_DATE}}</div>
                </td>
                <td>
                  <div>{{patient?.SERVICE_NAME}}</div>
                </td>
                <td>
                  <div>{{patient?.SERVICE_UNIT_QTY}}</div>
                </td>
                <td>
                  <div>{{patient?.SERVICE_TYPE_NAME}}</div>
                </td>
                <td>
                  <div *ngIf="patient.BILL_PROCESSED == 'Y'">{{patient?.SERVICE_AMOUNT}}</div>
                  <div class="editable" (blur)="calculateNet1($event, patient)" (change)="calculateNet1($event, patient)" (keyup)="calculateNet1($event, patient)" *ngIf="patient.BILL_PROCESSED == 'N'"><input type="number" step="0.25" min="0" class="form-control form-control-sm" [(ngModel)]="patient.SERVICE_AMOUNT" /></div>
                </td>
                <td>
                  <div *ngIf="patient.BILL_PROCESSED == 'Y'">{{patient?.SERVICE_DISCOUNT_AMOUNT}}</div>
                  <div class="editable" (blur)="calculateNet1($event, patient)" (change)="calculateNet1($event, patient)" (keyup)="calculateNet1($event, patient)" *ngIf="patient.BILL_PROCESSED == 'N'"><input type="number" step="0.25" min="0" [max]="patient.SERVICE_AMOUNT" class="form-control form-control-sm" [(ngModel)]="patient.SERVICE_DISCOUNT_AMOUNT" /></div>
                </td>
                <td>
                  <div *ngIf="patient.BILL_PROCESSED == 'Y'">{{patient.SERVICE_DISCOUNT_PERCENTAGE}} &nbsp;</div>
                  <div class="editable" (blur)="calculateNet2($event, patient)" (change)="calculateNet2($event, patient)" (keyup)="calculateNet2($event, patient)" *ngIf="patient.BILL_PROCESSED == 'N'"><input type="number" step="0.01" min="0" max="100" class="form-control form-control-sm" [(ngModel)]="patient.SERVICE_DISCOUNT_PERCENTAGE" /></div>
                </td>
                <td>
                  <div>{{patient?.SERVICE_NET_AMOUNT}}</div>
                </td>
                <td>
                  <div>
                    <button [disabled]="holdServiceStatus == 'Y'" (click)="confirmDialog(patient, 'addInline')" 
                            *ngIf="patient.BILL_PROCESSED == 'N'" 
                            type="button" 
                            class="btn btn-primary btn-sm mx-1 font-12">Add</button>
                    <button [disabled]="holdServiceStatus == 'Y'" (click)="confirmDialog(patient, 'undoAddInline')" 
                            *ngIf="patient.BILL_PROCESSED == 'Y'" 
                            type="button" 
                            class="btn btn-primary btn-sm mx-1 font-12">Undo</button>
                    <button [disabled]="holdServiceStatus == 'Y'" (click)="cancelPopup(closePopupBlock, patient, 'Cancel')" 
                            *ngIf="patient.RESULT_PROCESSED == 'N' && patient.SBI_STATUS == 'N'" 
                            type="button" 
                            class="btn btn-primary btn-sm mx-1 font-12">Cancel</button>
                    <button [disabled]="holdServiceStatus == 'Y'" (click)="confirmDialog(patient, 'undoCancelService')" 
                            *ngIf="patient.RESULT_PROCESSED == 'N' && patient.SBI_STATUS == 'CANCELLED'" 
                            type="button" 
                            class="btn btn-primary btn-sm">Undo</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->

      </div>
    </div>
  </div>

  <ng-template #closePopupBlock let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Cancel</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <textarea class="form-control" [(ngModel)]="sericePopupInfo.patient.SBI_REMARKS"></textarea>
      </div>
      <div class="form-group">
        <button type="button" (click)="cancelService()" [disabled]="sericePopupInfo.patient.SBI_REMARKS==''" class="btn btn-primary btn-sm mr-1">Save</button>
        <button type="button" (click)="cancelServiceClose()" class="btn btn-primary btn-sm mr-1">Close</button>
      </div>
    </div>
  </ng-template>

</div>