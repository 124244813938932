import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WardService } from 'src/app/ward-management/ward.service';
import { TokenStorageService } from '../loginservices/token-storage.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-update-external',
  templateUrl: './update-external.component.html',
  styleUrls: ['./update-external.component.css']
})
export class UpdateExternalComponent implements OnInit {

  @Input() ipPatientNO: number;
  //@ViewChild() editable: boolean;

  ExtReferalForm : FormGroup;
  
  public externalReferralForm = {
    referalId: '',
    referalName: '',
    refrlName: '',
    remarks: '',
    area: '',
    mobileNo: ''
  }
  extRefList: any = [];
  searchNameKeyword = 'referralName';
  externalReferralErrorMessage: boolean = false;
  referralModel: any = [];
  referralNames: any = [];
  userInfo: any;
  updatedInfo: any = {};
  extReferralId: any = "";
  extReferralName: any = "";
  extRefrl: boolean = false;  
  errorInfo: any;
  editable: boolean = false;
  isSelected: number = 0;
  dataLogList: any = [];
  submitbtn: boolean = false;

  constructor ( 
    private tokenService: TokenStorageService,
    private wardService: WardService,
    private toastrService: ToastrService,
    private router: Router,
    private modalService: NgbModal)
    { 
      this.userInfo = this.tokenService.getUser();
    }

  ngOnInit(): void {
    this.ExtReferalForm = new FormGroup({          
      remarks: new FormControl(''),
      externalReferal: new FormControl(''),
      refrlName: new FormControl(''),
      area: new FormControl(''),
      mobileNo: new FormControl('',[Validators.maxLength(10)])  
    });
    this.getDataLogList();
  }
  get f(): any { return this.ExtReferalForm.controls; }

  ngOnChanges(): void{
  }

  onChangeSearch(val: string): void {
    
    if (val.length < 3) { }
    else {
      this.gettingAllDoctorsData(val);
      this.extRefList.filter(option => option.doctorName.toLowerCase().includes(val));
    }
 }

  gettingAllDoctorsData(data): any{

    const input = {
      referalName: data
    }
    this.wardService.getExternalReferralsList(input).subscribe(list => {

      this.referralModel = [];
      this.extRefList = list;

     if (this.extRefList.length != 0) {

      this.extRefList.forEach(referral => {
          this.referralModel.push(
            {referralName: referral.doctorName, 
            referralId: referral.ReferralId,
            referralArea: referral.areaTownCity,
            referralPhNo: referral.contactNo
          });
        });
        
        this.referralNames = this.referralModel;
        //console.log(this.referralNames);
      }       
    });
  }

   selectAutocompleteEvent(item: any, field: string): void {
    console.log(item);
    if (field === 'referralname') {
      this.externalReferralForm.referalId = item.referralId;      
      this.extRefrl = true;
      this.submitbtn = false;      
    }
    else{
      this.extRefrl = false;
      this.submitbtn = true;
    }
      this.extReferralId = item.referralId;
      this.extReferralName = item.referralName;
      this.externalReferralForm.area = item.referralArea;
      this.externalReferralForm.mobileNo = item.referralPhNo;
      this.externalReferralForm.refrlName = item.referralName;        
  }
 
  onRemoveSelected(item: any, field: string): void {
    
    if(field === 'referralname') {
      this.externalReferralForm.referalId = '';
      this.extRefrl = false;
      this.submitbtn= true;
      this.externalReferralForm.area = '';
      this.externalReferralForm.mobileNo = '';
      this.externalReferralForm.refrlName = '';
    }
   }

  onFocused(e): void {
   // do something when input is focused
  }

  editReferralData(event){
    
    if(event){
      this.isSelected = 1;
      this.editable = true;
    }else{
      this.isSelected = 0;
    }
  }

  updateExternalForm(form){

    const input = {
    "sear_ip_no": this.ipPatientNO,
    "referralID": this.extReferralId,
    "referalName": form.value.refrlName,
    "referalArea": form.value.area,
    "referalMobileNo": form.value.mobileNo,
    "createName":this.userInfo.username,
    "remarks": form.value.remarks,
    "externalRecordCondition":this.isSelected
    }
    this.wardService.updateExternalReferral(input).subscribe(
      (data) =>{ 
      this.updatedInfo = data;
      this.errorInfo = this.updatedInfo.message;
      console.log(this.errorInfo);
      this.toastrService.success('External Referral updated successfully');
      //this.closePopup();      
      this.getDataLogList();
      this.setValuesEmpty();
      this.submitbtn= true;
    },(err)=>{
      this.toastrService.error('Update failed');
      this.closePopup();
    });
  }

  closePopup(){
    this.modalService.dismissAll();
  }

  getDataLogList(){
    const input = {
      "IpNo": this.ipPatientNO
    }
    this.wardService.getPatientDataLogList(input).subscribe((data)=>{
      
        this.dataLogList = data;
      });
  }

  setValuesEmpty(){
    this.ExtReferalForm.reset();
    this.externalReferralForm = {
      referalId: '',
      referalName: '',
      refrlName: '',
      remarks: '',
      area: '',
      mobileNo: ''
    }
  }

}
