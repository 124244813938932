import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { RadiologyService } from '../radiology.service';
import { Editor, Validators } from 'ngx-editor';
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import {
  insertOpResultEntry,
  insertIpResultEntry,
  updateOpResultEntry,
  updateIpResultEntry,
  GetResultEntry,
  getReportedDoctorsList,
  GET_RADIOLOGY_TEMPLATE,
  GET_TEMPLATES_LIST
} from '../../radiology-management/radiologyConstants';
import { NgxPrinterService } from 'ngx-printer';

@Component({
  selector: 'app-radiology-summary',
  templateUrl: './radiology-summary.component.html',
  styleUrls: ['./radiology-summary.component.css']
})

export class RadiologySummaryComponent implements OnInit {

  @Output() closePopup = new EventEmitter();

  editor: Editor;
  html: '';
  listTitle = 'Report Information'
  public selectedPatientInfo: any = {};
  public userInfo: any = [];
  public patientInfo: any = [];
  public patientType: any = [];
  public isRadiologySummaryExist: boolean = false;
  public radiolodySummaryInsertionUrl: any = "";
  public radiolodySummaryupdationUrl: any = "";
  public existingRecord: boolean = false;
  public showErrorMessage: boolean = false;
  public dataAdded : boolean = false;
  public reportingDoctorsList:any = [];
  public isPrintActive = false;
  public existingData:any = ''
  public templatesList:any = ''
  form: FormGroup;
  submitted = false;
  constructor(private router: Router,
    private tokenService: TokenStorageService,
    private dataService: RadiologyService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private inpatientService: InpatientService,
    private printerService: NgxPrinterService
  ) {
    this.userInfo = this.tokenService.getUser();
    this.selectedPatientInfo = this.dataService.patientInfo;
    this.patientType = this.dataService.patientType;
  }

  ngOnInit(): void {
    this.editor = new Editor();
    this.form = new FormGroup({
      editorContent: new FormControl("", Validators.required()),
      doctorName: new FormControl("", Validators.required()),
      doctorDesignation: new FormControl("", Validators.required()),
      templateName: new FormControl("")
    });
    this.getData();
    if(this.selectedPatientInfo.resultId !== ''){
      this.getRadiologyEntryResults();
    }

    this.getReportingDoctorsList()

    this.getTemplatesList()
  }
  getSummary(data) {
    const value = this.templatesList.filter(
      type => type.TEMPLATE_NAME === data.target.value);
      this.form.patchValue({
        editorContent: value[0].REPORT_TEMPLATE
      })
  }
  getTemplatesList()
  {
    const inputRequest = {
      serviceName: '',
      templateName: '',
      disabled: 'true',
      hospitalId: this.userInfo.hospitalID
    }
    this.dataService.getTemplatesList(inputRequest, GET_TEMPLATES_LIST)
      .subscribe(
        (response) => {
          this.templatesList = response.data
          console.log("teplatest list", response)
        },
        (err) => {
          console.log("Error - ", err)
        }
        )
  }

  getReportingDoctorsList()
  {
    let inputReq = {
      departmentType: 'RADIOLOGY'
    }
    this.dataService.getReportingDoctorsList(getReportedDoctorsList, inputReq)
      .subscribe(
        (response) => {
          this.reportingDoctorsList = response
        },
        (error) => {

        }
        )
  }

  getRadiologyEntryResults() {
    let inputReq;
    if (this.patientType == "OP") {
      inputReq = {
        resultId: this.selectedPatientInfo.resultId,
        requestType: 'opResults',
      }
    } else {
      inputReq = {
        resultId: this.selectedPatientInfo.resultId,
        requestType: 'ipResults',
      }
    }
    
    if(this.selectedPatientInfo.resultId === '' || this.selectedPatientInfo.resultId === null)
    {
      // console.log("selectedPatientInfo", this.selectedPatientInfo)
      let inputRequest = {
        serviceName:this.selectedPatientInfo.serviceName,
      }

      this.dataService.getRadiologyTemplate(GET_RADIOLOGY_TEMPLATE, inputRequest)
        .subscribe(response => {
          this.form.patchValue({
            editorContent: response.REPORT_TEMPLATE
          })
        })
    }
    else{
      this.dataService.getLabServiceResultEntry(GetResultEntry, inputReq)
        .subscribe(response => {
          this.existingRecord = true;
          this.form.patchValue({
            editorContent: response.resultData,
            doctorName: response.reportingDoctorName,
            doctorDesignation: response.reportingDoctorDesignation
          })
          this.existingData = response
        })
    }
  }
  getData() {
    if(this.patientType == 'OP'){
      this.patientInfo = this.selectedPatientInfo;
    } else{
      const inutReq = {
        sear_umr_no: this.selectedPatientInfo.umrNo,
        sear_ip_no: this.selectedPatientInfo.ipNr,
        sear_hospitalid: this.userInfo.hospitalID,
      };
      this.inpatientService.patientInfo(inutReq).subscribe(
        (response) => {
          this.patientInfo = response;
      });
    }
  }
  get f() { return this.form.controls; }
  submitData(form) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      if(this.form.controls.editorContent.invalid){
        this.showErrorMessage = true;
      }
        return;
    } else {
      let inputReq
      if (this.existingRecord) {
        this.updateRadiologySumary(form)
      }
      else {
        if (this.patientType == "OP") {
          inputReq = {
            visitId: this.selectedPatientInfo.visitId,
            sequenceNo: this.selectedPatientInfo.serviceSequenceNo == null?'':this.selectedPatientInfo.serviceSequenceNo,
            paymentId: this.selectedPatientInfo.paymentId,
            resultData: form.value.editorContent,
            updatedBy: this.userInfo.username,
            reportingDoctorName: form.value.doctorName,
            reportingDoctorDesignation: form.value.doctorDesignation
          }
        } else {
          inputReq = {
            ipNr: this.selectedPatientInfo.ipNr,
            sequenceNo: this.selectedPatientInfo.serviceSequenceNo ==null?'':this.selectedPatientInfo.serviceSequenceNo,
            paymentId: this.selectedPatientInfo.paymentId,
            resultData: form.value.editorContent,
            updatedBy: this.userInfo.username,
            reportingDoctorName: form.value.doctorName,
            reportingDoctorDesignation: form.value.doctorDesignation
          }
        }
        this.radiolodySummaryInsertionUrl = (this.patientType == "OP") ? insertOpResultEntry : insertIpResultEntry;

        this.dataService.getLabServiceResultEntry(this.radiolodySummaryInsertionUrl, inputReq)
          .subscribe(response => {
              this.closePopup.emit(true);
              this.toastr.success('Result summary details submited successfully..!');
              this.form.patchValue({
                editorContent: response.resultData ,
               doctorName: response.reportingDoctorName,
                doctorDesignation: response.reportingDoctorDesignation
              })
              this.getRadiologyEntryResults()
            },
            (err) => {
              this.toastr.error('Something went wrong!');
              console.log('HTTP Error', err);
            }
          )
      }
    }
  }
  updateRadiologySumary(form) {
    let inputReq = {
        resultId: this.selectedPatientInfo.resultId,
        sequenceNo: this.selectedPatientInfo.serviceSequenceNo ===null?'':this.selectedPatientInfo.serviceSequenceNo,
        paymentId: this.selectedPatientInfo.paymentId,
        resultData: form.value.editorContent,
        updatedBy: this.userInfo.username,
        reportingDoctorName: form.value.doctorName,
        reportingDoctorDesignation: form.value.doctorDesignation
    }
    this.radiolodySummaryupdationUrl = (this.patientType == "OP") ? updateOpResultEntry : updateIpResultEntry;

    this.dataService.getLabServiceResultEntry(this.radiolodySummaryupdationUrl, inputReq)
      .subscribe(response => {
        this.toastr.success('Result summary details updated successfully..!');
        this.form.patchValue({
          editorContent: response.resultData,
          doctorName: response.reportingDoctorName,
          doctorDesignation: response.reportingDoctorDesignation
        })
        this.getRadiologyEntryResults()
       
      },
      (err) => {
        this.toastr.error('Something went wrong!');
        console.log('HTTP Error', err);
      }
    )
  }
  get doc(): AbstractControl {
    return this.form.get("editorContent").value;
  }
 
  backNav() {
    this.router.navigate(['/radiology']);
  }

  resultEntryPrint()
  {
    const htmlele = document.getElementById('print-result-entry-section');
    htmlele.style.display = 'block';
    // this.isPrintActive = true
    this.printerService.printDiv('print-result-entry-section');
    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000);
  }
}

