import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Type, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InpatientService } from '../inpatient.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';

import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';

import { ConfirmDialogModule } from '../../shared/confirm-dialog/confirm-dialog.module';
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
interface IsericePopupInfo {
  title: string | null;
  patient: any | null;
}
@Component({
  selector: 'app-service-charged',
  templateUrl: './service-charged.component.html',
  styleUrls: ['./service-charged.component.css']
})
export class ServiceChargedComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('serviceauto') serviceauto;

  columnsToDisplay = ['SNO', 'SERVICE_BILLED_DATE', 'CREATED_BY', 'SERVICE_NAME', 'SERVICE_TYPE_NAME', 'TARIFF', 'SERVICE_UNIT_QTY', 'SERVICE_AMOUNT', 'SERVICE_DISCOUNT_AMOUNT', 'SERVICE_NET_AMOUNT', 'ADDACTION', 'CANCELACTION'];
  // 'SERVICE_DISCOUNT_AMOUNT'

  patientServiceList: any = [];
  patientServicesData = new MatTableDataSource(this.patientServiceList);

  public patientInfo: any;
  public ipPatientNO = 0;
  public userInfo: any = {};

  public tariffList: any;
  public servicesMaster: any;
  serviceNameFilterKeyword = 'ServiceName';

  patientURMNo: number;

  sericePopupInfo: IsericePopupInfo = {
    title: null,
    patient: null
  };

  holdServiceStatus = 'N';
  finalBillStatus = 'N';

  public rolesInfo: any = [];

  /* advanced search */
  advSearchForm: FormGroup;
  Control: AbstractControl;
  advancedSearch: any = {};

  returnMode = 'iplist';

  constructor(private inpatientService: InpatientService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: NgbModal,
              private toastrService: ToastrService,
              private tokenService: TokenStorageService,
              private formBuilder: FormBuilder,
              private confirmDialogService: ConfirmDialogService) {

    if (this.tokenService.getToken()) {
      this.rolesInfo = this.tokenService.getUser().rolesInfo;
    }

    this.userInfo = this.tokenService.getUser();

    const id = this.route.snapshot.paramMap.get('id');
    // tslint:disable-next-line:radix
    this.ipPatientNO = parseInt(id);

    this.route.queryParams.subscribe(params => {

      const urmNo = params.umrNumber;
      // tslint:disable-next-line:radix
      this.patientURMNo = parseInt(urmNo);

      this.returnMode = params.mode;

    });

  }

  ngOnInit(): void {

    this.getTariffs();
    this.getServicesByIPNO();

    this.advSearchForm = this.formBuilder.group({
      SID: [''],
      SDATE: ['']
    });

  }

  ngAfterViewInit(): void {

    this.getPatientInfo();
    this.getPatientServiceList();
    this.holdServiceBillingStatus();

  }

  /**
   * get patient info
   */
  getPatientInfo(): void {

    const search = {
      sear_umr_no: this.patientURMNo,
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID
    };

    this.inpatientService.patientInfo(search).subscribe((response) => {
      this.patientInfo = response;
    });

  }

  getTariffs(): void {

    const search = {
      IpNr: this.ipPatientNO,
      HospitalID: this.userInfo.hospitalID
    };

    this.inpatientService.getTariffs(search).subscribe((response) => {
      this.tariffList = response;
      console.log(this.tariffList);
    });

  }

  getServicesByIPNO(): void {

    const search = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID
    };

    this.inpatientService.getServicesByIPNO(search).subscribe((response) => {
      this.servicesMaster = response;
      console.log(this.servicesMaster);
    });

  }

  onSearch(): void {
    this.getPatientServiceList();
  }

  resetSearch(): void {
    this.advSearchForm.setValue({
      SID: '',
      SDATE: '',
    });
    this.serviceauto.clear();
    this.getPatientServiceList();
  }

  /**
   * get patient service list
   */
  getPatientServiceList(): void {

    const search = {
      start: '0',
      limit: '10',
      sear_ip_no: this.ipPatientNO,
      sear_service_id: (this.advSearchForm.controls.SID.value.ServiceId) ? this.advSearchForm.controls.SID.value.ServiceId : '',
      sear_service_date: this.advSearchForm.controls.SDATE.value
    };

    this.inpatientService.patientServiceList(search).subscribe((response) => {
      this.patientServiceList = response;

      this.patientServiceList.forEach(data => {

        data.SERVICE_DISCOUNT_PERCENTAGE = this.calculatePercentage(data);
        data.SBI_REMARKS = '';

      });

      this.patientServicesData = new MatTableDataSource(this.patientServiceList);
      this.patientServicesData.sort = this.sort;
      this.patientServicesData.paginator = this.paginator;

    });

  }

  holdServiceBillingStatus(): void {

    const search = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID
    };

    this.inpatientService.holdServiceBillingStatus(search).subscribe((response) => {
      this.holdServiceStatus = response.HoldServiceStatus;
      this.finalBillStatus = response.FinalBillStatus;
    });

  }

  onChangeTariff(element, event): void {
    // console.log(event.target.value);

    if (event.target.value !== '0') {

      const search = {
        HospitalID: this.userInfo.hospitalID,
        IpNr: this.ipPatientNO,
        SMID: element.SM_ID,
        IP_SBI_ID: element.IP_SBI_ID,
        TTM_ID: event.target.value
      };

      this.inpatientService.patientServiceChargedrowUpdate(search).subscribe((response) => {
        // this.holdServiceStatus = response.HoldServiceStatus;
        // this.finalBillStatus = response.FinalBillStatus;
        element.SERVICE_AMOUNT = response.ServiceAmount;

        this.calculateNet0(element, 'TARIFF');

        this.toastrService.success('Tariff changed successfully');
      });

    }

  }

  calculatePercentage(data: any): any {

    const grandTotal =  Number(parseFloat(data.SERVICE_AMOUNT).toFixed(2));
    const discountAmount = Number(parseFloat(data.SERVICE_DISCOUNT_AMOUNT).toFixed(2));

    let netAmount = grandTotal - discountAmount;
    netAmount = Number(netAmount.toFixed(2));

    let discountAmountPercenttage = ((grandTotal - netAmount) / grandTotal) * 100;
    discountAmountPercenttage = Number(discountAmountPercenttage.toFixed(2));

    return discountAmountPercenttage;

  }

  cancelPopup(closePopupBlock, patient, serviceName): void {
    this.sericePopupInfo.title = serviceName;
    this.sericePopupInfo.patient = patient;
    this.modalService.open(closePopupBlock, { size: 'md', backdrop: 'static' });
  }

  cancelService(): void {

    const SBI_REMARKS = this.sericePopupInfo.patient.SBI_REMARKS;
    const SBI_STATUS = 'CANCELLED';

    const patient = this.patientServiceList.find(e => e.IP_SBI_ID === this.sericePopupInfo.patient.IP_SBI_ID);
    console.log(patient);
    if (patient) {
      patient.SBI_REMARKS = SBI_REMARKS;
      patient.SBI_STATUS = SBI_STATUS;

      this.cancel(patient);
    }

    this.sericePopupInfo.patient = null;
    this.modalService.dismissAll();
  }

  cancelServiceClose(): void {
    this.modalService.dismissAll();
  }

  undoCancelService(patient): void {

    patient.SBI_REMARKS = '';
    patient.SBI_STATUS = 'N';

    this.undoCancel(patient);

  }

  cancel(patient): void {

    patient.BILL_PROCESSED = 'N';

    const search = {
      sear_ip_no: patient.IP_NR,
      ser_service_id: patient.IP_SBI_ID,
      bill_processed: patient.BILL_PROCESSED,
      result_processed: patient.RESULT_PROCESSED,
      request_accepted: patient.REQUEST_ACCEPTED,
      cost_per_unit: patient.SERVICE_AMOUNT,
      discount_amt: patient.SERVICE_DISCOUNT_AMOUNT,
      discount_percent: patient.SERVICE_DISCOUNT_PERCENTAGE,
      net_amt: patient.SERVICE_NET_AMOUNT,
      cancel_btn: '1',
      cancel_remarks: patient.SBI_REMARKS,
      undo_cancel_btn: '',
      add_btn: '',
      undo_add_btn: ''
    };

    this.inpatientService.patientServiceChargedEdit(search).subscribe((response) => {

      if (response) {
        this.reassignServiceRow(patient, response);
      }
      this.toastrService.success('Saved');

    });

  }

  undoCancel(patient): void {

    patient.BILL_PROCESSED = 'Y';

    const search = {
      sear_ip_no: patient.IP_NR,
      ser_service_id: patient.IP_SBI_ID,
      bill_processed: patient.BILL_PROCESSED,
      result_processed: patient.RESULT_PROCESSED,
      request_accepted: patient.REQUEST_ACCEPTED,
      cost_per_unit: patient.SERVICE_AMOUNT,
      discount_amt: patient.SERVICE_DISCOUNT_AMOUNT,
      discount_percent: patient.SERVICE_DISCOUNT_PERCENTAGE,
      net_amt: patient.SERVICE_NET_AMOUNT,
      cancel_btn: '',
      cancel_remarks: '',
      undo_cancel_btn: '1',
      add_btn: '',
      undo_add_btn: ''
    };

    this.inpatientService.patientServiceChargedEdit(search).subscribe((response) => {

      if (response) {
        this.reassignServiceRow(patient, response);
      }
      this.toastrService.success('Undo');

    });

  }

  addInline(patient): void {

    patient.BILL_PROCESSED = 'Y';

    const search = {
      sear_ip_no: patient.IP_NR,
      ser_service_id: patient.IP_SBI_ID,
      bill_processed: patient.BILL_PROCESSED,
      result_processed: patient.RESULT_PROCESSED,
      request_accepted: patient.REQUEST_ACCEPTED,
      cost_per_unit: patient.SERVICE_AMOUNT,
      discount_amt: patient.SERVICE_DISCOUNT_AMOUNT,
      discount_percent: patient.SERVICE_DISCOUNT_PERCENTAGE,
      net_amt: patient.SERVICE_NET_AMOUNT,
      cancel_btn: '',
      cancel_remarks: '',
      undo_cancel_btn: '',
      add_btn: '1',
      undo_add_btn: '',
      unit_quantity: patient.SERVICE_UNIT_QTY
    };

    this.inpatientService.patientServiceChargedEdit(search).subscribe((response) => {

      if (response) {
        this.reassignServiceRow(patient, response);
      }
      this.toastrService.success('Saved');

    });

  }

  undoAddInline(patient): void {

    patient.BILL_PROCESSED = 'N';

    const search = {
      sear_ip_no: patient.IP_NR,
      ser_service_id: patient.IP_SBI_ID,
      bill_processed: patient.BILL_PROCESSED,
      result_processed: patient.RESULT_PROCESSED,
      request_accepted: patient.REQUEST_ACCEPTED,
      cost_per_unit: patient.SERVICE_AMOUNT,
      discount_amt: patient.SERVICE_DISCOUNT_AMOUNT,
      discount_percent: patient.SERVICE_DISCOUNT_PERCENTAGE,
      net_amt: patient.SERVICE_NET_AMOUNT,
      cancel_btn: '',
      cancel_remarks: '',
      undo_cancel_btn: '',
      add_btn: '',
      undo_add_btn: '1',
      unit_quantity: patient.SERVICE_UNIT_QTY
    };

    this.inpatientService.patientServiceChargedEdit(search).subscribe((response) => {

      if (response) {
        this.reassignServiceRow(patient, response);
      }
      this.toastrService.success('Undo');

    });

  }

  reassignServiceRow(patient: any, result: any): void {

    // patient = result;
    // patient.CREATED_BY = 'YUVA';

    patient.IP_SBI_ID = result.IP_SBI_ID;
    patient.IP_NR = result.IP_NR;
    patient.SM_ID = result.SM_ID;
    patient.SERVICE_BILLED_DATE = result.SERVICE_BILLED_DATE;
    patient.SERVICE_NAME = result.SERVICE_NAME;
    patient.SERVICE_UNIT_QTY = result.SERVICE_UNIT_QTY;
    patient.SERVICE_AMOUNT = result.SERVICE_AMOUNT;
    patient.SERVICE_DISCOUNT_AMOUNT = result.SERVICE_DISCOUNT_AMOUNT;
    patient.SERVICE_DISCOUNT_PERCENTAGE = this.calculatePercentage(result);
    patient.SERVICE_NET_AMOUNT = result.SERVICE_NET_AMOUNT;
    patient.REQUEST_ACCEPTED = result.REQUEST_ACCEPTED;
    patient.RESULT_PROCESSED = result.RESULT_PROCESSED;
    patient.BILL_PROCESSED = result.BILL_PROCESSED;
    patient.SBI_STATUS = result.SBI_STATUS;
    patient.SBI_REMARKS = result.SBI_REMARKS;
    patient.CREATED_BY = result.CREATED_BY;

  }

  defaultValue(patient: any): any {

    if (patient.SERVICE_AMOUNT === '') {
      patient.SERVICE_AMOUNT = '0.00';
    }
    if (patient.SERVICE_DISCOUNT_AMOUNT === '') {
      patient.SERVICE_DISCOUNT_AMOUNT = '0.00';
    }

    if (patient.SERVICE_DISCOUNT_PERCENTAGE === '') {
      patient.SERVICE_DISCOUNT_PERCENTAGE = '0';
    }

    if (parseFloat(patient.SERVICE_DISCOUNT_PERCENTAGE) > 100) {
      patient.SERVICE_DISCOUNT_PERCENTAGE = '100';
    }

  }

  changeUnitQTY(event, patient: any): void {
    // console.log(event.target.value);

    const min = 1;
    const max = 99;
    const unitQty = event.target.value;

    if (unitQty !== '') {

      if (unitQty < min) {
        this.toastrService.error('Unit Qty should be between 1 to 99');
        event.target.value = min;
      } else if (unitQty > max ) {
        this.toastrService.error('Unit Qty should be between 1 to 99');
        event.target.value = max;
      } else {
        event.target.value = unitQty;
      }

      patient.SERVICE_UNIT_QTY  = event.target.value;

      this.calculateNet0(patient, 'SERVICE_UNIT_QTY');

    }

  }

  blurUnitQTY(event, patient: any): void {
    const unitQty = event.target.value;

    if (unitQty === '') {
      event.target.value = patient.SERVICE_UNIT_QTY;
    }
  }

  changeCostPerUnit(event, patient: any): void {
    // console.log(event.target.value);

    const min = 1;
    const max = 99;
    const currentValue = event.target.value;

    if (currentValue !== '') {

      // if (currentValue < min) {
      //   this.toastrService.error('Unit Qty should be between 1 to 99');
      //   event.target.value = min;
      // } else if (currentValue > max ) {
      //   this.toastrService.error('Unit Qty should be between 1 to 99');
      //   event.target.value = max;
      // } else {
      //   event.target.value = currentValue;
      // }
      event.target.value = currentValue;

      patient.SERVICE_AMOUNT  = event.target.value;

      this.calculateNet0(patient, 'SERVICE_AMOUNT');

    }

  }

  blurCostPerUnit(event, patient: any): void {
    const currentValue = event.target.value;

    if (currentValue === '') {
      event.target.value = Number(patient.SERVICE_AMOUNT).toFixed(2);
    }
  }

  changeDiscountAmount(event, patient: any): void {
    // console.log(event.target.value);

    const min = 0;
    const max = parseFloat(patient.SERVICE_NET_AMOUNT);
    const currentValue = event.target.value;

    if (currentValue !== '') {

      if (currentValue > max ) {
        this.toastrService.error('Discount amount should not exceed Net amount');
        event.target.value = max;
      } else {
        event.target.value = currentValue;
      }

      patient.SERVICE_DISCOUNT_AMOUNT  = event.target.value;

      this.calculateNet0(patient, 'SERVICE_DISCOUNT_AMOUNT');

    }

  }

  blurDiscountAmount(event, patient: any): void {
    const currentValue = event.target.value;

    if (currentValue === '') {
      event.target.value = Number(patient.SERVICE_DISCOUNT_AMOUNT).toFixed(2);
    }
  }

  calculateNet0(patient: any, except): any {

    const orgUnits = (patient.SERVICE_UNIT_QTY === '') ? Number(0) : Number(patient.SERVICE_UNIT_QTY);
    const orgCostPerUnit = (patient.SERVICE_AMOUNT === '') ? Number(0) : Number(patient.SERVICE_AMOUNT);

    const orgDiscountAmount = (patient.SERVICE_DISCOUNT_AMOUNT === '') ? Number(0) : Number(patient.SERVICE_DISCOUNT_AMOUNT);

    const units = orgUnits;
    const costPerUnit = orgCostPerUnit;

    const grandTotal =  units * costPerUnit;
    const discountAmount = orgDiscountAmount;

    let netAmount = grandTotal - discountAmount;
    netAmount = Number(netAmount.toFixed(2));

    let discountAmountPercenttage = 0;
    if (grandTotal > 0) {
      discountAmountPercenttage = ((grandTotal - netAmount) / grandTotal) * 100;
      discountAmountPercenttage = discountAmountPercenttage;
    }

    if (except !== 'SERVICE_AMOUNT') {
      patient.SERVICE_AMOUNT = Number(costPerUnit).toFixed(2);
    }

    if (except !== 'SERVICE_DISCOUNT_AMOUNT') {
      patient.SERVICE_DISCOUNT_AMOUNT = Number(discountAmount).toFixed(2);
    }

    if (except !== 'SERVICE_DISCOUNT_PERCENTAGE') {
      patient.SERVICE_DISCOUNT_PERCENTAGE = Number(discountAmountPercenttage).toFixed(2);
    }
    patient.SERVICE_NET_AMOUNT = Number(Math.round(netAmount)).toFixed(2);

  }

  changeDiscount(event, patient: any): void {
    // console.log(event.target.value);

    const min = 0;
    const max = 100;
    const currentValue = event.target.value;

    if (currentValue !== '') {

      if (currentValue > max ) {
        this.toastrService.error('Discount should not exceed 100 percent');
        event.target.value = max;
      } else {
        event.target.value = currentValue;
      }

      patient.SERVICE_DISCOUNT_PERCENTAGE  = event.target.value;

      this.calculateNet2(patient, 'SERVICE_DISCOUNT_PERCENTAGE');

    }

  }

  blurDiscount(event, patient: any): void {
    const currentValue = event.target.value;

    if (currentValue === '') {
      event.target.value = Number(patient.SERVICE_DISCOUNT_PERCENTAGE).toFixed(2);
    }
  }

  calculateNet2(patient: any, except): any {

    const orgUnits = (patient.SERVICE_UNIT_QTY === '') ? Number(0) : Number(patient.SERVICE_UNIT_QTY);
    const orgCostPerUnit = (patient.SERVICE_AMOUNT === '') ? Number(0) : Number(patient.SERVICE_AMOUNT);

    const orgDiscountAmount = (patient.SERVICE_DISCOUNT_AMOUNT === '') ? Number(0) : Number(patient.SERVICE_DISCOUNT_AMOUNT);

    const orgPer = (patient.SERVICE_DISCOUNT_PERCENTAGE === '') ? Number(0) : Number(patient.SERVICE_DISCOUNT_PERCENTAGE);
    // console.log(orgPer);

    const units =  orgUnits;
    const costPerUnit =  orgCostPerUnit;

    const grandTotal =  units * costPerUnit;

    let discountAmount = orgDiscountAmount;

    const discountAmountPercenttage = orgPer;

    discountAmount = ( grandTotal * discountAmountPercenttage / 100 );

    let netAmount = grandTotal - discountAmount;
    netAmount = Number(netAmount.toFixed(2));

    patient.SERVICE_AMOUNT = Number(costPerUnit).toFixed(2);
    patient.SERVICE_DISCOUNT_AMOUNT = Number(discountAmount).toFixed(2);

    if (except !== 'SERVICE_DISCOUNT_PERCENTAGE') {
      patient.SERVICE_DISCOUNT_PERCENTAGE = Number(discountAmountPercenttage).toFixed(2);
    }
    patient.SERVICE_NET_AMOUNT = Number(Math.round(netAmount)).toFixed(2);

  }

  confirmDialog(patient, method): any {

    const currentObj = this;

    console.log(method);

    this.confirmDialogService.confirmThis('Are you sure?', () =>  {
      // alert('Yes clicked');

      if (method === 'addInline') {
        currentObj.addInline(patient);
      }
      if (method === 'undoAddInline') {
        currentObj.undoAddInline(patient);
      }
      if (method === 'undoCancelService') {
        currentObj.undoCancelService(patient);
      }

    }, () => {
      // alert('No clicked');
    });

  }
  navigate() {
    const queryParam = { mode: 'dischargelist' };
    if (this.returnMode === 'iplist') {
      this.router.navigate(['/Inpatient']);
    } else if(this.returnMode === 'dischargelist'){     
      this.router.navigate(['/Inpatient'], {  queryParams: queryParam });
    }
    else{
      this.router.navigate(['/Inpatient']);
    }
  }
  gotoNav(): void {
    const queryParam = { umrNumber: this.patientURMNo, mode: this.returnMode };
    this.router.navigate(['/BillSummary/' + this.patientInfo.IP_NR], { queryParams: queryParam });

  }

  selectAutocompleteEvent(item: any): void {

    // this.advSearchForm.controls.SID.setValue(item.ServiceId);

  }

  onChangeSearch(val: string): void {
    // return this.doctorsList.filter(option => option.DM_NAME.toLowerCase().includes(val));
  }

  onFocused(e): void {
    // do something when input is focused
  }

}
