<div class="d-flex">
  <app-sidenav></app-sidenav>
  <div class="container-fluid total-page">

    <div class="row page-header">
      <div class="col-md-11 text-center p-0">
        <div>
          <h4 class="text-uppercase bold font-weight-bold orange-color">
            {{ listTitle }}
            <span class="descriptiveText" *ngIf="totalPatientsList != ''">
              (Total Patients: {{ totalPatientsList }})
            </span>
          </h4>
        </div>
        <span class="descriptiveText pr-1" *ngIf="selfPayList != 0">
          (Self-Pay Patients: {{ selfPayList }})
        </span>
        <span class="descriptiveText pr-1" *ngIf="insuranceList != 0">
          (Insurance Patients: {{ insuranceList }})
        </span>
        <!-- <span class="descriptiveText pr-1" *ngIf="corporateList != 0">
          (Corporate Patients: {{ corporateList }})
        </span> -->
      </div>
      <div class="col-md-1 text-center p-0" style="line-height: 80px;">
        <button type="button" (click)="backNav()" class="btn border-btn mr-3 px-4">Back</button>
      </div>
    </div>

    <!-- Advanced search block -->
    <div class="row">

      <!-- Advance search -->
      <div class="col-12">
        <div class=" p-3 mx-3">
          <form [formGroup]="advSearchForm" (ngSubmit)="onSearch()" class="form-inline" autocomplete="off">
            <div class="row advance-search">
              <div class="col-sm-3 mb-3">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname">UMR ID</label>
                  <div class="col-8 px-0">
                    <input formControlName="UMRID" class="form-control input-textt1 w-100" type="text" />
                    <span class="helptxt">Search with full UMR number</span>
                  </div>
                </div>
              </div>

              <div class="col-sm-3 mb-3">
                <div class="form-group row">
                  <label class="col-6 px-0 modname">Admission Date</label>
                  <div class="col-6 px-0">
                    <input formControlName="ADMISSIONDATE" class="form-control input-textt1 w-100"
                      placeholder="mm-dd-yyyy" type="date" style="padding: 0;" />
                    <span class="helptxt">&nbsp;</span>
                  </div>
                </div>
              </div>

              <div class="col-sm-3 mb-3">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname">Patient Name</label>
                  <div class="col-8 px-0">
                    <input formControlName="PATIENTNAME" class="form-control input-textt1 w-100" type="text" />
                    <span class="helptxt">Search with minimum 4 characters</span>
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname">IP No</label>
                  <div class="col-8 px-0">
                    <input formControlName="IPNO" class="form-control input-textt1 w-100" type="text" />
                    <span class="helptxt">Search with full IP number</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 mb-3">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname pb-0">Consult Doctor</label>
                  <div class="col-8 px-0">
                    <select class="form-control input-textt2 w-100" name="consultDoctor" formControlName="CONSULTDOCTOR"
                      id="consultDoctor" required>
                      <option value="">Select doctor name</option>
                      <option *ngFor="let name of consultDoctorsList" [ngValue]="name?.DM_ID">{{name?.DM_NAME}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname pb-0">Sponsor</label>
                  <div class="col-8 px-0">
                    <select class="form-control input-textt2 w-100" name="sponser" formControlName="SPONSER"
                      id="sponser" required>
                      <option value="">Select sponsor Name</option>
                      <option *ngFor="let name of sponsersList" [ngValue]="name?.ptmId">{{name?.ptmName}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-1 text-center">
                <button type="submit" class="btn btn-primary  advanced-search-btn">Search</button>
              </div>
              <div class="col-sm-2">
                <button type="submit" class="btn btn-primary  advanced-search-btn" (click)="selfpayPatientsList()">Print List</button>
              </div>
              <!-- <button type="button" class="btn btn-light float-right light-gray-btn mt-4 px-4">Reset</button> -->


            </div>
          </form>
        </div>
      </div>
      <!-- ./END Advance search -->

    </div>
    <!-- ./Advanced search block -->

    <div class="row justify-content-center mb-2">

      <div class="col-md-12">

        <div class="matcontent">

          <div class="row" *ngIf="!enableDischargeList">
            <div class="col-md-9"></div>
            <div class="col-md-3">
              <label style="line-height: 30px; color: #343887;">
                <span class="colorlegend"></span>
                Bed Confirmation awaiting
              </label>
            </div>
          </div>

          <mat-table #table [dataSource]="patientsData" matSort (matSortChange)="sortData($event)"
            matSortActive="IPNUM">

            <ng-container matColumnDef="IPNUM">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="center">
                <div>IP NO</div>
              </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element" class="center">
                <div>{{ element.IP_NR }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="NAMEGENDERAGE">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div>Name & Gender/Age</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.name }}">
                <div>
                  <a href="javascript:void(0)" (click)="patientQuickInfo(patientQuickInfoPopup, element)">
                    {{ element.TITLE }} {{ element.PATIENT_NAME }}
                  </a><br />{{ element.GENDER }}/<br>{{element.AGE}}
                </div>
              </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="GENDERAGE">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div>Gender / Age</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div>{{ element.GENDER }} /<br/> {{element.AGE}}</div>
              </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="SPONSOR">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="text-left">Sponsor / Payee Name</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div>{{ element.PIM_NAME }} </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CONSULTDOCTORWARDBED">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="text-left">Admitting Doctor Name<br>& Ward/Bed</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div *ngIf="element.BED_STATUS != 'C'" class="bednormal">{{ element.CONSULT_DOCTOR
                  }}<br>{{element?.WM_WARD_NAME}}
                  <span *ngIf="element?.WM_BED_NO">({{element?.WM_BED_NO}})</span>
                </div>
                <div *ngIf="element.BED_STATUS == 'C'" class="bedconfirmflag">
                  {{ element.CONSULT_DOCTOR }}<br>{{element?.WM_WARD_NAME}}
                  <span *ngIf="element?.WM_BED_NO">({{element?.WM_BED_NO}})</span>
                </div>
              </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="WARDBED">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div>Ward / Bed</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.description }}">
                <div *ngIf="element.BED_STATUS != 'C'" class="bednormal">{{element?.WM_WARD_NAME}} <span *ngIf="element?.WM_BED_NO">({{element?.WM_BED_NO}})</span></div>
                <div *ngIf="element.BED_STATUS == 'C'" class="bedconfirmflag">
                  {{element?.WM_WARD_NAME}} 
                  <span *ngIf="element?.WM_BED_NO">({{element?.WM_BED_NO}})</span>
                </div>
                
              </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="BILLAMOUNTTOTALADVPAID">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="text-left">Bill Amount &<br>Total Advance Paid</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div>{{element?.BILL_AMOUNT}}<br>{{element?.TOTAL_ADVANCE_PAID}}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="BILLAMOUNT">
              <mat-header-cell *matHeaderCellDef class="centre" mat-sort-header>
                <div class="text-left">Bill Amount</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="centre">
                <div>{{element?.BILL_AMOUNT}}</div>
              </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="TOTALADVPAID">
              <mat-header-cell *matHeaderCellDef class="centre" mat-sort-header>
                <div>Total Advance Paid</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="centre">
                <div>{{element?.TOTAL_ADVANCE_PAID}}</div>
              </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="PAYABLE">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Payable Amount</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div>{{element?.PAYABLE}}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ADMISSIONDATE">
              <mat-header-cell *matHeaderCellDef mat-sort-header="ADMISSIONDATE">
                <div class="text-left">Admission Date</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.IP_ADMISSION_DATE }}">
                <div>{{ element.IP_ADMISSION_DATE }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="DISCHARGETYPE">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Discharge Type</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.IP_DISCHARGE_TYPE }}">
                <div>{{ element.IP_DISCHARGE_TYPE }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="DISCHARGEDDATE">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Discharge Date</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.IP_DISCHARGE_DATE }}">
                <div>{{ element.IP_DISCHARGE_DATE }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="LENGTHOFSTAY">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Length of Stay</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.description }}">
                <div>{{ element.LENGTH_OF_STAY }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ACTION">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Action</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div style="min-width: 75px;">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      *ngIf="!enableDischargeList && rolesInfo?.attributeLevel?.ward_bed_management_in_patient_list_add_post_services"
                      (click)="actionRouting('AddInpatientServices', element)" mat-menu-item><span>Add/Post
                        Services</span></button>
                    <button
                      *ngIf="!enableDischargeList && rolesInfo?.attributeLevel?.ward_bed_management_in_patient_list_list_of_services_charged"
                      (click)="actionRouting('ServiceCharged', element)" mat-menu-item><span>List of Services
                        Charged</span></button>
                    <button
                      *ngIf="!enableDischargeList && rolesInfo?.attributeLevel?.ward_bed_management_in_patient_list_list_of_services_charged"
                      (click)="actionRouting('DateWiseServiceCharged', element)" mat-menu-item><span>Services
                        Charged</span></button>
                    <button *ngIf="rolesInfo?.attributeLevel?.ward_bed_management_in_patient_list_posted_bill_summary"
                      (click)="actionRouting('BillSummary', element)" mat-menu-item><span>Posted Bill
                        Summary</span></button>
                    <button
                      *ngIf="element.PTM_ID > 1 && rolesInfo?.attributeLevel?.ward_bed_management_in_patient_list_credit_bill_submission"
                      (click)="actionRouting('creditBill', element)" mat-menu-item><span>Credit Bill
                        Submission</span></button>
                    <button
                      *ngIf="!enableDischargeList && rolesInfo?.attributeLevel?.ward_bed_management_in_patient_list_add_receipt" [hidden]="element.holdServiceStatus == 'Y'"
                      (click)="actionRouting('AddReceipts', element)" mat-menu-item><span>Add Receipt</span></button>
                    <button
                      *ngIf="!enableDischargeList && rolesInfo?.attributeLevel?.ward_bed_management_in_patient_list_pre_auth && element.PTM_ID > 1"
                      (click)="actionRouting('PreAuth', element)" mat-menu-item><span>Pre-Auth</span></button>
                    <button *ngIf="!enableDischargeList" (click)="actionRouting('admissionFormPrint', element)"
                      mat-menu-item><span>Admission Form Print</span></button>
                    <button *ngIf="enableDischargeList" (click)="actionRouting('dischargeFormPrint', element)"
                      mat-menu-item><span>Check Out Slip</span></button>
                    <button *ngIf="element.PTM_ID > 1" (click)="actionRouting('BillingInsurance', element)"
                      mat-menu-item>
                      <span>Cover Letter</span>
                    </button>
                    <button (click)="actionRouting('admissionInformation', element)" mat-menu-item>
                      <span>Admission Information</span>
                    </button>
                    <!-- <button (click)="actionRouting('coverLetter', element)" mat-menu-item>
                      <span>Cover Letter</span>
                    </button> -->
                    <button (click)="printDischargeSummary(dischargeSummaryPrintPopup, element)" mat-menu-item>
                      <span>Discharge Summary </span>
                    </button>
                    <button (click)="actionIcdPop(icdPopup, element)" mat-menu-item><span>ICD-10</span></button>
                  </mat-menu>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let element; columns: columnsToDisplay"></mat-row>
          </mat-table>

          <div class="mat-norecords" *ngIf="patientsData.data.length === 0">No records found</div>
          <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
            style="border-bottom-left-radius:6px; border-bottom-right-radius:6px" class="mat-paginator-sticky">
          </mat-paginator>
        </div>

      </div>

    </div>
  </div>

  <!-- Start Pre-Auth popup -->
  <ng-template #patientQuickInfoPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Patient Quick Info</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="row">
        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              IP Number
            </div>
            <div class="col">
              <span>{{patientQuickInformation.IP_NR}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Next of Kin / Guardian Name
            </div>
            <div class="col">
              <span>{{patientQuickInformation.nextOfKinName || patientQuickInformation.NEXT_OF_KIN_NAME}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              UMR No
            </div>
            <div class="col">
              <span>{{patientQuickInformation.P_ID}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Relation
            </div>
            <div class="col">
              <span>{{patientQuickInformation.nextOfKinRelatoin}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Mobile No
            </div>
            <div class="col">
              <span>{{patientQuickInformation.mobileNo || patientQuickInformation.NEXT_OF_KIN_MOBILENR}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Admission Date
            </div>
            <div class="col">
              <span>{{patientQuickInformation.IP_ADMISSION_DATE}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Admission Time
            </div>
            <div class="col">
              <span>{{patientQuickInformation.IP_ADMISSION_TIME}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Admitting Doctor
            </div>
            <div class="col">
              <span>{{patientQuickInformation.CONSULT_DOCTOR}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Treating Doctor / Cross Consultants
            </div>
            <div class="col">
              <span>{{patientQuickInformation?.treatingDoctors?.DM_NAME}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              External Referral
            </div>
            <div class="col">
              <span>{{patientQuickInformation.externalRefDoctor}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </ng-template>
  <!-- End Pre-Auth popup -->

  <!-- Start Pre-Auth popup -->
  <ng-template #preAuthPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Pre-Auth Form</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <app-pre-auth [ipPatientNO]="selectedIPPatientNO" [patientURMNo]="seletedPatientURMNo"
        (closePopup)='closeModalPopup($event)'></app-pre-auth>
    </div>
  </ng-template>
  <!-- End Pre-Auth popup -->
  <!-- Start credit bill submission popup -->
  <ng-template #coverLetterPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Cover Letter</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-6">Ref:PCH-HYD:624:STR01</div>
        <div class="col-md-6 text-right">
          <div>PARAMITHA CHILDREN&#39;S HOSPITAL</div>
          <div class="pl-4">KOTHAPET, HYDERABAD-500035</div>
          <div class="pl-5">PH:040 4010 8108</div>
          <div class="pt-2 pl-4">DATE :16-6-2021</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div>To,</div>
          <div>The Claims Manager,</div>
          <div class="pt-3">Star Health and Allied Insurance,
            ,
          </div>
          <div class="py-4">Dear Sir,</div>
          <div class="pb-4"> Please find enclosed medicals bills of your employee who has received treatment
            at PARAMITHA CHILDREN'S HOSPITAL on credit basis. Details are as under:-
          </div>
          <table style="width:100%;">
            <tr>
              <th>Bill No</th>
              <th>Patient's Name</th>
              <th>IP No</th>
              <th>Claim Ref.No</th>
              <th>Net.Amt(Rs)</th>
            </tr>
            <tr>
              <td>{{ coverLetterInfo.billNo}}</td>
              <td>{{ coverLetterInfo.billNo}}</td>
              <td>{{ selectedIPPatientNO}}</td>
              <td>{{ coverLetterInfo.referenceID}}</td>
              <td>{{ coverLetterInfo.netAmount}}</td>
            </tr>

          </table>
          <div class="py-4">
            Primary Insurance: Star Health and Allied Insurance</div>
          <div> Kindly release the payment at favour of Paramitha Children's Hospital Pvt Ltd. </div>
          <div class="pb-4"> At the earliest <b>PAN NO: AAFCP7756G ROHINI</b> ID : 8900080163003</div>
          <div class="pb-4">Thanking You,</div>
          <div>Your's Sincerely, </div>
          <div>Sathish Kumar Maroju,</div>
          <div> Manager Corporate Relations,</div>
          <div>For Paramitha Children's Hospital.</div>
          <div>Contact No: 9553411594, 040-40108117.</div>
          <div>Email :paramithachildrenshospital@gmail.com</div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- End credit bill submission popup -->
  <!-- Start credit bill submission popup -->
  <ng-template #creditBillSubmissionPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Credit Bill Submission Form</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <app-credit-bill-submission [ipPatientNO]="selectedIPPatientNO" [patientURMNo]="seletedPatientURMNo"
        (closePopup)='closeModalPopup($event)'></app-credit-bill-submission>
    </div>
  </ng-template>
  <!-- End credit bill submission popup -->

  <!-- Payment process popup -->
  <ng-template #paymentReceiptPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Payment Process</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <app-payment-process [ipPatientNO]="selectedIPPatientNO" [patientURMNo]="seletedPatientURMNo"
        (closePopup)='closeModalPopup($event)'></app-payment-process>
    </div>
  </ng-template>
  <!-- End Payment process popup -->

  <ng-template #ipAdmissionInformationPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Ip Admission Information</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-ipadmission-info (popupClose)="admissionInfoPopClose()" [patientInfo]="selectedPatient">
      </app-ipadmission-info>
    </div>
  </ng-template>
</div>

<div id="print-prescription-section" class="print-form mt-5"
  *ngIf="printPrescription && admissionFormPrintData !== undefined">
  <div class="border border-dark">
    <div class="row no-gutters heading-block border-bottom border-dark">
      <div class="col-md-2 logo">
        <img src="./assets/hospital-logo.jpg" class="img-thumbnail" />
      </div>
      <div class="col-md-9">
        <div class="text-center">
          <h2 class="main-title mb-0 pdf-hospital-heading" [innerHtml]="admissionFormPrintData?.HospitalName">
          </h2>
          <p>{{admissionFormPrintData?.HospitalAddress}}</p>
          <h4 class="sub-title1 mb-1 font-weight-bold">
            {{admissionFormPrintData?.TitleLabel}}</h4>
        </div>
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-2">
      <div class="col-md-1 font-weight-bold">IP No. &nbsp;&nbsp;:</div>
      <div class="col-md-2 font-weight-bold border border-dark mr-3 p-1">
        {{admissionFormPrintData?.IpNo}}
      </div>
      <div class="col-md-1 font-weight-bold">
        U.M.R.No&nbsp;&nbsp;:
      </div>
      <div class="col-md-2 font-weight-bold border border-dark mr-3 p-1">
        {{admissionFormPrintData?.UMRNo}}
      </div>
      <div class="col-md-2 font-weight-bold">
        Admission Date&nbsp;&nbsp;:
      </div>
      <div class="col-md-2">
        {{admissionFormPrintData?.AdmissionDate}}
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-2">
      <div class="col-md-2 font-weight-bold">
        Name of the Patient :
      </div>
      <div>
        {{admissionFormPrintData?.PatientName}}
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Father/Husband Name :</div>
      <div class="col-md-4">{{admissionFormPrintData?.FatherHusband}}</div>
      <div class="col-md-1">Age :</div>
      <div class="col-md-2">{{admissionFormPrintData?.Age}}</div>
      <div class="col-md-1">Sex :</div>
      <div class="col-md-2">{{admissionFormPrintData?.Sex}}</div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Mother's Name :</div>
      <div class="col-md-3">{{admissionFormPrintData?.MothersName}}</div>
      <div class="col-md-2">Marital Status :</div>
      <div class="col-md-2">{{admissionFormPrintData?.MaritalStatus}}</div>
      <div class="col-md-1">Occupation :</div>
      <div class="col-md-2">{{admissionFormPrintData?.Occupation}}</div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">
        Address :
      </div>
      <div>
        {{admissionFormPrintData?.Address}}
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Phone Number :</div>
      <div class="col-md-2"><b>(R)</b></div>
      <div class="col-md-2"><b>(O)&nbsp;</b>{{admissionFormPrintData?.Phoneno}}</div>
      <div class="col-md-1">Nationality :</div>
      <div class="col-md-2">{{admissionFormPrintData?.Nationality}}</div>
      <div class="col-md-1">Religion :</div>
      <div class="col-md-2">{{admissionFormPrintData?.Religion}}</div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">
        Consultant Doctor :
      </div>
      <div class="col-md-4">
        {{admissionFormPrintData?.ConsultantDoctor}}
      </div>
      <div class="col-md-2">
        Department :
      </div>
      <div class="col-md-4">
        {{admissionFormPrintData?.Department}}
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">
        Ref. By Doctor :
      </div>
      <div class="col-md-4">
        {{admissionFormPrintData?.RefByDoctor}}
      </div>
      <div class="col-md-2">
        Ref. Source :
      </div>
      <div class="col-md-4">
        {{admissionFormPrintData?.RefSource}}
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">
        Credit Organisation :
      </div>
      <div class="col-md-4">
        {{admissionFormPrintData?.CreditOrgnaization}}
      </div>
      <div class="col-md-2">
        <b>Ward / Bed No :</b>
      </div>
      <div class="col-md-4">
        {{admissionFormPrintData?.WardBedNo}}
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Diagnosis on Admission :</div>
      <div>{{admissionFormPrintData?.DiagnosisOnAdmission}}</div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Final Diagnosis :</div>
      <div class="col-md-6">{{admissionFormPrintData?.FinalDiagnosis}}</div>
      <div class="col-md-1 font-weight-bold">I.C.D Code :</div>
      <div class="col-md-3">{{admissionFormPrintData?.FinalDiagnosisIcdCode}}</div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Secondary Diagnosis :</div>
      <div class="col-md-6">{{admissionFormPrintData?.SecondaryDiagnosis}}</div>
      <div class="col-md-1 font-weight-bold">I.C.D Code :</div>
      <div class="col-md-3">{{admissionFormPrintData?.SecondaryDiagnosisIcdCode}}</div>
    </div>
    <div class="row px-3 pt-3">
      <div class="col-md-2">Surgerical Operation :</div>
      <div class="col-md-5">{{admissionFormPrintData?.SurgicalOperation}}</div>
      <div class="col-md-2 font-weight-bold">I.C.D Code :</div>
      <div class="col-md-3">{{admissionFormPrintData?.SurgicalOperationIcdCode}}</div>
    </div>
    <div class="row no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Date :</div>
      <div class="col-md-4">{{admissionFormPrintData?.SurgicalOperationDate}}</div>
      <div class="col-md-2 font-weight-bold">Type of Anaesthesia :</div>
      <div class="col-md-4">{{admissionFormPrintData?.SurgicalOperationTypeOfAnaesthesia}}</div>
    </div>
    <div class="row no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Cause of Death :</div>
      <div class="col-md-2">{{admissionFormPrintData?.CauseOfDeath}}</div>
      <div class="col-md-2 font-weight-bold">Under 48hrs. :</div>
      <div class="col-md-1">
        <div class="checkBox"></div>
      </div>
      <div class="col-md-2 font-weight-bold">Above 48hrs. :</div>
      <div class="col-md-1">
        <div class="checkBox"></div>
      </div>
      <div class="col-md-1">Date of Death: </div>
      <div class="col-md-1">{{admissionFormPrintData?.DateOfDeath}}</div>
    </div>
    <div class="row no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Date Of Discharge :</div>
      <div class="col-md-4">{{admissionFormPrintData?.DateofDischarge}}</div>
      <div class="col-md-2 font-weight-bold">Hospital Days :</div>
      <div class="col-md-4">{{admissionFormPrintData?.HospitalDays}}</div>
    </div>
    <div class="row no-gutters  border-bottom border-dark p-3">
      <div class="col-md-1 font-weight-bold">Result :</div>
      <div class="col-md-1">Recovered</div>
      <div class="col-md-1">
        <div class="checkBox"></div>
      </div>
      <div class="col-md-1 font-weight-bold">Improved</div>
      <div class="col-md-1">
        <div class="checkBox"></div>
      </div>
      <div class="col-md-2 font-weight-bold">Diagnosis Only</div>
      <div class="col-md-1">
        <div class="checkBox"></div>
      </div>
      <div class="col-md-1 font-weight-bold">LAMA </div>
      <div class="col-md-1">
        <div class="checkBox"></div>
      </div>
      <div class="col-md-1 font-weight-bold">
        <div class="row">
          <div class="mr-2">MLC</div>
          <div class="checkBox"></div>
        </div>
      </div>
      <div class="col-md-1 font-weight-bold">
        <div class="row">
          <div class="mr-2">EXP</div>
          <div class="checkBox"></div>
        </div>
      </div>
    </div>
    <div class="row no-gutters  border-bottom border-dark p-3 pt-8">
      <div class="col-md-2">Signature of Resident</div>
      <div class="col-md-8"></div>
      <div class="col-md-2">Signature of Consultant</div>
    </div>
    <div class=" p-3 font-weight-bold">Obstetric Data :</div>
    <div class="row px-3 py-1">
      <div class="col-md-1">Normal</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
      <div class="col-md-1">Live Birth</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
      <div class="col-md-1">Date</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
    </div>
    <div class="row px-3 py-1">
      <div class="col-md-1">Abnormal</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
      <div class="col-md-1">Twin</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
      <div class="col-md-1">Male</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
    </div>
    <div class="row px-3 py-1 mb-2">
      <div class="col-md-1">Weight</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
      <div class="col-md-1">Still Birth</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
      <div class="col-md-1">Female</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
    </div>
  </div>
  <div style="height: 250px;"></div>
  <div class="border border-dark">
    <div class="font-weight-bold text-center p-3">
      AUTHORISATION FOR INVESTIGATIONS, PROCEDURES,
      SURGICAL OPERATIONS AND TREATMENT
    </div>
    <div class="p-3">
      I ______________________________________________ S/O D/O W/O F/O ____________________________
      resident of
      necessary for the diagnosis and treatment .
      any Procedures like Biopsy, Dialysis, Surgical Operations under any anaesthesia as and when required or deemed
      investigations including HIV Testing and administer necessary drugs, injections and blood transfusion and to
      undertake
      authorise all medical, nursing and paramedical staff of {{admissionFormPrintData?.HospitalName | titlecase}}, to
      conduct all
      necessary
    </div>
    <div class="row p-3">
      <div class="col-md-2">Witness Name :</div>
    </div>
    <div class="row px-3">
      <div class="col-md-2">Signature :</div>
    </div>
    <div class="row p-3">
      <div class="col-md-2">Date :</div>
      <div class="col-md-7"></div>
      <div class="col-md-3">Signature of Patient / Attendant</div>
    </div>
    <div class="font-weight-bold text-center p-3">
      AUTHORISATION FOR RELEASE OF INFORMATION
    </div>
    <div class="p-3">
      I hereby authorise the {{admissionFormPrintData?.HospitalName | titlecase}}, Hyderabad to release any document
      from any Medical
      record
      for the purpose of research, claim or settlements to any person or agency or settlements to any company
      permitted
      under law. I shall not hold the authorities liable for any legal consequences arising from the release of the
      said
      information.

    </div>
    <div class="row p-3">
      <div class="col-md-2">Witness Name :</div>
    </div>
    <div class="row px-3">
      <div class="col-md-2">Signature :</div>
    </div>
    <div class="row p-3">
      <div class="col-md-2">Date :</div>
      <div class="col-md-7">{{admissionFormPrintData?.Dischargformdate}}</div>
      <div class="col-md-3">Signature of Patient / Attendant</div>
    </div>
    <div class="font-weight-bold text-center p-3">
      DISCHARGE AGAINST MEDICAL ADVISE FORM
    </div>
    <div class="p-3">
      I am responsible for taking the patient
      _____{{admissionFormPrintData?.PatientName}}__________________________________ at my own
      risk.
      The patient condition and prognosis was explained to me by the consultant and Doctor on duty in the vernacular
      language understand by me. Yet, we have decided to take patient against medical advice. In case of any
      eventuality i will not hold the hospital authorities responsible.

    </div>
    <div class="row p-3">
      <div class="col-md-6 border-right border-dark">
        <div class="row p-3">
          SIGNATURE OF
          <br>
          CONSULTANT /
          <br>
          DOCTOR ON DUTY
          <h1 class="px-4">}</h1>
        </div>
        <div class="row px-3 py-2">
          <div>Name :</div>
        </div>
        <div class="row px-3 py-2">
          <div>Registration No :</div>
        </div>
        <div class="row py-2">
          <div class="col-md-2">Date :</div>
          <div class="col-md-10 font-weight-bold">{{admissionFormPrintData?.Dischargformdate}}</div>
        </div>
        <div class="row py-2">
          <div class="col-md-2">Time :</div>
          <div class="col-md-10 font-weight-bold">{{admissionFormPrintData?.Dischargformtimw}}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row px-3 py-2">Signature</div>
        <div class="row px-3 py-2">
          <div>Name :</div>
        </div>
        <div class="row px-3 py-2">
          <div>Relation to the Patient :</div>
        </div>
        <div class="row no-gutters border-bottom border-dark py-2">
          <div class="col-md-2">Date :</div>
          <div class="col-md-4 font-weight-bold">{{admissionFormPrintData?.Dischargformdate}}</div>
          <div class="col-md-2">Time :</div>
          <div class="col-md-4 font-weight-bold">{{admissionFormPrintData?.Dischargformtimw}}</div>
        </div>
        <div class="row px-3 py-2">
          <div>Wittness Name :</div>
        </div>
        <div class="row px-3 py-2">Signature</div>
        <div class="row py-2">
          <div class="col-md-2">Date :</div>
          <div class="col-md-4 font-weight-bold">{{admissionFormPrintData?.Dischargformdate}}</div>
          <div class="col-md-2">Time :</div>
          <div class="col-md-4 font-weight-bold">{{admissionFormPrintData?.Dischargformtimw}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="print-prescription-section" class="print-form mt-5"
  *ngIf="printPrescription && dischargeFormPrintData !== undefined">
  <div class="border border-dark rounded">
    <div class="row no-gutters heading-block border-bottom border-dark">

      <div class="mx-auto text-center">
        <h2 class="main-title mb-0  pdf-hospital-heading" [innerHtml]="dischargeFormPrintData?.HospitalName">
        </h2>
        <p>{{dischargeFormPrintData?.HospitalAddress}}</p>
        <h3 class="sub-title1  mb-1 font-weight-bold">
          <u>{{dischargeFormPrintData?.TitleLabel}}</u>
        </h3>

      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">UMR No. &nbsp;&nbsp;:</div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.UMRNo}}
      </div>
      <div class="col-md-2 font-weight-bold">
        Discharge Date
      </div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.DischargeDate}}
      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Admission No. &nbsp;&nbsp;:</div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.IpNo}}
      </div>
      <div class="col-md-2 font-weight-bold">
        Ward Name
      </div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.WardName}}
      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold"></div>
      <div class="col-md-4">

      </div>
      <div class="col-md-2 font-weight-bold">
        Bed No.
      </div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.BedNo}}
      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Patient Name</div>
      <div class="col-md-10 ">{{dischargeFormPrintData?.PatientName}}</div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Doctor Name</div>
      <div class="col-md-10 ">{{dischargeFormPrintData?.DoctorName}}</div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Department</div>
      <div class="col-md-10 ">{{dischargeFormPrintData?.Department}}</div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Total Bill Amount</div>
      <div class="col-md-10 ">{{dischargeFormPrintData?.TotalBillAmount}}</div>
    </div>
    <div class="row p-2 font-weight-bold">
      <div class="col-md-2">Due Authorized By</div>
      <div class="col-md-10">{{dischargeFormPrintData?.DueAuthorizedBy}}</div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Due Amount</div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.DueAmount}}
      </div>
      <div class="col-md-2">

      </div>
      <div class="col-md-4 font-weight-bold">
        Signature
      </div>
    </div>
  </div>
  <div class="row p-2">
    <div class="col-md-2 font-weight-bold">Prepared by</div>
    <div class="col-md-4">
      {{dischargeFormPrintData?.PreparedBy}}
    </div>
    <div class="col-md-2">
      Prepared on
    </div>
    <div class="col-md-4 font-weight-bold">
      {{dischargeFormPrintData?.PreparedOn}}
    </div>
  </div>
  <div class="row p-2">
    <div class="col-md-2 font-weight-bold">Printed by</div>
    <div class="col-md-4">
      {{userInfo.username}}
    </div>
    <div class="col-md-2">
      Printed on
    </div>
    <div class="col-md-4 font-weight-bold">
      {{dischargeFormPrintData?.PrintedOn}}
    </div>
  </div>
</div>

<!-- print discharge summary popup -->
<ng-template #dischargeSummaryPrintPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Discharge Summary</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times-circle"></i></span>
    </button>
  </div>
  <div class="modal-body text-break">
    <app-discharge-summary-popup [selectedBed]="dischargeSummaryPatientInfo" [existingRecord]="existingRecord"
      [dischargeSummaryInfo]="existingDischargeSummaryInfo.DISCHARGE_SUMMARY_INFO"></app-discharge-summary-popup>
  </div>
</ng-template>
<!-- ./END print discharge summary popup -->

<!-- ICD Popup -->
<ng-template #icdPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">ICD-10 Information</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times-circle"></i></span>
    </button>
  </div>
  <div class="modal-body text-break">
    <app-icd [patientDetails]="icdPatientDetails" (ICDpopupClose)="popupClose()"></app-icd>
  </div>
</ng-template>
<!-- ./ END ICD Popup -->

<div id="patients-list" class="print-list mt-5" style="display: none;"><!-- style="display: none;" -->
  <div class="row ml-1">
    <div class="row col-md-12">
      <div class="col-12 header justify-content-center ml-1 mb-4">
        <h4 class="text-center font-weight-bold pt-2">{{sponserName}} INPATIENTS LIST</h4>
        <!-- <hr class="m-0 hr" /> -->
      </div>  
      <table class="table table-bordered list">
        <!--Table head-->
        <thead style="background-color: lightgray;">
          <tr>
            <th class="fweight600 wid120">SI.NO</th>
            <th class="fweight600 wid120">IPNO </th>
            <th class="fweight600 wid120">PATIENT ANME</th>
            <th class="fweight600 wid120">GUARDIAN NAME<br>(RELATION)</th>
            <th class="fweight600 wid120">ADMITTED WARD/BED</th>
            <th class="fweight600 wid120">CONSULTANT NAME</th>
            <th class="fweight600 wid120">REFERRAL DOCTOR NAME</th>            
            <th class="fweight600 wid120">DATE OF ADMISSION</th>
            <th class="fweight600 wid120">LENGTH OF STAY</th>
            <th class="fweight600 wid120">GROSS AMOUNT</th>
            <th class="fweight600 wid120">RECEIPT AMOUNT</th>
            <th class="fweight600 wid120">PAYABLE AMOUNT</th>            
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let patient of patientsList, let i = index" class="handCursor">
            <td>{{i+1}}</td>
            <td class="text-capitalize">{{patient.IP_NR}} </td>
            <td>{{patient.TITLE}} {{patient.PATIENT_NAME}}</td>
            <td>{{patient.nextOfKinName}}<br>
              <span *ngIf="patient.nextOfKinRelatoin!=''">({{patient.nextOfKinRelatoin}})</span></td>
            <td>{{patient.WM_WARD_NAME}}{{patient.WM_BED_NO}}</td>
            <td>{{patient.CONSULT_DOCTOR}}</td>
            <td>{{patient.refDoctorName}}</td>            
            <td>{{patient.IP_ADMISSION_DATE}}</td>
            <td>{{patient.LENGTH_OF_STAY}}</td>            
            <td>{{patient.BILL_AMOUNT}}</td>
            <td>{{patient.TOTAL_ADVANCE_PAID}}</td>
            <td>{{patient.PAYABLE}}</td>
          </tr>
        </tbody>
      </table>      
    </div>
  </div>
</div>