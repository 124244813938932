import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';

import { InpatientService } from '../../viewinpatient-module/inpatient.service';

import { CommonService } from 'src/app/shared/services/common.service';
@Component({
  selector: 'app-summary-bill-print',
  templateUrl: './summary-bill-print.component.html',
  styleUrls: ['./summary-bill-print.component.css']
})
export class SummaryBillPrintComponent implements OnInit, OnChanges {

  public finalBillPatientInfo: any;
  public finalBillServicesList: any;
  public finalBillReceiptsList: any;
  public finalBillFooterAcknowledgement: any;
  public finalBillSummary: any;

  public userInfo: any = {};

  billReceiptsTotal: any = '';
  billServiceListTotal: any = 0;
  billReceiptsTotalWords: any = '';

  billServiceListTotalWords: any = '';
  billServiceDiscountStatus = false;

  billingSummaryDetails: any = [];

  inWordsGrossAmount = '';
  inWordsReceiptAmount = '';

  @Input() ipno: number;
  @Input() umrno: number;
  @Input() receiptno: number;
  @Input() typeofbill: string;
  walkin = false;

  constructor(private tokenService: TokenStorageService,
              private inpatientService: InpatientService,
              private commonService: CommonService) {

    this.userInfo = this.tokenService.getUser();

  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {

    // this.printReceipt(this.ipno, this.receiptno);
    this.getBillingSummaryInfo();

    this.finalBillprint(this.typeofbill);

  }

  /** get the billing summary info */
  getBillingSummaryInfo(): void {
    try {
      const inputRequest = {
        sear_ip_no: this.ipno,
        sear_hospitalid: this.userInfo.hospitalID
      };
      this.inpatientService.patientBillingSummary(inputRequest).subscribe(
        (response) => {
          // console.log("billing summary", response)
          this.billingSummaryDetails = response;

          if (this.billingSummaryDetails) {
            if (this.billingSummaryDetails.Gross_Amount > 0) {
              this.inWordsGrossAmount = this.commonService.toWords(
                this.billingSummaryDetails.Gross_Amount
              );
            }

            if (this.billingSummaryDetails.Total_Receipt_Amount > 0) {
              this.inWordsReceiptAmount = this.commonService.toWords(
                this.billingSummaryDetails.Total_Receipt_Amount
              );
            }

          }

        },
        (err) => {
          console.log('HTTP Error', err);
        }
      );
    } catch (error) {}
  }

  finalBillprint(typeOfBill): any {

    let inputRequest: any;

    if (typeOfBill === 'Summary') {
      inputRequest = {
        sear_ip_no: this.ipno,
        sear_hospitalid: this.userInfo.hospitalID,
        sear_umr_no: this.umrno,
        daywise_break_btn: 0,
        summary_bill_btn: 1
      };
    } else if (typeOfBill === 'Full Break') {
      inputRequest = {
        sear_ip_no: this.ipno,
        sear_hospitalid: this.userInfo.hospitalID,
        sear_umr_no: this.umrno,
        daywise_break_btn: 0,
        summary_bill_btn: 0
      };
    } else {
      inputRequest = {
        sear_ip_no: this.ipno,
        sear_hospitalid: this.userInfo.hospitalID,
        sear_umr_no: this.umrno,
        daywise_break_btn: 1,
        summary_bill_btn: 0
      };
    }

    // future scope
    this.getFinalBillData(inputRequest);

  }

  // future scope
  getFinalBillData(inputRequest): void {

    this.inpatientService.getFinalBillCallsR(inputRequest).subscribe(result => {

      // Header
      if (result[0] !== null) {
        this.finalBillPatientInfo = result[0];
        if (this.finalBillPatientInfo.Referral === 'Walk-In'){
          this.walkin = true;
        }
      }

      // Services
      if (result[1] !== null) {

        this.billServiceDiscountStatus = false;

        const totalCountArray = [];

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < result[1].length; i++) {

          if (!(this.finalBillPatientInfo.SummaryBillBtn)) {
            // tslint:disable-next-line:prefer-for-of
            for (let j = 0; j < result[1][i].SubCategory.length; j++) {

              totalCountArray.push(result[1][i].SubCategory[j]);
              if ( parseFloat(result[1][i].SubCategory[j].Discount) > 0 ) {
                this.billServiceDiscountStatus = true;
              }

            }
          } else { /** If it is summary bill, we will not be having sub catagories */

            totalCountArray.push(result[1][i]);
            if ( result[1][i].DiscountPercent !== '0') {
              this.billServiceDiscountStatus = true;
            }

          }

        }
        console.log(totalCountArray);
        // console.log("getFinalBillServicesList", this.billServiceDiscountStatus);
        // tslint:disable-next-line:max-line-length
        // this.billServiceListTotal = totalCountArray.map((t) => this.finalBillPatientInfo.SummaryBillBtn ?  parseInt(t.netAmount) : parseInt(t.Rate)).reduce((a: number, value: number) => a + value, 0);

        this.finalBillServicesList = result[1];

      }

      // Receipts
      if (result[2] !== null) {

        this.finalBillReceiptsList = result[2];

        this.billReceiptsTotal = this.finalBillReceiptsList
          .map((t) => parseInt(t.RecptAmt))
          .reduce((a: number, value: number) => a + value, 0);

        this.billReceiptsTotal = Math.round(this.billReceiptsTotal);

        // this.billReceiptsTotal = this.billReceiptsTotal
        //   .toString()
        //   .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

      // Footer
      if (result[3] !== null) {
        this.finalBillFooterAcknowledgement = result[3];
        // console.log(
        //   'finalBillFooterAcknowledgement',
        //   this.finalBillFooterAcknowledgement
        // );
      }

      // Bill Summary
      if (result[4] !== null) {
        this.finalBillSummary = result[4];
      }

    },
    error => {
      console.log('Unable to load');
    },
    () => {

      // // All the API calls are completed here.
      // // codes should be executed after the completion of all API calls
      // // console.log("I am completed");
      // this.printPrescription = true;

      // setTimeout(() => {
      //   this.printerService.printDiv('print-prescription-section');
      // }, 2000);

      // setTimeout(() => {
      //   this.printPrescription = false;
      // }, 3000);

    });

  }

}
